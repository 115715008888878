import React, { Suspense, useState } from 'react';
import { useOutlet, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Error401 from 'components/errors/Error401';
import { useGuard } from 'hooks/useGuard';
import { AnimatePresence, motion } from 'framer-motion';
import PageLoader from 'components/common/PageLoader';
const AnimatedOutlet = () => {
  const o = useOutlet();
  const [outlet] = useState(o);

  return <>{outlet}</>;
};
export function RolesAuthRoute({ route, roles, ...rest }) {
  const { id } = useParams();
  const { can } = useGuard({ roles, itemIds: id && [id] });
  if (!route)
    return (
      <AnimatePresence mode="wait">
        <motion.div
          {...rest}
          key={location.pathname}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
        >
          <Suspense fallback={<PageLoader />}>
            <AnimatedOutlet />
          </Suspense>
        </motion.div>
      </AnimatePresence>
    );
  if (!roles || can()) return <>{route}</>;

  return <Error401 roles={roles} />;
}
RolesAuthRoute.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
  route: PropTypes.node
};
