import api from './index';
import { RoleDomain } from './users';
const getStrategy = type => {
  switch (type) {
    case 'enterprise':
      return 'enterprise-bearer';
    case 'public':
      return 'b2c-bearer';
    default:
      return null;
  }
};
export const flexLogout = () => api.get('/users/auth/logout');
export const flexLogin = () => {
  return api.get('/users/auth/login', {
    params: { strategy: getStrategy('public') }
  });
};
export const getItemOwnership = () => {
  return api.get<ItemOwnership[]>('users/auth/itemownership').then(d => d.data);
};
export type AppRole = {
  collectionId: number;
  levelId: number;
  levelName: string;
  collectionName: string;
  authority: number;
  description: string;
};
export const getAppRoles = () => {
  return api.get<AppRole[]>('/users/auth/roles').then(d => d.data);
};
export type ItemOwnership = {
  domain: RoleDomain;
  ownerId: number;
  itemId: number;
  parentDomain?: string;
  parentId?: number;
  childDomain?: string;
  childId?: number;
};
