import flexAPI from '.';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CrudApi from './CrudApi';
import { CustomRule } from 'helpers/validation/validate';
export type FormResponseRecord = {
  id: number;
  submittedDate?: Date;
  submittedBy?: number;
  formId: number;
  screenoutQuestionIds?: number[];
};

export type FormResponseInput = {
  id?: number;
  submittedDate?: Date;
  submittedBy?: number;
  formId: number;
  screenoutQuestionIds?: number[];
  metadata?: any;
  campaignId?: number;
  data: { [key: number]: any };
};

export type FormResponseData = {
  id: number;
  formId: number;
  data: {
    labelled?: { [key: string]: any };
    form: { [key: number]: any };
    raw?: {
      id: number;
      response: any;
      questionId: number;
      questionLabel: string;
      inputType: string;
      metadata?: any;
      responseId: number;
    }[];
  };
  tags?: string[];
  systemTag?: string;
  meta?: {
    campaignId?: number;
    campaignType?: string;
    applicantStageId?: number;
  };
  companyId?: number;
  formName?: string;
};

export type FormResponse = FormResponseRecord & FormResponseData;
export type FormQuestion = {
  id?: number;
  formId?: number;
  sectionId?: number;
  questionText: string;
  inputType: string;
  instructions?: string;
  videoUrl?: string;
  videoType?: string;
  audioUrl?: string;
  audioType?: string;
  isRequired?: boolean;
  dataIndex?: number;
  mediaCapture?: boolean;
  mediaUpload?: boolean;
  minLength?: number;
  maxLength?: number;
  deletedBy?: number;
  deletedDate?: Date;
  isHidden?: boolean;
  patternInfo?: string;
  patternError?: string;
  nameMiddle?: boolean;
  min?: number;
  max?: number;
  label?: string;
  options?: string[];
  accept?: string[];
  validation?: CustomRule;
  screening?: CustomRule;
  pattern?: string[];
  shifts?: string[];
  masking?: CustomRule;
  isDependant?: boolean;
  audioFile?: string[];
  videoFile?: string[];
  promptFiles?: string[];
  includeAudio?: boolean;
  includeVideo?: boolean;
  tags?: QuestionTag[];
  tagIds?: number[];
  audioTranscript?: string;
  maxMediaPlays?: number;
};
export type FormSection = {
  id?: number;
  formId?: number;
  name: string;
  icon?: IconProp;
  dataIndex?: number;
  deletedBy?: number;
  deletedDate?: Date;
  questions?: FormQuestion[];
};
export type FormTag = {
  id: number;
  tagId: number;
  formId: number;
  tagName?: string;
};
export type QuestionTag = {
  id?: number;
  tagId: number;
  questionId?: number;
};
export type Form = {
  id?: number;
  companyId: number;
  name: string;
  type: string;
  createdDate: Date;
  createdBy: number;
  modifiedDate?: Date;
  modifiedBy?: number;
  intro?: string;
  outro?: string;
  sections: FormSection[];
  responses: number;
  isSystem: boolean;
  allowPrev?: boolean;
  exitScreenouts?: boolean;
  requireLogin?: boolean;
  tags?: FormTag[];
  tagIds?: number[];
  deletedBy?: number;
  deletedDate?: Date;
};
export type TagName = {
  id: number;
  tagName: string;
  isSystem?: boolean;
};
// const customFormsApi = {
//   get: ({ id, filters }: { id?: number; filters?: ApiFilters }) =>
//     flexAPI.get<Form[]>('/forms' + (id ? '/' + id : ''), {
//       params: { filters }
//     }),
//   add: data => {
//     return flexAPI.post<Form>('/forms', data).then(d => d.data);
//   },
//   update: (id, data) =>
//     flexAPI.patch<Form>('/forms/' + id, data).then(d => d.data),
//   submit: (formId, meta, data, screenoutQuestionIds) =>
//     flexAPI.post('/forms/' + formId, {
//       data,
//       meta,
//       screenoutQuestionIds
//     }),
//   getTagNames: () => flexAPI.get<TagName[]>('/forms/tags').then(d => d.data),
//   addTagName: tagName => flexAPI.post<TagName[]>('/forms/tags', { tagName }),
//   getSystem: tagName => flexAPI.get('/forms/system', { params: { tagName } }),
//   responses: {
//     get: ({
//       formId,
//       responseId,
//       includeScreenouts
//     }: {
//       formId?: number;
//       responseId?: number | number[];
//       includeScreenouts?: boolean;
//     }) =>
//       flexAPI.get<FormResponse[]>('/forms/responses/', {
//         params: { filters: { formId, id: responseId }, includeScreenouts }
//       }),
//     edit: ({ formId, responseIds, data }) =>
//       flexAPI.patch('/forms/' + formId + '/responses/', { data, responseIds }),
//     user: userId =>
//       flexAPI.get<FormResponse[]>('/forms/responses/', {
//         params: { filters: { submittedBy: userId } }
//       })
//   },
//   getDependencies: id =>
//     flexAPI.get('/forms/' + id + '/dependencies').then(d => d.data)
// };
export const customFormsApi = new CrudApi<Form>('forms');
export const customFormTagsApi = new CrudApi<TagName>('forms/tags');
export const customFormsResponseApi = new CrudApi<
  FormResponse,
  FormResponseInput
>('forms/responses');
export const getFormDependencies = formId =>
  flexAPI.get('/forms/' + formId + '/dependencies').then(d => d.data);
export const getSystemForm = tagName =>
  flexAPI.get('/forms/system', { params: { tagName } }).then(d => d.data);
