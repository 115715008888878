import React, { ReactNode, useEffect, useState } from 'react';
import { Button, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import { SearchInput } from 'components/navbar/top/SearchBox';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Controller } from 'react-hook-form';
import Divider from 'components/common/Divider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ResponsiveModal } from 'components/common/Modals';
import ItemSelectorGroup from './ItemSelectorGroup';
import { groupBy } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { settings } from 'config';
import Truncate from './Truncate';
import DomainIcon from './DomainIcon';
import LockedContent from './LockedContent';
import { FormCheckInputProps } from 'react-bootstrap/esm/FormCheckInput';
import { WizardFormGroupProps } from 'components/wizard/WizardInput';
import { SelectorItemData } from './ItemSelectorItem';
const Item = ({
  className,
  avatar,
  onClick = () => {},
  icon,
  color,
  name,
  info,
  locked
}) => {
  return (
    <LockedContent
      locked={!!locked}
      tooltip={typeof locked === 'string' && locked}
      className={'h-100'}
    >
      <Flex
        className={classNames(
          'flex-column position-relative align-items-center rounded-3 p-2 w-100 h-100 justify-content-between',
          className
        )}
      >
        {avatar ? (
          <Avatar
            src={avatar}
            className={`status-offline`}
            mediaClass={'border border-3 rounded-circle border-200 bg-100'}
            size="4xl"
          />
        ) : (
          <DomainIcon size="lg" icon={icon} color={color} />
        )}
        <h6 className="fs-1 fw-semi-bold mb-0 mt-2">
          <div className="text-900 stretched-link" onClick={onClick}>
            <Truncate middle>{name}</Truncate>
          </div>
        </h6>
        {info &&
          Object.keys(info)
            .filter(name => !!info[name])
            .filter((_, i) => i < 4)
            .map((name, i) => (
              <span
                key={name}
                className={classNames(
                  'fs--2 lh-1 mb-0 pt-1 text-500 text-center',
                  {
                    'fw-light': i > 0
                  }
                )}
              >
                {info[name]}
              </span>
            ))}
      </Flex>
    </LockedContent>
  );
};
Item.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  color: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.object
};
const domainSettings = settings.domains;
export type SelectorItem = {
  icon?: ReactNode;
  label: string;
  avatar?: any;
  info?: Record<string, ReactNode>;
  group?: string;
  value: number;
  locked?: boolean | string;
};
export type ItemSelectorProps = {
  name: string;
  data: SelectorItem[];
  multiple?: boolean;
  label?: ReactNode;
  isLoading?: boolean;
  children?: any;
  registerProps?: any;
  placeholder?: string;
  avatar?: string;
  icon?: ReactNode;
  color?: string;
  instruction?: ReactNode;
  onNewClick?: () => void;
  domain?: keyof typeof domainSettings;
  open?: boolean;
  onClose?: () => void;
  confirmText?: string;
  formControlProps?: FormCheckInputProps;
  formGroupProps?: WizardFormGroupProps;
  hideLabel?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  compact?: boolean;
  readOnly?: boolean;
  selectedText?: (data: SelectorItemData[]) => ReactNode;
  onNameClick?: (data: SelectorItemData) => void;
};
const ItemSelector = ({
  name,
  data = [],
  multiple,
  label,
  isLoading,
  children,
  registerProps,
  placeholder,
  avatar,
  icon,
  color = 'primary',
  instruction,
  onNewClick,
  domain,
  open,
  onClose = () => {},
  confirmText = 'Done',
  formControlProps = {},
  formGroupProps = {},
  hideLabel,
  disabled,
  size = 'md',
  compact,
  readOnly,
  selectedText,
  onNameClick,
  ...rest
}: ItemSelectorProps) => {
  const domainIcon = settings.domains[domain]?.icon;
  const domainColor = settings.domains[domain]?.color;
  const [show, setShow] = useState<boolean>();
  useEffect(() => {
    setShow(open);
  }, [open]);
  useEffect(() => {
    if (!show) {
      onClose();
    }
  }, [show]);
  const [search, setSearch] = useState<string>();
  const required = registerProps?.required ?? true;
  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field, fieldState: { error }, formState: { errors } }) => {
        const isSelected = item => {
          return multiple
            ? Array.isArray(field.value) &&
                field.value?.some(v => v?.toString() == item.value?.toString())
            : field.value?.toString() === item.value.toString();
        };
        const groupDef = groupBy(data, f =>
          isSelected(f) ? 'Selected' : f.group || 'All'
        );
        const groups = {
          Selected: groupDef.Selected || [],
          ...groupDef
        };
        return (
          <Form.Group
            {...formGroupProps}
            className={classNames(
              { 'mb-3': !formGroupProps?.noMb },
              formGroupProps?.className
            )}
          >
            {label && !hideLabel && (
              <Form.Label aria-required={required}>
                <div className="d-flex">
                  {required && (
                    <p className="d-inline-block text-danger mb-0">*</p>
                  )}
                  <Flex alignItems={'baseline'}>
                    <span>{label}</span>
                    {onNewClick && (
                      <Button
                        size="sm"
                        onClick={onNewClick}
                        variant="link"
                        className="p-0 ms-2"
                      >
                        Add new
                      </Button>
                    )}
                  </Flex>
                </div>
                {instruction && (
                  <div className="form-text lh-base fw-normal flex-1">
                    {instruction}
                  </div>
                )}
              </Form.Label>
            )}
            <div
              {...rest}
              className={classNames('form-control cursor-pointer', {
                'is-invalid': !!error,
                'is-valid': Object.keys(errors).length && !error,
                'bg-200 pointer-events-none': disabled || readOnly
              })}
              // style={{ minHeight: 35 }}
              ref={field.ref}
              onClick={() => !disabled && !readOnly && setShow(true)}
            >
              {isLoading ? (
                <Skeleton />
              ) : children ? (
                children(field.value, disabled || readOnly)
              ) : (
                <ItemSelectorGroup
                  data={data.filter(d => isSelected(d))}
                  selectedText={selectedText}
                  avatar={avatar}
                  icon={icon || domainIcon}
                  placeholder={placeholder}
                  color={color || domainColor}
                  size={size}
                  compact={compact}
                  onNameClick={onNameClick}
                />
              )}
            </div>
            <Form.Control.Feedback type="invalid">
              {error?.message}
            </Form.Control.Feedback>
            <ResponsiveModal wide show={show} onHide={() => setShow(false)}>
              <Modal.Body className="position-relative">
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    //   setShow(false);
                  }}
                >
                  <div style={{ width: '90%' }} className="search-box">
                    <SearchInput
                      autoFocus
                      value={search}
                      setValue={setSearch}
                    />
                  </div>
                  <CloseButton
                    className="position-absolute end-0 top-0 mt-2 me-2"
                    onClick={() => setShow(false)}
                  />
                  <div
                    style={{
                      maxHeight: '80vh',
                      overflowY: 'auto',
                      overflowX: 'hidden'
                    }}
                  >
                    {Object.keys(groups).map((group, i) => (
                      <div key={i} className="w-100">
                        <Divider>
                          <h6>{group}</h6>
                        </Divider>
                        <Row>
                          {groups[group]
                            ?.filter(
                              d =>
                                !search ||
                                (
                                  d.label +
                                  ' ' +
                                  (d.info
                                    ? Object.values(d.info).join(' ')
                                    : '')
                                )
                                  .toLowerCase()
                                  .indexOf(search.toLowerCase()) >= 0
                            )
                            .map((item, i) => (
                              <Col key={i} xs={6} xxl={4}>
                                <Form.Check
                                  id={'itemSelect-' + item.value}
                                  className="p-0 text-center w-100 h-100"
                                >
                                  <Form.Check.Input
                                    value={item.value}
                                    checked={isSelected(item)}
                                    onChange={e => {
                                      console.log(
                                        'changed',
                                        e.target.value,
                                        e.target.checked,
                                        multiple
                                      );
                                      const c = e.target.checked;
                                      const v = e.target.value;
                                      const current = multiple
                                        ? field.value || []
                                        : field.value || '';
                                      if (c) {
                                        if (!multiple) {
                                          field.onChange(v);
                                          setShow(false);
                                          return;
                                        }
                                        field.onChange(
                                          current.concat([v]) || [v]
                                        );
                                      } else {
                                        if (!multiple) {
                                          return field.onChange('');
                                        }
                                        field.onChange(
                                          current.filter(
                                            f => f?.toString() !== v?.toString()
                                          ) || []
                                        );
                                      }
                                    }}
                                    className="d-none"
                                    {...formControlProps}
                                  />
                                  <Form.Check.Label className="w-100 h-100 mb-0">
                                    <Item
                                      locked={item.locked}
                                      name={item.label}
                                      className={classNames(
                                        'cursor-pointer w-100 ',
                                        {
                                          'bg-primary-subtle': isSelected(item)
                                        }
                                      )}
                                      info={item.info}
                                      avatar={item.avatar || avatar}
                                      icon={item.icon || icon || domainIcon}
                                      color={color || domainColor}
                                    />
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    ))}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShow(false)} variant={'link'}>
                  Cancel
                </Button>
                <Button onClick={() => setShow(false)}>{confirmText}</Button>
              </Modal.Footer>
            </ResponsiveModal>
          </Form.Group>
        );
      }}
    />
  );
};
export default ItemSelector;
