import classNames from 'classnames';
import React, { HTMLProps, ReactNode } from 'react';
export default ({
  title,
  description,
  children,
  className,
  card = true,
  noPadding,
  noMargin,
  variant,
  ...rest
}: {
  noMargin?: boolean;
  noPadding?: boolean;
  card?: boolean;
  title: ReactNode;
  description?: ReactNode;
  children: ReactNode;
  className?: string;
  variant?: string;
} & Omit<HTMLProps<HTMLDivElement>, 'title'>) => {
  return (
    <div
      {...rest}
      className={classNames(
        'position-relative border rounded-3',
        { 'p-3': !noPadding },
        { 'mb-3': !noMargin },
        { [`border-${variant}-subtle`]: !!variant },
        { 'border-300': !variant }
      )}
    >
      <h6
        style={{ left: '2%' }}
        className={classNames(
          'position-absolute top-0 translate-middle-y',
          { 'bg-200': !card, 'bg-white': !!card },
          { [`text-${variant}`]: !!variant },
          { 'text-600': !variant }
        )}
      >
        {title}
      </h6>
      {description && <p className="form-text">{description}</p>}
      <div className={classNames(className, 'px-2')} {...rest}>
        {children}
      </div>
    </div>
  );
};
