import { ApiFilters } from 'apis/types';
import api from '.';
import { cleanFilePath } from 'helpers/utils';
import { CountBy, CrudCounts } from 'hooks/useDefaultCrud';

export default class CrudApi<
  TRead extends { id?: number; deletedBy?: number; deletedDate?: Date },
  TWrite = TRead,
  TReadData = TRead & { deletedBy?: number; deletedDate?: Date },
  TWriteData = TWrite
> {
  #baseUrl: string;
  constructor(protected baseUrl: string) {
    this.#baseUrl = cleanFilePath(baseUrl) + '/';
  }
  getOne = async (id: number | string) => {
    return api.get<TReadData[]>(this.#baseUrl + id).then(d => d.data);
  };

  get = async (filters?: ApiFilters, includeDeleted?: boolean) => {
    return api
      .get<TReadData[]>(this.#baseUrl, {
        params: { filters, includeDeleted }
      })
      .then(d => d.data);
  };

  counts = async <TBy extends CountBy<TReadData>[]>(
    by?: string | string[],
    filters?: ApiFilters,
    allowedOnly?: boolean,
    includeDeleted?: boolean
  ) => {
    return api
      .get<CrudCounts<TReadData, TBy>>(
        this.#baseUrl + (allowedOnly ? '/allowed-counts' : '/counts'),
        {
          params: { filters, includeDeleted, by }
        }
      )
      .then(d => d.data);
  };

  getPublic = async (filters?: ApiFilters, includeDeleted?: boolean) => {
    return api
      .get<Partial<TReadData>[]>(this.#baseUrl + '/public', {
        params: { filters, includeDeleted }
      })
      .then(d => d.data);
  };

  insert = async (data: Omit<TWriteData, 'id'>, noReturn?: boolean) => {
    return api
      .post<TReadData>(this.#baseUrl, { data, noReturn })
      .then(d => d.data);
  };

  selfInsert = async (data: Omit<TWriteData, 'id'>, noReturn?: boolean) => {
    return api
      .post<TReadData>(this.#baseUrl + 'me', { data, noReturn })
      .then(d => d.data);
  };

  clone = async (id: number, noReturn?: boolean) => {
    return api
      .post<TReadData>(this.#baseUrl + id + '/clone', { noReturn })
      .then(d => d.data);
  };

  insertBulk = async (data: Omit<TWriteData, 'id'>[], noReturn?: boolean) => {
    return api
      .post<TReadData[]>(this.#baseUrl + '/bulk', { data, noReturn })
      .then(d => d.data);
  };

  update = async (
    id: number,
    data: Partial<Omit<TWriteData, 'id'>>,
    noReturn?: boolean
  ) => {
    return api
      .patch<TReadData>(this.#baseUrl + id, { data, noReturn })
      .then(d => d.data);
  };

  updateBulk = async (
    filters: ApiFilters,
    data: Partial<Omit<TWriteData, 'id'>>,
    noReturn?: boolean
  ) => {
    return api
      .patch<TReadData>(this.#baseUrl, { data, filters, noReturn })
      .then(d => d.data);
  };

  selfUpdate = async (
    id: number,
    data: Partial<Omit<TWriteData, 'id'>>,
    noReturn?: boolean
  ) => {
    return api
      .patch<TReadData>(this.#baseUrl + 'me/' + id, { data, noReturn })
      .then(d => d.data);
  };

  remove = async (id: number) => {
    return api.delete(this.#baseUrl + id).then(d => d.data);
  };
}
