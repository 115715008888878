import React from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { createContext } from 'react';
import { WizardInputProps } from './WizardInput';
const InputContext = createContext({});
const InputConfig = ({
  children,
  ...rest
}: Partial<WizardInputProps> & { children: React.ReactNode }) => {
  return (
    <InputContext.Provider value={{ ...rest }}>
      {children}
    </InputContext.Provider>
  );
};
InputConfig.propTypes = {
  children: PropTypes.node
};
export const useInputConfig = () =>
  useContext<Partial<WizardInputProps>>(InputContext);
export default InputConfig;
