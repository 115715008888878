import { useSearchParams } from 'react-router-dom';

export default <TData = any>() => {
  const [params] = useSearchParams();
  const filters = [...params.keys()].map(k => ({
    id: k as keyof TData & string,
    value: { value: params.getAll(k), type: 'in', active: true }
  }));
  return filters;
};
