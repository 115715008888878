import React from 'react';
import TourContent from './TourContent';
import useNewTour from './useNewTour';
import useOneTimeTour from './useOneTimeTour';

const TableTour = () => {
  const { isNotDone, setDone } = useOneTimeTour({ name: 'table' });
  const steps = [
    {
      selector: '.tour-advance-table',
      content: () => (
        <TourContent title="Content tables">
          You'll see a lot of these tables in Flex. It's how most content is
          displayed. Let's take a quick dive into what you can do with them!
        </TourContent>
      )
    },
    {
      selector: '.tour-advance-table .tour-btn-filter',
      highlightedSelectors: ['.tour-filters-body'],
      mutationObservables: ['.tour-filters-body'],
      resizeObservables: ['.tour-filters-body'],
      content: () => (
        <TourContent>
          Here you can filter your content to find exactly what you need
        </TourContent>
      )
    },
    {
      selector: '.tour-advance-table .tour-select-checkbox',
      content: ({ isHighlightingObserved }) => (
        <TourContent>
          You can select multiple elements using{' '}
          {isHighlightingObserved
            ? 'these checkboxes'
            : ' the checkboxes on the left'}
          , and apply actions to all of them in bulk
        </TourContent>
      )
    },
    {
      selector: '.tour-advance-table .tour-btn-new-record',
      content: ({ isHighlightingObserved }) => (
        <TourContent>
          {isHighlightingObserved ? (
            <>You can add a new item here.</>
          ) : (
            <>You can add a new item by clicking the 'add' button at the top.</>
          )}{' '}
          You will usually be taken to a new page to create it, but you will be
          brought back here after
        </TourContent>
      )
    },
    {
      selector: '[data-tour="table-data-exporter"]',
      content: () => (
        <TourContent>You can export all the table data here</TourContent>
      )
    },
    {
      selector: '.tour-advance-table .tour-per-page',
      actionAfter: setDone,
      content: () => (
        <TourContent>
          Want to see more or fewer items? Change the number of items per page
          here
        </TourContent>
      )
    }
  ];

  useNewTour('table', steps, isNotDone);
  return <></>;
};
export default TableTour;
