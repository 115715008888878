import { useMutation, useQueryClient } from '@tanstack/react-query';
import { customFormTagsApi, TagName } from 'apis/flex/customForms';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import useTags from '../hooks.js/useTags';
const TagSelector = ({
  name,
  inputProps = {}
}: {
  name: string;
  inputProps?: Partial<WizardInputProps>;
}) => {
  const { data: tagsOptions, isLoading: tagsLoading } = useTags({
    useFilter: true,
    select: d =>
      d.map(t => ({
        value: t.id,
        label: t.tagName,
        disabled: t.isSystem
      }))
  });
  const queryClient = useQueryClient();
  const { mutate, isLoading: newTagLoading } = useMutation<
    TagName,
    Error,
    string
  >({
    mutationFn: v => customFormTagsApi.insert({ tagName: v }),
    onSuccess: () => queryClient.invalidateQueries(['CustomFormTags'])
  });
  const { setValue, getValues } = useFormContext();
  const handleCreate = val => {
    mutate(val, {
      onSuccess: added => {
        const oldVal = getValues(name);
        const newVal = [
          ...(!oldVal || Array.isArray(oldVal) ? oldVal : [oldVal]),
          added.id
        ];
        console.log('setting new tag value', name, newVal);
        setValue(name, newVal, {
          shouldDirty: true,
          shouldTouch: true
        });
      }
    });
  };
  const pluginProps = useMemo(
    () => ({
      creatable: true,
      onCreate: handleCreate,
      isLoading: newTagLoading
    }),
    []
  );
  const registerProps = useMemo(
    () => ({
      required: false
    }),
    []
  );
  return (
    <WizardInput
      name={name}
      label="Tags"
      registerProps={{ ...registerProps }}
      {...inputProps}
      loading={inputProps.loading || tagsLoading}
      type="select"
      multiple
      pluginProps={pluginProps}
      options={tagsOptions}
    />
  );
};
TagSelector.propTypes = {
  name: PropTypes.string.isRequired,
  inputProps: PropTypes.object
};
export default TagSelector;
