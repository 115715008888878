const lightBounce = {
  // delay: stagger * index,
  type: 'spring',
  mass: 0.5,
  damping: 12,
  velocity: 10,
  stiffness: 200
};
const lightBounceStagger = index => ({
  delay: 0.2 * index,
  type: 'spring',
  mass: 0.5,
  damping: 12,
  velocity: 10,
  stiffness: 200
});

export const transitions = {
  lightBounce,
  lightBounceStagger
};
