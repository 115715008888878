import { Department, departmentsApi } from 'apis/flex/hr';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <TSelected = Department[]>(
  props: UseCrudProps<Department, TSelected>
) => {
  return useDefaultCrud<Department, TSelected>(
    'Departments',
    departmentsApi,
    props
  );
};
