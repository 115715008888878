import { createContext, MutableRefObject, Ref } from 'react';
import { settings } from 'config';
import { User } from 'components/app/users/types';
import { GenericModalProps } from 'hooks/useModal';
import { UseCrudProps } from 'hooks/useDefaultCrud';

const AppContext = createContext(settings);

export const EmailContext = createContext({ emails: [] });

export const AuthContext = createContext({
  user: null
});

export const AgentContext = createContext({
  agent: {}
});
export const ProductContext = createContext({ products: [] });

export const CourseContext = createContext({ courses: [], primaryCourses: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const JobApplicationContext = createContext({ user: {} });

export const ChatContext = createContext({});

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});
export const AdminDataContext = createContext({
  data: [],
  columns: []
});

export const UserContext = createContext({} as User);
export const RefreshUserContext = createContext(null);

export const FormEditorContext = createContext({});

export const ReturnToContext = createContext([]);

export const ModalContext = createContext<{
  modal?: GenericModalProps;
  setModal?: (props: GenericModalProps) => void;
}>({});

export const CrudContext = createContext<UseCrudProps<any, any>>({});

export const LoginContext = createContext<{
  settings: { welcomed: boolean };
  setSettings: (...args) => void;
}>({
  settings: {
    welcomed: false
  },
  setSettings: () => {}
});

export const LayoutContext = createContext<{
  mainRef?: MutableRefObject<HTMLDivElement>;
}>({
  mainRef: null
});

export default AppContext;
