import React from 'react';
import ApplicantListItem from './ApplicantListItem';
import { AnimatePresence, motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
const ApplicantIconGroup = ({ data }) => {
  const rowHeight = 40;
  const avatarWidth = 32;
  const compactGap = 10;
  const parent = {
    hover: {
      height: data.length * rowHeight,
      width: '100%'
      // transition: {
      // when: 'beforeChildren',
      // staggerChildren: 0.1
      // }
    },
    initial: {
      height: 57,
      width: compactGap * data.length + avatarWidth,
      transition: {
        when: 'afterChildren'
      }
    }
  };
  const children = {
    hover: i => ({
      x: 0,
      y: rowHeight * i,
      // width: '100%',
      transition: {
        when: false
      }
    }),
    initial: i => ({
      opacity: 1,
      x: compactGap * i,
      // width: avatarWidth,
      transition: {
        when: false
      }
    })
  };
  return (
    <>
      <Form.Label>{data.length} applicants</Form.Label>
      <AnimatePresence>
        <motion.div
          className="position-relative mb-3"
          variants={parent}
          initial={'initial'}
          whileHover={'hover'}
          transition={transitions.lightBounce}
        >
          {data.map((row, i) => {
            return (
              <motion.div
                className="position-absolute"
                variants={children}
                custom={i}
                key={i}
                style={{ zIndex: i + 1 }}
                // initial={{ opacity: 0, x: '100px' }}
                animate={{
                  opacity: 1,
                  x: compactGap * i,
                  // width: avatarWidth,
                  transition: {
                    when: false
                  }
                }}
                // transition={transitions.lightBounceStagger(i)}
              >
                <ApplicantListItem key={i} data={row} />
              </motion.div>
            );
          })}
        </motion.div>
      </AnimatePresence>
    </>
  );
};
ApplicantIconGroup.propTypes = {
  data: PropTypes.array
};
export default ApplicantIconGroup;
