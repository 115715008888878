import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Error401 = ({ roles }) => {
  const nav = useNavigate();
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">401</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Unauthorised
        </p>
        <hr />
        <p>
          It looks like you don't have the required permissions to access this
          page. Please contact your manager to assign the correct permissions.
        </p>
        {roles && (
          <p>
            One of the following permissions are required: {roles.join(', ')}.
          </p>
        )}
        <Link className="btn btn-primary btn-sm mt-3" onClick={() => nav(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
          Go back
        </Link>
      </Card.Body>
    </Card>
  );
};
Error401.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string)
};
export default Error401;
