import React, { useMemo } from 'react';
import CustomWizardQuestion from './CustomWizardQuestion';

export default ({ section, allQuestions }) => {
  return useMemo(
    () =>
      (section.questions || []).map(
        (question, i) =>
          question.inputType &&
          !question.isHidden && (
            <CustomWizardQuestion
              allQuestions={allQuestions}
              key={i}
              question={question}
            />
          )
      ),
    [section?.questions, allQuestions]
  );
};
