import { useQuery } from '@tanstack/react-query';
import { ApplicantResponse, applicantsAPI } from 'apis/flex/recruitment';
import { ApiFilters } from 'apis/types';

export default <T = ApplicantResponse[]>({
  select = d => d as T,
  filters,
  enabled = true,
  staleTime = 1000 * 30,
  ...rest
}: {
  select?: (data: ApplicantResponse[]) => T;
  filters?: ApiFilters;
  staleTime?: number;
  enabled?: boolean;
} = {}) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['Applicants', filters, staleTime],
    queryFn: () =>
      applicantsAPI.get({
        filters
      }),
    select,
    enabled,
    ...rest
  });
  return { data, isLoading, error };
};
