import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
export type VideoPlayerProps = {
  url: string;
  autoplay?: boolean;
} & ReactPlayerProps;
const VideoPlayer = ({ url, autoplay = false, ...rest }: VideoPlayerProps) => {
  return (
    <div style={{ position: 'relative', paddingTop: '56.25%' }}>
      <ReactPlayer
        url={url}
        controls
        // light
        height={'100%'}
        autoPlay={autoplay}
        width={'100%'}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: 'var(--falcon-border-radius-lg)'
        }}
        className={'rounded-3'}
        fallback={
          <div style={{ height: 150, width: '100%' }}>
            <Skeleton />
          </div>
        }
        {...rest}
      />
    </div>
  );
};
VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  autoplay: PropTypes.bool
};
export default VideoPlayer;
