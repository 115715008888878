import React from 'react';
import ItemSelector, {
  ItemSelectorProps
} from 'components/common/ItemSelector';
import useForms, { useFormsWithQuestions } from '../hooks.js/useForms';
import { useMemo } from 'react';
import { format } from 'date-fns';
import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import { Form } from 'apis/flex/customForms';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import DomainItemIcon from 'components/common/DomainItemIcon';
const useFormItems = ({
  requiredQuestionTypes = null,
  includeDeleted = false,
  filter
}) => {
  const defaultFilter = f => !f.isSystem && filter(f);
  const { data: formsWithQuestions, isLoading: withQuestionsLoading } =
    useFormsWithQuestions({
      enabled: !!requiredQuestionTypes,
      fields: requiredQuestionTypes,
      select: d => d.filter(defaultFilter),
      includeDeleted
    });
  const { data: forms, isLoading } = useForms({
    enabled: !requiredQuestionTypes,
    select: d => d.filter(defaultFilter),
    useFilter: true,
    includeDeleted
  });
  const data = useMemo(
    () =>
      ((requiredQuestionTypes ? formsWithQuestions : forms) || []).map(f => ({
        value: f.id,
        label: f.name,
        info: {
          responses: (f.responses || 0) + ' responses',
          created: format(new Date(f.createdDate), 'dd/MM/yyyy'),
          questions:
            f.sections?.reduce((a, b) => a + (b.questions?.length || 0), 0) +
            ' questions'
        },
        tags: (
          <>
            {f.deletedBy ? <SoftBadge bg="danger">Deleted</SoftBadge> : null}
            {f.tags?.map(t => (
              <SoftBadge key={t.id} className="me-1">
                {t.tagName}
              </SoftBadge>
            ))}
          </>
        )
      })),
    [forms, formsWithQuestions]
  );
  return {
    data,
    isLoading:
      (isLoading && !requiredQuestionTypes) ||
      (withQuestionsLoading && !!requiredQuestionTypes)
  };
};
const FormPicker = ({
  name,
  requiredQuestionTypes = null,
  filter = () => true,
  includeDeleted,
  ...rest
}: {
  name: string;
  includeDeleted?: boolean;
  requiredQuestionTypes?: string[];
  filter?: (data: Form) => boolean;
} & Partial<ItemSelectorProps>) => {
  const { data, isLoading } = useFormItems({
    requiredQuestionTypes,
    includeDeleted,
    filter
  });
  return (
    <ItemSelector
      data={data}
      domain="form"
      name={name}
      onNewClick={() =>
        window.open(window.location.origin + '/hr/recruitment/forms/new')
      }
      onNameClick={item =>
        window.open(window.location.origin + '/forms/' + item.value)
      }
      isLoading={isLoading}
      {...rest}
    />
  );
};
export const SelectedForms = ({ ids, ...rest }: SelectedDomainItemsProps) => {
  const { data } = useFormItems({
    filter: form => ids.some(i => i == form.id),
    includeDeleted: true
  });
  return <DomainItemIcon data={data} domain="form" {...rest} />;
};
FormPicker.propTypes = {
  name: PropTypes.string.isRequired,
  filter: PropTypes.func,
  requiredQuestionTypes: PropTypes.arrayOf(PropTypes.string)
};
export default FormPicker;
