import React, { HTMLProps, ReactNode } from 'react';
import classNames from 'classnames';

export type FlexProps = {
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  inline?: boolean;
  wrap?: string;
  children: ReactNode;
  tag?: 'div' | 'span';
  breakpoint?: string;
  direction?: string;
} & HTMLProps<HTMLDivElement>;
const Flex = ({
  justifyContent = null,
  alignItems = null,
  alignContent = null,
  inline = null,
  wrap = null,
  className = null,
  tag: Tag = 'div',
  children,
  breakpoint = null,
  direction = null,
  ...rest
}: FlexProps) => {
  return (
    <Tag
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap
        },
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Flex;
