import React from 'react';
import classNames from 'classnames';
import { settings } from 'config';
import { FiBox } from 'react-icons/fi';

export default ({
  domain = null,
  size = 'sm',
  className = null,
  icon = null,
  color = 'info',
  width = null,
  bg = null,
  text = null
}) => {
  const widths = {
    xs: 24,
    sm: 32,
    md: 56,
    lg: 98
  };
  const iconWidth = width || widths[size];
  const domainColor = domain
    ? settings.domains[domain]?.variant || color
    : color;
  const domainIcon = icon ? icon : domain && settings.domains[domain]?.icon;
  const height = iconWidth;
  return (
    <div
      style={{ width: height, height }}
      className={classNames(
        className,
        `d-inline-flex align-items-center justify-content-center rounded-circle shadow-none text-center`,
        {
          'p-0 fs-0': size === 'xs',
          'p-2 fs-1': size === 'sm',
          'p-2 fs-3': size === 'md',
          'p-3 fs-5': size === 'lg',
          [`bg-${domainColor}-subtle`]: !bg,
          [`bg-${bg}-subtle`]: bg,
          [`text-${text}`]: text,
          [`text-${domainColor}`]: !text
        }
      )}
    >
      {domainIcon || <FiBox />}
    </div>
  );
};
