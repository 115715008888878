import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const Error500 = ({ error, button }: { error?: Error; button?: ReactNode }) => {
  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">500</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Whoops, something went wrong!
        </p>
        <hr />
        <p>
          Try refreshing the page, or going back and attempting the action
          again. If this problem persists,
          <a href="mailto:info@exmaple.com" className="ms-1">
            contact us
          </a>
          .
        </p>
        {!!error && !!error.message && (
          <>
            <hr />
            <p className="text-700">The error received was {error.message}.</p>
          </>
        )}
      </Card.Body>
      {button && <Card.Footer>{button}</Card.Footer>}
    </Card>
  );
};
Error500.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string.isRequired })
};
export default Error500;
