import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import companyConfig from 'companyConfig';
import { ErrorComponentLayout } from 'layouts/ErrorLayout';

const LoginError = ({ error, button }) => {
  return (
    <ErrorComponentLayout>
      <Card className="text-center h-100">
        <Card.Body className="p-5">
          <div className="display-1 fs-error text-300">401</div>
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            Unauthorised!
          </p>
          <p>
            It looks like we couldn't log you in. It may be that you do not have
            a user account set up. Please check your login credentials and try
            again.
          </p>
          <hr />
          <p>
            If you have been invited to use Flex by a colleague, please ensure
            the email address you're signing in with is the same as the one we
            sent the invitation to. If you believe you're seeing this page in
            error, please
            <a href={'mailto:' + companyConfig.supportEmail} className="ms-1">
              contact us
            </a>
            .
          </p>
          {error && (
            <>
              <hr />
              <p className="form-text">{error.message}</p>
            </>
          )}
        </Card.Body>
        <Card.Footer>{button}</Card.Footer>
      </Card>
    </ErrorComponentLayout>
  );
};
LoginError.propTypes = {
  error: PropTypes.object,
  button: PropTypes.any
};
export default LoginError;
