import { ApiFilters } from 'apis/types.js';
import { customFormsResponseApi, FormResponse } from './customForms';
import api from './index';
import { User } from 'components/app/users/types.js';
import { Pipeline } from 'components/app/recruitment/campaigns/create/Pipelines';
import CrudApi from './CrudApi';
import { ValidationRuleResults } from 'helpers/validation/validate';

export const getFutureAcademies = () =>
  api.get('/recruitment/academies', {
    params: {
      filters: { first: 'startDateTime', comparitor: '>', second: new Date() }
    }
  });
export const addAcademy = (details, events) =>
  api.post('/recruitment/academies', { details, events });
export const editAcademy = (id, data, events) =>
  api.patch('/recruitment/academies/' + id, { data, events });
export const editAcademyEvent = (id, data) => {
  return api.patch('/recruitment/academies/events/' + id, data);
};
export const inviteToAcademy = (academyId, userIds, deadline) =>
  api.post('/recruitment/academies/' + academyId + '/invite', {
    userIds,
    deadline
  });
export const getAcademies = ({
  id,
  filters
}: {
  id?: number;
  filters?: ApiFilters;
}) =>
  api.get('/recruitment/academies/' + (id || ''), {
    params: {
      filters
    }
  });
export const getAcademyLeaders = filters =>
  api.get('/recruitment/academies/leaders/', {
    params: {
      filters
    }
  });
export const registerForAcademy = id =>
  api.post('/recruitment/academies/' + id + '/register/');
export type ApplicantStage = {
  id: number;
  applicantId: number;
  stageId: number;
  formId?: number;
  responseId?: number;
  dueDate?: Date;
  sendEmail?: boolean;
  emailBody?: string;
  completedDate?: Date;
  reviewDate?: Date;
  isPass?: boolean;
  reviewedBy?: number;
  startDate?: Date;
};
export type RecruitmentStage = {
  id: number;
  name: string;
  isActive?: boolean;
  formId?: number;
  sendEmail?: boolean;
  emailBody?: string;
  createdDate: Date;
  companyId: number;
};
export const stagesAPI = {
  get: (campaignId: number, filters?: ApiFilters) =>
    api
      .get<RecruitmentStage[]>(
        '/recruitment/campaigns/' + campaignId + '/stages',
        { params: filters }
      )
      .then(d => d.data),
  add: (campaignId: number, data: Omit<RecruitmentStage, 'id'>) =>
    api
      .post<RecruitmentStage>(
        '/recruitment/campaigns/' + campaignId + '/stages',
        data
      )
      .then(d => d.data),
  edit: (campaignId: number, id: number, data: Partial<RecruitmentStage>) =>
    api
      .patch<RecruitmentStage>(
        '/recruitment/campaigns/' + campaignId + '/stages/' + id,
        data
      )
      .then(d => d.data)
};
export type ApplicantScore = {
  id: number;
  applicantId: number;
  score: number;
  scoredBy: number;
  scoredDate: Date;
};
export type Applicant = {
  firstName: string;
  surname: string;
  email: string;
  telephone: string;
  responseId: number;
  appliedDate: string;
  acceptReject?: string;
  acceptRejectDate?: Date;
  acceptRejectBy?: number;
  stageId?: number;
  academyId?: number;
  companyId: number;
  campaignId: number;
  id: number;
  stages: (ApplicantStage & RecruitmentStage)[];
  user: User;
  currentStage: ApplicantStage & RecruitmentStage;
  campaignJobTitleId: number;
  scores: ApplicantScore[];
};
export type ApplicantResponse = {
  applicant: Applicant;
  response: FormResponse;
};
export const applicantsAPI = {
  get: async (params: { id?: number; filters?: ApiFilters } = {}) => {
    const a = await api.get<Applicant[]>(
      '/recruitment/applicants' + (params.id ? '/' + params.id : ''),
      {
        params
      }
    );
    const r = await customFormsResponseApi.get({
      id: a.data.map(aa => aa.responseId)
    });
    // console.log(a, r);
    return r
      .map(resp => ({
        applicant: a.data.find(aa => resp.id === aa.responseId),
        response: resp
      }))
      .filter(a => a.applicant);
  },
  add: data => api.post('/recruitment/applicants', { data }),
  edit: (id, data) => api.patch('/recruitment/applicants/' + id, { data }),
  reject: (applicantIds, feedback) => {
    return api.post('/recruitment/applicants/reject/', {
      applicantIds,
      feedback
    });
  },
  bank: ids => api.post('/recruitment/applicants/bank', { ids }),
  accept: (applicantIds, data) => {
    return api.post('/recruitment/applicants/accept/', {
      applicantIds,
      ...data
    });
  },
  score: (ids, score) =>
    api.patch('/recruitment/applicants/score', { ids, score }),
  stage: {
    move: (ids, data) =>
      api.post('/recruitment/applicants/stage', { ids, data }),
    get: (filters: ApiFilters) =>
      api.get('/recruitment/applicants/stage', { params: { filters } }),
    submit: (applicantStageId, responseId) =>
      api.post(
        '/recruitment/applicants/stage/' + applicantStageId + '/submit',
        { responseId }
      ),
    review: (applicantStageId, isPass) =>
      api.post(
        '/recruitment/applicants/stage/' + applicantStageId + '/review',
        { isPass }
      )
  }
};
export const interviewsApi = {
  getQuestionSets: id =>
    api.get('/recruitment/interviews/question-sets/' + (id || '')),
  invite: (applicantIds, questionSetId) => {
    return api.post('/recruitment/interviews/invite/', {
      applicantIds,
      questionSetId
    });
  },
  getResponseMedia: id =>
    api.get('/recruitment/interviews/responses/' + id + '/media'),
  uploadMedia: (interviewId, responses) => {
    const formData = new FormData();
    Object.keys(responses).forEach(k => {
      formData.append(k, responses[k]);
    });
    return api.post(
      '/recruitment/interviews/' + interviewId + '/media',
      formData
    );
  },
  submit: responses => {
    return api.post('/recruitment/interviews', responses);
  }
};

export type CampaignApplicant = {
  id: number;
  firstName: string;
  surname: string;
  appliedDate: string;
  acceptReject?: string;
};
export type Campaign = {
  id?: number;
  companyId: number;
  name: string;
  description: string;
  jobTitleId: number;
  advertTitle?: string;
  advertDescription?: string;
  createdDate: Date;
  createdBy: number;
  startDate: Date;
  endDate?: Date;
  jobStartDate?: Date;
  salaryMin?: number;
  salaryMax?: number;
  minHourlyRate?: number;
  maxHourlyRate?: number;
  isRemote?: boolean;
  formId?: number;
  isActive: boolean;
  isPrivate: boolean;
  coverPhoto?: string;
  publishDate?: Date;
  formEmailQuestionId?: string;
  formTelQuestionId?: string;
  formNameQuestionId?: string;
  allowRepeats: boolean;
  allowRepeatsAfter?: number;
  allowDuplicates: boolean;
  jobTitle?: string;
  stages?: RecruitmentStage[];
  applicants?: CampaignApplicant[];
  maxApplicants?: number;
  maxAccepted?: number;
  maxApplicantsReached?: boolean;
  inviteLink?: string;
  inviteLinkId?: number;
};

export const campaignsApi = new CrudApi<Campaign>('/recruitment/campaigns');
//
//
//DUMMY DATA
export const getNewApplicants = () => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 1000);
  });
};
export const getApplicantSource = () => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 500);
  });
};
export const getDailyApplicants = () => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 500);
  });
};
export const getToDos = () => {
  return new Promise(res => {
    res({
      data: []
    });
  });
};

export const getRunningCampaigns = () => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 3000);
  });
};
export const getAllCampaigns = () => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 3000);
  });
};
export const getRecruitRatio = () => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 3000);
  });
};
export type ApplicantPipeline = {
  id: number;
  pipelineId: number;
  applicantId: number;
  runDate?: Date;
  companyId: number;
  isTriggered?: boolean;
  filterResults?: ValidationRuleResults;
  pipeline: Pipeline;
};
export const getApplicantPipelines = ({
  applicantId,
  filters
}: {
  applicantId: number;
  filters?: ApiFilters;
}) =>
  api
    .get('/recruitment/applicants/' + applicantId + '/pipelines', {
      params: { filters }
    })
    .then(d => d.data);

export const rerunApplicantPipeline = applicantPipelineId =>
  api
    .post('/recruitment/applicants/pipelines/' + applicantPipelineId + '/rerun')
    .then(d => d.data);
