/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from '../Flex';
import { useAdvanceTable } from './AdvanceTableProvider';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

export const AdvanceTableFooter = ({
  viewAllBtn,
  navButtons = true,
  rowsPerPageOptions = [10, 25, 50, 100, 1000],
  className
}) => {
  const {
    getPrePaginationRowModel,
    getCanPreviousPage,
    getCanNextPage,
    pagination,
    setPagination,
    getRowModel
  } = useAdvanceTable();
  const [isAllVisible, setIsAllVisible] = useState(false);
  const rowsLength = getPrePaginationRowModel().rows.length;
  return (
    <div className="mt-3 p-2">
      <Flex
        className={classNames(
          className,
          'align-items-center justify-content-between'
        )}
      >
        <Flex alignItems="center" className="fs--1">
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              {Math.min(
                rowsLength,
                pagination.pageSize * pagination.pageIndex + 1
              )}{' '}
              to{' '}
              {pagination.pageSize * pagination.pageIndex +
                getRowModel().rows.length}{' '}
              of {rowsLength}
            </span>
            {viewAllBtn && (
              <>
                <span className="d-none d-sm-inline-block me-2">&mdash;</span>
                <Button
                  variant="link"
                  size="sm"
                  className="py-2 px-0 fw-semi-bold"
                  onClick={() => {
                    setIsAllVisible(!isAllVisible);
                    setPagination({
                      ...pagination,
                      pageSize: isAllVisible
                        ? pagination.pageSize
                        : getRowModel().rows.length
                    });
                  }}
                >
                  View {isAllVisible ? 'less' : 'all'}
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="ms-1 fs--2"
                  />
                </Button>
              </>
            )}
          </p>
          <p className="mb-0 mx-2">Show:</p>
          <Form.Select
            size="sm"
            className="w-auto pe-5 tour-per-page"
            onChange={e =>
              setPagination({
                ...pagination,
                pageSize: Number(e.target.value)
              })
            }
            defaultValue={pagination.pageSize}
          >
            {rowsPerPageOptions.map(value => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Flex>
        <Flex>
          {navButtons && (
            <Flex alignItems={'center'}>
              <Button
                size="sm"
                variant={getCanPreviousPage() ? 'primary' : 'light'}
                onClick={() =>
                  setPagination({
                    ...pagination,
                    pageIndex: 0
                  })
                }
                className={classNames({ disabled: !getCanPreviousPage() })}
              >
                <span className="d-none d-sm-inline">First</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </span>
              </Button>
              <Button
                size="sm"
                variant={getCanPreviousPage() ? 'primary' : 'light'}
                onClick={() =>
                  setPagination({
                    ...pagination,
                    pageIndex: pagination.pageIndex - 1
                  })
                }
                className={classNames('ms-1', {
                  disabled: !getCanPreviousPage()
                })}
              >
                <span className="d-none d-sm-inline">Previous</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
              </Button>
              <span className="fs--1 px-2">{pagination.pageIndex + 1}</span>
              <Button
                size="sm"
                variant={getCanNextPage() ? 'primary' : 'light'}
                className={classNames('px-sm-4', {
                  disabled: !getCanNextPage()
                })}
                onClick={() =>
                  setPagination({
                    ...pagination,
                    pageIndex: pagination.pageIndex + 1
                  })
                }
              >
                <span className="d-none d-sm-inline">Next</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </Button>
              <Button
                size="sm"
                variant={getCanNextPage() ? 'primary' : 'light'}
                className={classNames('ms-1', {
                  disabled: !getCanNextPage()
                })}
                onClick={() =>
                  setPagination({
                    ...pagination,
                    pageIndex: Math.ceil(rowsLength / pagination.pageSize) - 1
                  })
                }
              >
                <span className="d-none d-sm-inline">Last</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </span>
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default AdvanceTableFooter;
