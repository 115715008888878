import { useQuery } from '@tanstack/react-query';
import { Contract, contractsApi } from 'apis/flex/hr';

export default <T = Contract[]>({
  select,
  ...rest
}: { select?: (data: Contract[]) => T } = {}) => {
  return useQuery<Contract[], Error, T>({
    queryKey: ['contracts'],
    queryFn: () => contractsApi.get(),
    select,
    ...rest
  });
};
