import { User } from 'components/app/users/types.js';
import api from '.';
import { ApiFilters } from 'apis/types.js';
import CrudApi from './CrudApi';
import { Contract } from './hr';
import { EventPrefix } from './notifications';
const base = '/users/';

export type RoleDomain = EventPrefix | 'super';
export type RoleLevel = 'viewer' | 'creator' | 'editor' | 'admin';
export type RoleAction = 'view' | 'edit' | 'create';
export type UserRoles = Partial<Record<RoleDomain, Record<RoleLevel, boolean>>>;
export type UserView = User & {
  applicant: any;
  jobTitle: string;
  manager: string;
  managerEmail: string;
  contract: Contract;
  employeeId: number;
  departmentId: number;
  departmentName: string;
};

export const usersApi = new CrudApi<UserView>('/users');
export const checkUser = email =>
  api.get('/users/check', { params: { email } }).then(d => d.data);

export type UserGroup = {
  id: number;
  name: string;
  description?: string;
  companyId: number;
  managerId: number;
  userIds?: number[];
  avatar?: string;
};
export const getUserGroups = ({
  filters,
  id
}: {
  filters?: ApiFilters;
  id?: number;
}) =>
  api
    .get<UserGroup[]>(base + 'groups/' + (id || ''), { params: { filters } })
    .then(d => d.data);
export const updateUserGroup = (id: number, data: Partial<UserGroup>) =>
  api.patch<UserGroup>(base + 'groups/' + id, { data }).then(d => d.data);
export const addUserGroup = (data: Omit<UserGroup, 'id'>) =>
  api.post<UserGroup>(base + 'groups/', { data }).then(d => d.data);
export const updateUserMemberships = (userId: number, groupIds: number[]) =>
  api.put(base + '/' + userId + '/memberships', { groupIds });
export const updateGroupMemberships = (groupId: number, userIds: number[]) =>
  api.put(base + 'groups/' + groupId + '/memberships', { userIds });
