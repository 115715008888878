import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileId } from 'apis/types';
import { AudioFilePlayer } from 'components/common/AudioPlayer';
import Flex from 'components/common/Flex';
import HalfDoughnutChart from 'components/common/widgets/HalfDoughnutChart';
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import Wrapper from 'helpers/Wrapper';
import TextDiff from 'components/common/TextDiff';

export default ({
  analysis: v
}: {
  analysis: {
    input?: {
      fileId?: FileId;
      text?: string;
      reference?: string;
    };
    score: number;
    text?: string;
    audio?: FileId[];
  };
}) => {
  return (
    <Flex
      alignItems={'center'}
      direction={'column'}
      justifyContent={'center'}
      style={{ width: '13rem', maxWidth: '20rem' }}
    >
      <div className="w-100">
        <HalfDoughnutChart height={100} target={100} reached={v.score} />
      </div>
      <CountUp
        start={0}
        end={v.score}
        duration={2.75}
        className="d-inline-block mb-3 mt-n4 fs-5 fw-medium font-sans-serif text-center"
        separator=","
      />
      {!!v.audio &&
        (Array.isArray(v.audio) ? v.audio : [v.audio]).map(a => (
          <Row
            key={a}
            className="justify-content-center mb-3 align-items-center w-100 flex-nowrap g-0"
          >
            <Col sm={'auto'} className="text-700 fw-semibold">
              <AudioFilePlayer
                width={150}
                fileId={a}
                className="justify-content-start"
              />
            </Col>
          </Row>
        ))}
      {v.text && (
        <div className="text-700 fw-semibold fs--1 mb-3 w-100 text-start">
          <FontAwesomeIcon icon={faKeyboard} className="me-2 text-primary" />
          <Wrapper
            condition={!!(v.input?.text || v.input?.reference)}
            wrapper={c => (
              <TextDiff referenceText={v.input.reference || v.input.text}>
                {c}
              </TextDiff>
            )}
          >
            {v.text}
          </Wrapper>
        </div>
      )}
    </Flex>
  );
};
