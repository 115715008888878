import { FileId } from 'apis/types.js';
import api from '.';
import CrudApi from './CrudApi';
const baseUrl = '/hr';
export type Contract = {
  id: number;
  name: string;
  createdBy: number;
  createdDate: Date;
  companyId: number;
  fileId: FileId;
  signerTemplateId?: string;
  isActive: boolean;
  minWeeklyHours?: number;
  basicType?: string;
  basicRate?: number;
  minimumWage?: boolean;
  livingWage?: boolean;
  wageAddition?: number;
  signatures?: {
    x: number;
    y: number;
    page: number;
    width?: number;
    height?: number;
  }[];
};
export type UserContract = {
  id: number;
  contractId: number;
  startDate: Date;
  sentDate?: Date;
  endDate?: Date;
  isActive?: boolean;
  name?: string;
  userSignedDate?: Date;
};
export const contractsApi = {
  create: (data: Omit<Contract, 'id'>) =>
    api.post<Contract>(baseUrl + '/contracts', data),
  update: (id: number, data: Partial<Contract>) =>
    api.patch<Contract>(baseUrl + '/contracts/' + id, data),
  get: (id?: number) =>
    api.get<Contract[]>(baseUrl + '/contracts/' + (id || '')).then(d => d.data),
  send: (userIds: number[], contractId: number, data: UserContract) =>
    api.post<UserContract>(baseUrl + '/contracts/' + contractId + '/send', {
      userIds,
      data
    }),
  end: (userIds?: number[], id?: number) =>
    api.post<UserContract[]>(baseUrl + '/contracts/end', { userIds, id }),
  userGet: (userId: number) =>
    api
      .get<UserContract[]>(baseUrl + '/contracts/user/' + userId)
      .then(d => d.data)
};
export type Employee = {
  id: number;
  companyId: number;
  userId: number;
  applicantId?: number;
  onboardDate?: Date;
  terminationDate?: Date;
  dateOfBirth?: Date;
  homeAddress?: string;
  mobileNumber?: string;
  niNumber?: string;
  firstName: string;
  surname: string;
  email: string;
  jobTitleId: number;
  jobTitle?: string;
  managerId: number;
  manager?: string;
  avatar?: string;
  departmentId?: number;
};
export const employeesApi = new CrudApi<Employee>(baseUrl + '/employees');

export type TrainingCourse = {
  id: number;
  name: string;
  companyId: number;
  description?: string;
  active: boolean;
  createdDate: Date;
  createdBy: number;
  profileBackground?: [string];
  resources: TrainingResource[];
  isPublic?: boolean;
  prerequisites?: TrainingPrerequisite[];
  enforceOrder?: boolean;
};
export type TrainingPrerequisite = {
  id: number;
  courseId: number;
  prerequisiteId: number;
};
export type TrainingResource = {
  id: number;
  index: number;
  createdBy: number;
  createdDate: Date;
  courseId: number;
  name: string;
  description?: string;
  type: string;
  videoId?: [FileId];
  videoUrl?: string;
  formId?: string;
  confirmationTypes?: string[];
  url?: string;
  confirmationText: string;
  fileId?: [FileId];
  allowedAttempts?: number;
  autoApprove?: boolean;
};
export type TrainingQuizAttempt = {
  id: number;
  userResourceId: number;
  formResponseId: number;
};
export type UserTrainingResource = {
  id: number;
  resourceId: number;
  userCourseId: number;
  formResponseId?: number;
  signature?: string;
  checkbox?: boolean;
  lastSubmittedDate: Date;
  startedDate: Date;
  finishedDate?: Date;
  failedDate?: Date;
  failedBy?: number;
  failedReason?: string;
  approvedDate?: Date;
  approvedBy?: number;
  quizAttempts?: TrainingQuizAttempt[];
};
export type UserTrainingCourse = {
  id: number;
  courseId: number;
  userId: number;
  lastSubmittedDate?: Date;
  startedDate?: Date;
  deadlineDate?: Date;
  resources?: UserTrainingResource[];
};
export const trainingApi = {
  courses: new CrudApi<TrainingCourse>(baseUrl + '/training/courses'),
  userCourses: new CrudApi<UserTrainingCourse>(
    baseUrl + '/training/courses/user'
  )
};
export type Screening = {
  id: number;
  /**
   @param userId * Only available after creation
   */
  userId?: number;
  employeeId: number;
  addedBy?: number;
  addedDate?: Date;
  checks?: IDCheck[];
};
export type ScreeningCheckType =
  | 'Right to work'
  | 'Identity'
  | 'Criminal record';
export type ScreeningDocType =
  | 'Passport'
  | "Driver's license"
  | 'Share code'
  | 'Birth certificate'
  | 'Other';
export type IDCheck = {
  id?: number;
  screeningId?: number;
  checkType: ScreeningCheckType;
  docType?: ScreeningDocType;
  docFront?: FileId;
  docBack?: FileId;
  requestedDate?: Date;
  requestedBy?: number;
  submittedDate?: Date;
  approvedDate?: Date;
  approvedBy?: number;
  approvedNotes?: string;
  rejectedDate?: Date;
  rejectedBy?: number;
  rejectedNotes?: string;
};
export const screeningApi = new CrudApi<Screening>(baseUrl + '/screening');

export type JobRole = {
  id: number;
  companyId: number;
  jobTitle: string;
  jobDescription: string;
  departmentId?: number;
  roles?: string[];
  defaultManagerId?: number;
  defaultContractId?: number;
};
export const jobRolesApi = new CrudApi<JobRole>(baseUrl + '/job-titles');
export type Department = {
  id: number;
  name: string;
  description?: string;
  parentId?: number;
  companyId: number;
};
export const departmentsApi = new CrudApi<Department>(baseUrl + '/departments');
