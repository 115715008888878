import { useMutation } from '@tanstack/react-query';
import { commitMedia, updateMedia, uploadMedia } from 'apis/flex/helpers';
import { blobUrlToFile } from 'helpers/data';
import {
  getItemFromStore,
  setItemToStore,
  setPropertyByDotNotation
} from 'helpers/utils';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm
} from 'react-hook-form';
import { UseMediaFormReturn } from './useMediaFormContext';
type FileMutateOptions = {
  id: number;
  file: { type: string; blob: string; path: string; name: string };
};

/**
 * Generates a custom hook for handling media form submission.
 *
 * @param {string} authItemCollection - E.g. "form". Specify which, if any, auth collection the files should be restricted to. This will inform the file storage system to only allow access to authorised users for that collection.
 * @param {number} authItemId - Specifies, in combination with "authItemCollection", which specific item the files should be linked to. The hook also returns a "setAuthItemId" function to update this value after submission.
 * @param {Object} UseFormProps - The original props for the useForm() hook.
 * @return {Object} The custom hook for handling media form submission.
 */
const useMediaForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = TFieldValues
>({
  authItemCollection,
  authItemId,
  companyId,
  liveUpload = true,
  ...useFormProps
}: UseFormProps<TFieldValues, TContext> & {
  authItemCollection?: string;
  authItemId?: number;
  companyId?: number;
  liveUpload?: boolean;
} = {}): UseMediaFormReturn<TFieldValues, TContext, TTransformedValues> => {
  const formMethods = useForm<TFieldValues, TContext, TTransformedValues>(
    useFormProps
  );
  const { clearErrors, setError, setValue, getValues } = formMethods;
  const [fileUrls, setFileUrls] = useState([]);
  const [fileFields, setFileFields] = useState([]);
  const [fileFieldSettings, setFileFieldSettings] = useState({});
  const getFileValues = useCallback(() => {
    return getValues(fileFields).reduce((a, b, i) => {
      a[fileFields[i]] = b;
      return a;
    }, {});
  }, [getValues, fileFields]);
  const { mutate: mutateFile } = useMutation<any, Error, FileMutateOptions>({
    mutationFn: ({ id, file }) => {
      const type = file.type;
      return blobUrlToFile(file.blob, file.path || file.name, {
        type
      }).then(fileObj => {
        return uploadMedia(
          { [id]: fileObj },
          fileFieldSettings[id],
          !authItemCollection,
          companyId
        );
      });
    },
    onSuccess: (d, { id, file }) => {
      console.log('Uploaded file for', id, d.data[id][0]);
      updateFile(file, id, {
        uploading: false,
        uploaded: true,
        uploadPath: d.data[id][0],
        isTemp: true
      });
    },
    onError: (err, { id, file }) => {
      setError(id.toString() as any, err);
      updateFile(file, id, {
        uploading: false,
        uploaded: false,
        uploadError: err
      });
    }
  });
  const updateFile = useCallback(
    (file, id, vals) => {
      const files = getValues<any[]>(id);
      if (!files || !file) return;
      const arr = Array.isArray(files) ? files : [files];
      const fi = arr.findIndex(f => f?.blob === file.blob);
      if (fi >= 0) {
        Object.assign(arr[fi], vals);
        setValue(id, arr as any);
      }
    },
    [getValues, setValue]
  );
  const handleFileUpload = useCallback(
    (id, file) => {
      setFileUrls([...fileUrls, file.blob]);
      clearErrors(id);

      updateFile(file, id, {
        uploading: true,
        uploadError: false,
        uploaded: false,
        uploadPath: null
      });
      mutateFile({
        id,
        file
      });
    },
    [fileUrls, clearErrors, mutateFile, setFileUrls, updateFile]
  );
  const getMediaFields = useCallback(() => {
    const vals = getFileValues();
    return Object.keys(vals).reduce((a, k) => {
      // console.log('checking for media', k, vals[k]);
      const arr = Array.isArray(vals[k]) ? vals[k] : [vals[k]];
      if (
        vals[k] &&
        arr.some(f => (f.blob || f.preview) && (f.name || f.path))
      ) {
        a[k] = arr;
      }
      return a;
    }, {});
  }, [getFileValues]);
  const scanForMedia = useCallback(() => {
    const vals = getMediaFields();
    Object.keys(vals).forEach(k => {
      // console.log('scanning...', k, vals[k]);
      const toUpload = vals[k]
        .filter(d => d)
        .map((f, i) => Object.assign(f, { index: i }))
        .filter(f => {
          return (
            f.blob &&
            !fileUrls.includes(f.blob) &&
            !f.uploading &&
            !f.uploadPath
          );
        });
      toUpload.map(file => handleFileUpload(k, file));
    });
  }, [getMediaFields, fileUrls, handleFileUpload, liveUpload]);
  const storedCommittedFiles = useMemo(
    () => getItemFromStore('committedFiles', {}),
    []
  );
  const [commitedFiles, setCommitedFiles] = useState({});
  useEffect(() => {
    setCommitedFiles(storedCommittedFiles);
  }, [storedCommittedFiles]);
  useEffect(() => {
    setItemToStore('committedFiles', {
      ...storedCommittedFiles,
      ...commitedFiles
    });
  }, [commitedFiles]);
  const getCommittedValues = () => {
    const files = getMediaFields();
    const vals = getValues();
    Object.keys(files).forEach(qid => {
      const qFiles = files[qid].map(f => {
        return commitedFiles[f.uploadPath] || f.uploadPath;
      });
      setPropertyByDotNotation(vals, qid, qFiles);
      // setValue(qid, qFiles);
    }, {});
    return vals;
  };
  const { mutate: commitAndSubmit } = useMutation<
    any,
    Error,
    { files: any; onComplete: any }
  >({
    mutationFn: async ({ files }) => {
      const ids = Object.keys(files).flatMap(qid =>
        files[qid].filter(f => f.isTemp).map(f => f.uploadPath)
      );
      console.log('committing', ids, files);
      const resp = await commitMedia(ids, authItemCollection, authItemId);
      const committedLookup = ids.reduce(
        (a, b, i) => ({ ...a, [b]: resp.data[i] }),
        {}
      );
      let vals = getValues();
      for (const qid of Object.keys(files)) {
        const qFiles = files[qid].map(f => {
          return committedLookup[f.uploadPath] || f.uploadPath;
        });
        vals = setPropertyByDotNotation(vals, qid, qFiles);
        // setValue(qid, qFiles);
      }
      console.log('setting committed files', committedLookup, vals);
      setCommitedFiles(c => ({
        ...c,
        ...committedLookup
      }));
      return vals;
    },
    onSuccess: (newVals, { onComplete }) => {
      console.log('submitting with new vals', newVals);
      onComplete({
        ...newVals
      });
    }
  });
  const handleSubmit = useCallback(
    (onComplete, onError) => {
      const startSubmit = () => {
        scanForMedia();
        const waitStart = Date.now();
        const checkFiles = () => {
          const files = getMediaFields();
          console.log(
            'checking files for submit',
            files,
            isEmpty(files),
            Object.keys(files).every(k => files[k].every(file => file.uploaded))
          );
          if (
            isEmpty(files) ||
            Object.keys(files).every(k => files[k].every(file => file.uploaded))
          ) {
            if (!isEmpty(files)) {
              return commitAndSubmit(
                { files, onComplete },
                { onError: e => onError && onError(e) }
              );
            }
            return onComplete({
              ...getCommittedValues()
            });
          }
          const error = Object.keys(files).find(
            k => files[k].map(file => file.uploadError).filter(f => f)[0]
          );
          if (error) {
            console.error('Error uploading files on submit', error);
            if (!onError) {
              return;
            }
            return onError(error);
          }
          if (waitStart < Date.now() - 10000)
            return onError
              ? onError({ message: 'Timed out' })
              : new Error('Timed out');
          return setTimeout(checkFiles, 300);
        };
        setTimeout(checkFiles, 300);
      };
      return formMethods.handleSubmit(startSubmit as any, onError);
    },
    [fileFields]
  );
  useEffect(() => {
    if (!liveUpload) return;
    scanForMedia();
  }, [fileFields]);
  const registerMedia = useCallback(
    (field, settings) => {
      if (!fileFields.includes(field)) {
        console.log('registering media', field, fileFields);
        setFileFields(f => {
          const fields = new Set([...f, field]);
          // console.log('setting new fileFields', [...fields]);
          return [...fields];
        });
        //recursively ensure all values are either boolean, string or number
        const recursivelyCleanSettings = (settings: any, currentLevel = 0) => {
          if (!settings || currentLevel > 5) return;
          return Object.keys(settings).reduce((a, b) => {
            if (['boolean', 'string', 'number'].includes(typeof settings[b])) {
              return { ...a, [b]: settings[b] };
            }
            if (!!settings[b] && typeof settings[b] === 'object') {
              return {
                ...a,
                [b]: recursivelyCleanSettings(settings[b], currentLevel + 1)
              };
            }
            return a;
          }, {});
        };
        const cleanSettings = recursivelyCleanSettings(settings);
        setFileFieldSettings({ ...fileFieldSettings, [field]: cleanSettings });
      } else if (liveUpload) {
        scanForMedia();
      }
    },
    [fileFields]
  );
  const { mutate: update } = useMutation<
    any,
    Error,
    { authItemId: number; fileIds: string[] }
  >({
    mutationFn: ({ authItemId, fileIds }) => {
      return updateMedia(fileIds, { authItemCollection, authItemId });
    }
  });
  // console.log('getMediaFields', getMediaFields());
  const setAuthItemId = useCallback(
    commitedFiles =>
      (authItemId, cb = () => {}) => {
        const files = getMediaFields();
        const fileIds = Object.keys(files).flatMap(f =>
          files[f].map(
            file => commitedFiles[file.uploadPath] || file.uploadPath
          )
        );
        if (!authItemId) {
          throw new Error('Cannot set authItemId as ' + authItemId);
        }
        if (fileIds.length) {
          return update({ authItemId, fileIds }, { onSuccess: cb });
        }
        cb();
      },
    [getMediaFields]
  );
  return {
    ...formMethods,
    handleSubmit,
    registerMedia,
    setAuthItemId: useMemo(
      () => setAuthItemId(commitedFiles),
      [setAuthItemId, commitedFiles]
    ),
    triggerUpload: scanForMedia
  };
};
export default useMediaForm;
