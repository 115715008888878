import React, { ReactNode, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import googleMapStyles from 'helpers/googleMapStyles';
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  StandaloneSearchBox,
  GoogleMapProps
} from '@react-google-maps/api';
import mapMarker from '../../../src/assets/img/icons/map-marker.png';
import companyConfig from 'companyConfig';
// const libraries = ['places'];
export type MapProps = {
  children?: ReactNode;
  mapStyle?: string;
  darkStyle?: string;
  className?: string;
  initialCenter?: {
    lat: number;
    lng: number;
  };
  search?: string;
  style?: Record<string, any>;
} & GoogleMapProps;
const GoogleMap = ({
  mapStyle = null,
  initialCenter = {
    lat: companyConfig.companyLongLat[0],
    lng: companyConfig.companyLongLat[1]
  },
  darkStyle = null,
  className = null,
  children = null,
  search = null,
  style = {},
  ...rest
}: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    // libraries
  });

  const {
    config: { isDark }
  } = useContext(AppContext);

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [mapStyles, setMapStyles] = useState(mapStyle);

  const options = {
    mapTypeControl: true,
    streetViewControl: true,
    fullscreenControl: true,
    styles: googleMapStyles[mapStyles]
  };

  useEffect(() => {
    if (darkStyle && isDark) setMapStyles(darkStyle);
    else setMapStyles(mapStyle);
  }, [isDark]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className={className} style={style}>
      <ReactGoogleMap
        zoom={18}
        options={options}
        center={initialCenter}
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
        {...rest}
      >
        <Marker
          onClick={() => setShowInfoWindow(!showInfoWindow)}
          position={initialCenter}
          icon={mapMarker}
        >
          {children && showInfoWindow ? (
            <InfoWindow
              position={initialCenter}
              onCloseClick={() => setShowInfoWindow(false)}
            >
              <div>{children}</div>
            </InfoWindow>
          ) : null}
        </Marker>
        {search && (
          <StandaloneSearchBox>
            <input
              type="text"
              value={search}
              placeholder="Customized your placeholder"
              // style={inputStyle}
            />
          </StandaloneSearchBox>
        )}
      </ReactGoogleMap>
    </div>
  );
};

GoogleMap.propTypes = {
  mapStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
    'Cobalt'
  ]),
  darkStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
    'Cobalt'
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  search: PropTypes.string
};

export default GoogleMap;
