import React from 'react';
import { Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';

const TourContent = ({ title, children, disableNext }) => {
  const tour = useTour();
  const handleNext = () => {
    if (tour.currentStep === tour.steps.length - 1) {
      return tour.setIsOpen(false);
    }
    tour.setCurrentStep(tour.currentStep + 1);
  };
  const handlePrev = () => {
    tour.setCurrentStep(tour.currentStep - 1);
  };
  const location = useLocation();
  const prevLocation =
    tour.currentStep > 0 && tour.steps[tour.currentStep - 1].location;
  return (
    <div>
      {title && <Card.Header className="pb-0 h6">{title}</Card.Header>}
      <Card.Body className="pt-3 pb-0 fs--1 fs-lg-0">{children}</Card.Body>
      <Card.Footer className="pt-1 pb-0 d-flex justify-content-between">
        <Button
          disabled={
            tour.currentStep === 0 ||
            (prevLocation && prevLocation !== location.pathname)
          }
          size="sm"
          variant="link"
          onClick={handlePrev}
        >
          Prev
        </Button>
        <Button disabled={disableNext} size="sm" onClick={handleNext}>
          {tour.currentStep === tour.steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Card.Footer>
    </div>
  );
};
TourContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  disableNext: PropTypes.bool
};
export default TourContent;
