import { ResponsiveModal } from 'components/common/Modals';
import { ModalContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
export default (props?: GenericModalProps) => {
  const { modal, setModal } = useContext(ModalContext);
  useEffect(() => {
    if (props) setModal({ ...modal, ...props });
  }, []);
  useEffect(() => {
    return () => setModal(null);
  }, []);
  return {
    ...modal,
    // show: !!modal,
    setShow: (show: boolean) => setModal(m => ({ ...m, show }))
  };
};
export type GenericModalProps = ModalProps & {
  title?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
};
export const GenericModal = ({
  show,
  setShow,
  title,
  body,
  footer,
  ...rest
}: GenericModalProps) => (
  <ResponsiveModal
    show={show}
    setShow={setShow}
    onHide={() => setShow(null)}
    {...rest}
  >
    {title && (
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    )}
    {body && <Modal.Body>{body}</Modal.Body>}
    {footer && <Modal.Footer>{footer}</Modal.Footer>}
  </ResponsiveModal>
);
export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState<GenericModalProps>(null);
  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
      <GenericModal
        {...modal}
        setShow={(show: boolean) => setModal(m => ({ ...m, show }))}
        // show={!!modal}
      />
    </ModalContext.Provider>
  );
};
