import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';

const CustomTooltip = ({
  placement = 'top',
  content,
  children
}: {
  placement?: Placement;
  content: any;
  children: any;
}) => {
  return content ? (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip style={{ position: 'fixed' }}>{content}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
};

export default CustomTooltip;
