import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import AdminEditor from 'components/common/admin-table-v2/AdminEditor';
import { apiPromise } from 'apis/errors';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { ResponsiveModal } from '../Modals';
import { Modal } from 'react-bootstrap';
const AdminEditorModal = ({
  show,
  setShow,
  sqlTables,
  queryKeys,
  mutationFn,
  fields,
  count,
  data
}) => {
  const queryClient = useQueryClient();
  const mutateQuery = {
    mutationKey: [...queryKeys[0], 'edit'],
    mutationFn: (...args) =>
      apiPromise(
        async () => {
          handleClose();
          return await mutationFn(...args);
        },
        {
          success: 'Updated successfully',
          error: 'Oops! An error occurred. Please try again',
          pending: 'Updating...'
        }
      ).then(() => {}),
    onSuccess: () => {
      queryKeys.forEach(k => queryClient.invalidateQueries(k));
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  // const dataQuery = useMemo(() => {
  //   return {
  //     queryKey: [...queryKey, 'edit'],
  //     queryFn: () => (data ? Promise.resolve(data) : dataFn())
  //   };
  // }, [data, dataFn]);
  return (
    <ResponsiveModal wide size="lg" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Editing {count} records</Modal.Title>
        <FalconCloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <AdminEditor
          data={data}
          mutateQuery={mutateQuery}
          fields={fields}
          sqlTables={sqlTables}
          onClose={handleClose}
        />
      </Modal.Body>
    </ResponsiveModal>
  );
};
AdminEditorModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  sqlTables: PropTypes.array,
  queryKeys: PropTypes.array,
  mutationFn: PropTypes.func,
  fields: PropTypes.array,
  data: PropTypes.object,
  count: PropTypes.number
};
export default AdminEditorModal;
