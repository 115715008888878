import GoogleMap, { MapProps } from 'components/map/GoogleMap';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
const MapDark = ({ children = null, ...props }) => {
  return (
    <GoogleMap mapStyle="Cobalt" className="vh-50 rounded-soft mt-5" {...props}>
      {children}
    </GoogleMap>
  );
};
MapDark.propTypes = {
  search: PropTypes.string
};
const MapLight = ({ children = null, ...props }) => {
  return (
    <GoogleMap
      mapStyle="Default"
      className="vh-50 rounded-soft mt-5"
      {...props}
    >
      {children}
    </GoogleMap>
  );
};
MapLight.propTypes = MapDark.propTypes;
const Map = (props: MapProps) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return <>{isDark ? <MapDark {...props} /> : <MapLight {...props} />}</>;
};
export default Map;
