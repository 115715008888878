import { ApiFilters, FileId } from 'apis/types.js';
import api from '.';
import { getMediaStreamUrl } from 'helpers/utils';
import CrudApi from './CrudApi';

export const decryptParams = (data, iv) =>
  api.get('/helpers/decrypt', { params: { data, iv } });
export const encryptParams = data =>
  api.get('/helpers/encrypt', { params: { data } }).then(d => d.data);
export const uploadMedia = (
  fileObject: Record<string | number, File | File[]> = { id: null },
  settings,
  isPublic,
  companyId
) => {
  const formData = new FormData();
  Object.keys(fileObject).forEach(k => {
    let arr: File[];
    const obj = fileObject[k];
    if (Array.isArray(obj)) {
      arr = obj;
    } else {
      arr = [obj];
    }
    arr.forEach(file => {
      formData.append(k, file);
    });
  });
  formData.append('settings', JSON.stringify(settings));
  formData.append('companyId', companyId);
  return api.post('/helpers/media' + (isPublic ? '/public' : ''), formData);
};
export const commitMedia = (ids, authItemCollection, authItemId) =>
  api.post('/helpers/media/commit', { ids, authItemCollection, authItemId });
export const updateMedia = (ids, data) =>
  api.patch('/helpers/media/batch', { ids, data });

//GET requests have to have their extensions split so Chrome doesn't do strange things with CORS/content sniffing
export const getMedia = (id: string) =>
  api
    .get('/helpers/media', {
      params: { id: id.split('.')[0], ext: id.split('.')[1] }
    })
    .then(d => d.data);
export const getMediaUrl = (id: FileId) =>
  api
    .get<string>('/helpers/media/url', {
      params: { id: id.split('.')[0], ext: id.split('.')[1] }
    })
    .then(d => d.data);
export const getMediaStream = (id: FileId) =>
  api.get<any, Blob>(getMediaStreamUrl(id), { responseType: 'blob' });
export const regexAssist = prompt =>
  api.get('/helpers/ai/regex', { params: { prompt } }).then(d => d.data);
export type SpeechAnalysis = {
  text: string;
  fluency: number;
  tokens: string[];
  sentiment: {
    score: number;
    positive: string[];
    negative: string[];
  };
};
export const aiApi = {
  regexAssist,
  generateHtml: (prompt, settings) =>
    api.post('/helpers/ai/html', { prompt, settings }).then(d => d.data),
  generateText: (prompt, settings) =>
    api.post('/helpers/ai/text', { prompt, settings }).then(d => d.data),
  transcribe: ({ fileUrl, fileId }: { fileUrl?: string; fileId?: FileId }) =>
    api
      .get<string>('/helpers/ai/transcribe', {
        params: { url: fileUrl, fileId }
      })
      .then(d => d.data),
  speechAnalysis: (fileId: FileId) =>
    api
      .get<SpeechAnalysis>('/helpers/ai/speech-analysis', {
        params: { fileId }
      })
      .then(d => d.data)
};
export const getOneTimeTours = () =>
  api.get('/helpers/tours/').then(d => d.data);
export const setTourDone = name =>
  api.post('/helpers/tour/done', { name }).then(d => d.data);
export type SavedEvent = {
  id: number;
  data: Record<string, any>;
  options: Record<string, any>;
  itemId: string;
  domain: string;
  action: string;
  authorId: number;
  createdDate?: Date;
  createdBy?: number;
  sagaId?: string;
};
export const getDomainItemEvents = (
  {
    domain,
    itemId,
    actions,
    authorIds,
    customFilters
  }: {
    domain: string;
    itemId: string;
    actions?: string[];
    authorIds?: number[];
    customFilters?: ApiFilters;
  },
  { page, pageSize }: { page: number; pageSize: number }
) =>
  api
    .get<SavedEvent[]>('/helpers/domain-events', {
      params: {
        filters: {
          domain,
          itemId,
          action: actions,
          authorId: authorIds
        },
        customFilters,
        page,
        pageSize
      }
    })
    .then(d => d.data);
export type ShortLinkClick = {
  id: number;
  shortLinkId: number;
  clickDate?: Date;
  clickedByIp: string;
  clickedByUserAgent: string;
  clickSource: string;
};
export type ShortLink = {
  id: number;
  companyId?: number;
  hash?: string;
  url: string;
  expires?: number;
  createdDate?: Date;
  createdBy?: number;
  campaignId?: number;
  campaignType?: string;
  shortLink?: string;
};
export const shortLinksApi = new CrudApi<ShortLink>('/helpers/short-urls');
export const shortLinkClicksApi = new CrudApi<ShortLinkClick>(
  '/helpers/short-urls/clicks'
);
export const resolveShortLink = (hash: string) =>
  api.get<string>('/helpers/short-urls/resolve/' + hash).then(d => d.data);
