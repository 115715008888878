import React, { ReactNode } from 'react';
import { Card, OverlayTrigger } from 'react-bootstrap';
import FalconCardHeader from './FalconCardHeader';

export default ({
  title,
  body,
  children
}: {
  title?: ReactNode;
  body: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <OverlayTrigger
        trigger={['click']}
        rootClose
        placement="auto"
        overlay={
          <Card style={{ maxWidth: '600px', zIndex: 9999 }}>
            {title && (
              <FalconCardHeader
                title={<div>{title}</div>}
                className="d-flex justify-content-between"
              />
            )}
            <Card.Body>{body}</Card.Body>
          </Card>
        }
      >
        <div className="position-relative">{children}</div>
      </OverlayTrigger>
    </div>
  );
};
