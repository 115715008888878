import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import companyConfig from 'companyConfig';

const Logo = ({
  at = 'navbar-vertical',
  className = null,
  textClass = null,
  ...rest
}) => {
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        {/* <img className="me-2" src={logo} alt="Logo" width={width} /> */}
        <span className={classNames('font-sans-serif d-lg-none', textClass)}>
          <div className="mb-n3">flex</div>
          <div className="text-info fs--2 d-block">
            {companyConfig.companyName.toLowerCase()}
          </div>
        </span>
        <span
          className={classNames('font-sans-serif d-none d-lg-block', textClass)}
        >
          <span className="text-info">
            {companyConfig.companyName.toLowerCase()}
          </span>
          flex
        </span>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
