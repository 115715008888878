export default {
  supportEmail: 'support@flex.teamsearchmr.co.uk',
  companyName: 'Teamsearch',
  companyAddress: 'Floor 2, 1850 Mill, Shaw Lodge Mills, Halifax, HX3 9ET',
  companyLongLat: [53.71397312010506, -1.8551913240293416],
  companyAddressObj: {
    add1: 'Floor 2, 1850 Mill',
    add2: 'Shaw Lodge Mills',
    town: 'Halifax',
    county: 'West Yorkshire',
    postcode: 'HX3 9ET'
  },
  baseUrl: process.env.REACT_APP_PUBLIC_URL,
  apiUrl: process.env.REACT_APP_SERVER_URL
};
