import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, RowProps } from 'react-bootstrap';
import warningLight from 'assets/img/animated-icons/warning-light.json';

const Screenedout = ({
  title,
  description,
  button,
  animation,
  height = '40vh',
  ...rest
}: {
  title?: string;
  description?: string;
  button?: React.ReactNode;
  animation?: any;
  height?: number | string;
} & RowProps) => {
  return (
    <>
      <Row className="pb-4 px-4" {...rest}>
        <Col className="text-center">
          <div className="mx-auto" style={{ height }}>
            <Lottie
              animationData={animation || warningLight}
              loop={true}
              className="h-100"
            />
          </div>
          <h4
            className="mb-1"
            dangerouslySetInnerHTML={{ __html: title || 'Not eligible' }}
          />
          <div
            className="fs-0"
            dangerouslySetInnerHTML={{
              __html:
                description ||
                'Unfortunately on this occassion you do not fit the eligibility criteria. Thank you for your time. You may now close this page.'
            }}
          />
          {button && <div className="pt-4 text-center">{button}</div>}
        </Col>
      </Row>
    </>
  );
};

Screenedout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.node,
  animation: PropTypes.any
};

export default Screenedout;
