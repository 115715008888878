import Lottie from 'lottie-react';
import celebration from '../celebration.json';
import React from 'react';
export const Celebration = () => (
  <div className="wizard-lottie-wrapper">
    <div className="wizard-lottie mx-auto">
      <Lottie animationData={celebration} loop={true} />
    </div>
  </div>
);
