import React from 'react';
import { Button } from 'react-bootstrap';
import Divider from './Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export default ({
  children,
  label = (expanded: boolean) => `Show ${expanded ? 'Less' : 'More'}`
}: {
  children: React.ReactNode;
  label?: (expanded: boolean) => React.ReactNode;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Divider className="p-2">
        <Button variant="link" size="sm" onClick={() => setShow(!show)}>
          {label(show)}
          <motion.div
            className="d-inline-block ms-1"
            initial={{ rotate: 0 }}
            animate={{ rotate: show ? 180 : 0 }}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </motion.div>
        </Button>
      </Divider>
      {show && children}
    </>
  );
};
