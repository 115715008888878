import React from 'react';
import { format } from 'date-fns';
import SelectedApplicants from './widgets/SelectedApplicants';
import useApplicantGroups from './hooks/useApplicantGroups';
import PropTypes from 'prop-types';
import useCampaignApplicants from './hooks/useCampaignApplicants';
import ItemSelector from 'components/common/ItemSelector';
const ApplicantSelector = ({
  name,
  filter: select = d => d,
  registerProps,
  campaignId,
  ...rest
}) => {
  const groups = useApplicantGroups({ campaignId });
  const { data, isLoading } = useCampaignApplicants({
    select: d =>
      select(d).map(a => ({
        group: groups.find(g => g.filter(a.applicant))?.title,
        info: {
          Applied: format(
            new Date(a.response.submittedDate),
            'dd/MM/yyyy HH:mm'
          )
        },
        label:
          a.response.data.labelled.name.first +
          ' ' +
          a.response.data.labelled.name.second,
        value: a.applicant.id
      })),
    campaignId
  });
  return (
    <ItemSelector
      name={name}
      data={data}
      isLoading={isLoading}
      registerProps={registerProps}
      {...rest}
    >
      {value => <SelectedApplicants ids={value} />}
    </ItemSelector>
  );
};
ApplicantSelector.propTypes = {
  name: PropTypes.string,
  filter: PropTypes.func,
  registerProps: PropTypes.object,
  campaignId: PropTypes.number
};
export default ApplicantSelector;
