import React from 'react';
import ItemSelector, {
  ItemSelectorProps,
  SelectorItem
} from 'components/common/ItemSelector';
import useTrainingCourses from '../hooks/useTrainingCourses';
import { TrainingCourse } from 'apis/flex/hr';
import { getMediaStreamUrl } from 'helpers/utils';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import DomainItemIcon from 'components/common/DomainItemIcon';

const useCourseItems = ({
  filter,
  mutator = () => ({})
}: {
  filter?: (course: TrainingCourse) => boolean;
  mutator?: (course: TrainingCourse) => Partial<SelectorItem>;
}) => {
  return useTrainingCourses({
    useFilter: true,
    select: d =>
      d.filter(filter).map(course => ({
        label: course.name,
        value: course.id,
        info: {
          modules: (course.resources?.length || 0) + ' resources'
        },
        avatar:
          !!course.profileBackground &&
          getMediaStreamUrl(course.profileBackground[0]),
        ...mutator(course)
      }))
  });
};
export default ({
  name,
  filter = () => true,
  mutator = () => null,
  ...rest
}: Omit<ItemSelectorProps, 'data' | 'isLoading'> & {
  filter?: (course: TrainingCourse) => boolean;
  mutator?: (course: TrainingCourse) => Partial<ItemSelectorProps['data'][0]>;
}) => {
  const { data, isLoading } = useCourseItems({ filter, mutator });
  return (
    <ItemSelector
      name={name || 'courseId'}
      label="Training course"
      placeholder="Select a course"
      domain="training-course"
      data={data}
      isLoading={isLoading}
      {...rest}
    />
  );
};
export const SelectedTrainingCourses = ({
  ids,
  ...rest
}: SelectedDomainItemsProps) => {
  const { data } = useCourseItems({ filter: c => ids.some(i => c.id == i) });
  return (
    <DomainItemIcon
      data={data}
      domain="training-course"
      placeholder={'No courses selected'}
      {...rest}
    />
  );
};
