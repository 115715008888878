import companyConfig from 'companyConfig';
import Linkify from 'linkify-react';
import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

export const toast500 = (msg?: ReactNode) => {
  return toast.error(() => (
    <Linkify>
      {msg ||
        'Something went wrong! Please try again, or contact ' +
          companyConfig.supportEmail}
    </Linkify>
  ));
};
export const apiError = err => {
  Sentry.captureException(err);
  if (err.response?.status === 403) {
    return toast.warning(
      () => (
        <>
          Unauthorised!
          {err.response?.data?.requiredPermissions && (
            <div className="form-text">
              One of the following permissions is required:{' '}
              {err.response?.data?.requiredPermissions.join(', ')}
            </div>
          )}
        </>
      ),
      { icon: true }
    );
  }
  if (err.response?.status === 409) {
    return toast500(err.data?.message);
  }
  if (err.response?.status === 401) {
    // setItemToStore('client-access-token', null);
    return toast500(err.response?.data?.message);
  }
  return toast500();
};
export const apiPromise = (
  promise,
  messages = {
    pending: 'Working on it..',
    success: 'Done!',
    error: 'Something went wrong! Please try again'
  }
) => {
  return toast.promise(promise, messages, {
    // position: toast.POSITION.TOP_RIGHT,
    icon: true
  });
};
export const apiSuccess = (msg?: ReactNode) => {
  if (msg && typeof msg === 'string') {
    return toast.success(msg);
  }
  return toast.success('Done!');
};
export const apiCustomError = (msg?: ReactNode) => {
  return err => {
    console.error(err);
    return toast500(msg);
  };
};
