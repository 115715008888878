import Avatar from 'components/common/Avatar';
import React from 'react';
import blankUser from 'assets/img/team/avatar.png';
import { format } from 'date-fns';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
const ApplicantListItem = ({ data, compact }) => {
  return (
    <Flex
      xs={12}
      sm={6}
      xl={4}
      className={classNames(
        'align-items-center position-relative d-flex mb-2 text-start'
      )}
    >
      <Avatar
        src={blankUser}
        className={`status-offline`}
        mediaClass={'border border-3 rounded-circle border-200 bg-100'}
      />
      {!compact && (
        <motion.div
          className="overflow-hidden text-nowrap"
          variants={{ hover: { maxWidth: '100%', marginLeft: '1rem' } }}
          initial={{ maxWidth: 0, marginLeft: 0 }}
        >
          <h6 className="mb-0 fw-semi-bold">
            {/* <Link
              className="text-900 stretched-link"
              to={'/recruitment/applicants/' + data.id}
            > */}
            {data.name}
            {/* </Link> */}
          </h6>
          <p className="text-500 fs--2 mb-0">{data.email}</p>
          <p className="text-500 fs--2 mb-0">
            Applied: {format(new Date(data.submittedDate), 'dd/MM/yyyy HH:mm')}
          </p>
        </motion.div>
      )}
    </Flex>
  );
};
ApplicantListItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    submittedDate: PropTypes.any,
    id: PropTypes.any
  }),
  compact: PropTypes.bool
};
export default ApplicantListItem;
