import { Campaign, campaignsApi } from 'apis/flex/recruitment';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <T = Campaign[]>(props: UseCrudProps<Campaign, T> = {}) => {
  return useDefaultCrud<Campaign, T>(
    'RecruitmentCampaigns',
    campaignsApi,
    props
  );
};
