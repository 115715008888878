import React from 'react';
import { useState } from 'react';
import { extractTextFromChildren } from 'helpers/strings';
import { Button } from 'react-bootstrap';

export default ({ children, maxCharacters }) => {
  const [show, setShow] = useState(false);
  const text = extractTextFromChildren(children);
  const isTextLong = text.length > maxCharacters;
  const displayedText =
    show || !isTextLong ? (
      children
    ) : (
      <>
        {text.substring(0, maxCharacters)}...
        <Button
          variant="link"
          className="p-0 fs--1"
          onClick={() => setShow(!show)}
        >
          Read More
        </Button>
      </>
    );
  return (
    <>
      {show ? (
        <>
          {children}
          <Button
            variant="link"
            className="p-0 fs--1"
            onClick={() => setShow(!show)}
          >
            Show Less
          </Button>
        </>
      ) : (
        displayedText
      )}
    </>
  );
};
