import { customFormTagsApi, TagName } from 'apis/flex/customForms';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <T = TagName[]>({
  select,
  ...rest
}: { select?: (data: TagName[]) => T } & UseCrudProps<TagName, T> = {}) => {
  return useDefaultCrud('CustomFormTags', customFormTagsApi, {
    useFilter: true,
    select,
    staleTime: 1000 * 60 * 10,
    ...rest
  });
};
