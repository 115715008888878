import { getSqlCols } from 'apis/flex/adminTable';
import { DbColumnInfo } from './AdvanceTableProvider';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';

export default ({
  db,
  tables,
  ...rest
}: { db: string; tables: string[] } & Partial<
  Omit<QueryObserverOptions<DbColumnInfo[]>, 'queryKey' | 'queryFn'>
>) =>
  useQuery<DbColumnInfo[]>({
    queryKey: ['ColumnInfo', db, tables],
    queryFn: () => getSqlCols(tables, db).then(d => d.data),
    staleTime: Infinity,
    ...rest
  });
