import PropTypes from 'prop-types';
const Wrapper = ({ condition = true, wrapper, children }) =>
  condition
    ? wrapper(children)
    : typeof children === 'function'
    ? children()
    : children;
Wrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};
export default Wrapper;
