import React from 'react';
import PropTypes from 'prop-types';
import ItemSelector, { SelectorItem } from 'components/common/ItemSelector';
import UserIcon from 'components/app/users/widgets/UserIcon';
import { format } from 'date-fns';
import Flex from 'components/common/Flex';
import { Guard } from 'hooks/useGuard';
import { Button } from 'react-bootstrap';
import useContracts from './hooks/useContracts';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import DomainItemIcon from 'components/common/DomainItemIcon';
const useContractItems = ({ filter }) => {
  return useContracts<SelectorItem[]>({
    select: d =>
      d.filter(filter).map(d => ({
        info: {
          createdDate: format(new Date(d.createdDate), 'dd/MM/yyyy'),
          createdBy: (
            <UserIcon
              size="s"
              nameClass={'text-500'}
              showName
              hideJobTitle
              user={d.createdBy}
            />
          )
        },
        label: d.name,
        value: d.id,
        group: d.isActive ? 'Active' : 'Inactive'
      }))
  });
};
const ContractPicker = ({ filter = d => d, name, ...rest }) => {
  const { data, isLoading } = useContractItems({ filter });
  // const [newModal, setNewModal] = useState(false);
  const handleNewClick = () => {
    window.open('/hr/contracts/new', 'blank');
  };
  return (
    <>
      <ItemSelector
        domain="contract"
        isLoading={isLoading}
        data={data}
        label={
          <Flex alignItems={'baseline'}>
            <span>Contract</span>
            <Guard roles={['contract.create']}>
              <Button
                size="sm"
                onClick={handleNewClick}
                variant="link"
                className="p-0 ms-2"
              >
                Add new
              </Button>
            </Guard>
          </Flex>
        }
        name={name}
        {...rest}
      />
    </>
  );
};
export const SelectedContracts = ({
  ids,
  ...rest
}: SelectedDomainItemsProps) => {
  const { data } = useContractItems({ filter: c => ids.some(i => i == c.id) });
  return <DomainItemIcon data={data} domain="contract" {...rest} />;
};
SelectedContracts.propTypes = {
  ids: PropTypes.array
};
ContractPicker.propTypes = {
  filter: PropTypes.func,
  name: PropTypes.string,
  ids: PropTypes.array
};
export default ContractPicker;
