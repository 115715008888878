import { TrainingCourse, trainingApi } from 'apis/flex/hr';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <TSelected = TrainingCourse[]>(
  props: UseCrudProps<TrainingCourse, TSelected> = {}
) => {
  return useDefaultCrud<TrainingCourse, TSelected>(
    'TrainingCourses',
    trainingApi.courses,
    { ...props, staleTime: props.staleTime || 1000 * 60 * 10 }
  );
};
