/* eslint-disable react/prop-types */
import React, {
  HTMLProps,
  JSXElementConstructor,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  AccessorColumnDef,
  Cell,
  CellContext,
  ColumnDefBase,
  ColumnFiltersColumnDef,
  CoreColumn,
  DisplayColumnDef,
  HeaderContext,
  Row as RowType,
  Table,
  VisibilityColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import { Card, Dropdown, Form } from 'react-bootstrap';
import {
  QueryObserverOptions,
  useMutation,
  useQuery
} from '@tanstack/react-query';
import Error500 from 'components/errors/Error500';
import AdvanceTableHeader from './AdvanceTableHeader';
import AdvanceTableFooter from './AdvanceTableFooter';
import {
  camelToSentence,
  getCommonDataValues,
  getMediaStreamUrl
} from 'helpers/utils';
import CardDropdown from '../CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { format, formatDistance } from 'date-fns';
import Error403 from 'components/errors/Error403';
import { getSqlEditProps } from './sql';
import {
  getRuleFunc,
  getValueParser,
  stringComparison
} from 'helpers/validation/validate';
import { getMediaUrl } from 'apis/flex/helpers';
import { uniqueId } from 'lodash';
import TableTour from 'components/tours/TableTour';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FileId } from 'apis/types';
import { RoleDomain } from 'apis/flex/users';
import SelectedApplicants from 'components/app/recruitment/applicants/widgets/SelectedApplicants';
import { SelectedForms } from '../customForms/widgets/FormPicker';
import { SelectedTrainingCourses } from 'components/app/hr/training/widgets/TrainingCoursePicker';
import { SelectedContracts } from 'components/app/documents/contracts/ContractPicker';
import {
  DomainIconProps,
  SelectedDomainItemsProps,
  UsersIcon
} from 'components/app/users/widgets/selector/UserSelector';
import CustomTooltip from '../Tooltip';
import CustomPopover from '../CustomPopover';
import AnalysisScore from '../customForms/widgets/AnalysisScore';
import SoftBadge from '../SoftBadge';
import { HardwareSummary } from 'components/wizard/inputs/WizardHardware';
import Wrapper from 'helpers/Wrapper';
import { ModalConfirm } from '../Modals';
import { SelectedEmployees } from 'components/app/hr/staff/widgets/EmployeeSelector';
import { SelectedDepartment } from 'components/app/hr/departments/SelectedDepartment';
import useUrlParamFilters from './useUrlParamFilters';
import useColumnInfo from './useColumnInfo';
import Flex from '../Flex';
import CircleProgress from '../CircleProgress';
import { getRuleInputType } from 'helpers/validation/rules';

export type ProcessedColumnDef<T = any, V = unknown> = AccessorColumnDef<T, V> &
  Omit<
    ColumnDefBase<T, V> &
      Omit<DisplayColumnDef<T, V>, 'header'> &
      // GroupColumnDef<any, V> &
      ColumnFiltersColumnDef<T>,
    'meta'
  > & {
    filter?: boolean | ((props: any) => ReactNode);
    isSelect?: boolean;
    isAction?: boolean;
    isChild?: boolean;
    editable?: boolean;
    header: string;
    visible?: boolean;
    download?: boolean | ((value: any) => string);
    headerDownload?: string;
    cellProps?: HTMLProps<HTMLTableCellElement>;
    wrap?: number;
    headerProps?: HTMLProps<HTMLTableCellElement>;
  };
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue = unknown>
    extends CustomFieldProps<TData> {
    editable: boolean;
    download: (val: TValue) => string;
    inputType: string;
    registerProps?: {
      required?: boolean;
    };
    formControlProps?: {
      maxLength?: number;
      step?: string;
    };
  }
}
export type ProcessedCell<T = any, V = unknown> = Cell<T, V> & {
  column: CoreColumn<T, V> & {
    columnDef: ProcessedColumnDef<T, V>;
  };
};
export type ProcessedColumn<T = any, V = unknown> = Omit<
  CoreColumn<T, V>,
  'columnDef'
> & {
  columnDef: ProcessedColumnDef<T, V>;
};
export type ProcessedRow<T = any> = RowType<T> & {
  columns: (CoreColumn<T, unknown> & {
    columnDef: ProcessedColumnDef<T, unknown>;
  })[];
};
const domainItems = {
  applicant: SelectedApplicants,
  form: SelectedForms,
  user: UsersIcon,
  'training-course': SelectedTrainingCourses,
  contract: SelectedContracts,
  employee: SelectedEmployees,
  department: SelectedDepartment
};
export type UseAdvanceTable<TData extends { id?: number } = any> = Omit<
  Table<any>,
  'getAllFlatColumns'
> & {
  animateDirection: 'forward' | 'backward';
  id: string;
  dataId: string;
  isLoading: boolean;
  title: string;
  getOnRowClick: () => RowClickHandler<TData>;
  getOnNewClick: () => () => void;
  getTableActions: () => Action[];
  RowContextMenu: (any: any) => JSX.Element;
  pagination: {
    pageSize: number;
    pageIndex: number;
    isForward: boolean;
  };
  sorting: {
    id: string;
    desc: boolean;
  }[];
  filters: {
    id: string;
    value: FilterValue;
  }[];
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        value: FilterValue;
      }[]
    >
  >;
  getAllFlatColumns: () => ProcessedColumn[];
  editing: Partial<RowType<TData>>[];
  setEditing: React.Dispatch<React.SetStateAction<Partial<RowType<TData>>[]>>;
};
const TableContext = createContext<Partial<UseAdvanceTable>>({});
export const getTableContext = <TData extends { id?: number } = any>() =>
  TableContext as React.Context<Partial<UseAdvanceTable<TData>>>;
export const useAdvanceTable = <TData extends { id?: number } = any>() =>
  useContext(getTableContext<TData>());
export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, className, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef<any>();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        className={classNames('form-check fs-0 mb-0', className)}
      >
        <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);
export const ActionMenu = <TData extends { id?: number } = any>({
  onEditClick,
  actions,
  row,
  dataAccessor
}: ActionMenuProps<TData>) => {
  return (
    <>
      {onEditClick && !actions && (
        <Dropdown.Item onClick={() => onEditClick(dataAccessor(row))}>
          <FontAwesomeIcon icon={faEdit} /> <span className="ms-1">Edit</span>
        </Dropdown.Item>
      )}
      {actions &&
        row &&
        actions
          .filter(
            a =>
              a.name &&
              (a.show === undefined ||
                a.show === true ||
                (typeof a.show === 'function' && a.show(dataAccessor(row))))
          )
          .map((action, ai) => (
            <Wrapper
              condition={!!action.confirm}
              key={action.name}
              wrapper={c => (
                <ModalConfirm
                  body={typeof action.confirm === 'string' && action.confirm}
                  onConfirm={() => action.onClick(dataAccessor(row))}
                >
                  {c}
                </ModalConfirm>
              )}
            >
              <Dropdown.Item
                key={ai}
                onClick={() => {
                  !action.confirm && action.onClick(dataAccessor(row));
                }}
                className={`text-${action.variant}`}
                disabled={
                  typeof action.disabled === 'function'
                    ? action.disabled(row)
                    : action.disabled
                }
              >
                {action.icon && (
                  <FontAwesomeIcon icon={action.icon} size="sm" />
                )}{' '}
                <div className="ms-1 d-inline">{action.name}</div>
                {action.modal && action.modal()}
              </Dropdown.Item>
            </Wrapper>
          ))}
    </>
  );
};
type ActionMenuProps<T> = {
  onEditClick?: RowClickHandler<T>;
  actions: Action[];
  row: RowType<any>;
  dataAccessor: (data: any) => any;
};
ActionMenu.propTypes = {
  row: PropTypes.object,
  onEditClick: PropTypes.func,
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  dataAccessor: PropTypes.func
};
export const ActionCell = <TData extends { id?: number } = any>({
  row,
  actions,
  onEditClick,
  dataAccessor
}: ActionMenuProps<TData>) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <CardDropdown drop="start">
        <ActionMenu {...{ row, actions, onEditClick, dataAccessor }} />
      </CardDropdown>
    </div>
  );
};
ActionCell.propTypes = ActionMenu.propTypes;

const filterParsers = {
  name: (v: object) => (v ? Object.values(v).join(' ') : ''),
  postalAddress: (v: object) => (v ? Object.values(v).join(' ') : ''),
  shifts: (v: object) => (v ? Object.keys(v).join(' ') : '')
};
type FilterParserTypes = keyof typeof filterParsers;
const getFilterParser = (type: FilterParserTypes, filterType: string) => {
  return filterParsers[type] || getValueParser(type, filterType);
};

const FileDownloader = ({ files }: { files: FileId[] }) => {
  const { mutate: download } = useMutation<string, Error, FileId>({
    mutationFn: (fileId: FileId) => getMediaUrl(fileId),
    onSuccess: url => {
      window.open(url, 'blank');
    }
  });
  const handleDownload = (file: FileId) => {
    download(file);
  };
  return (
    files && (
      <div className={'d-flex gap-2'}>
        {files.map((file, i) => (
          <div
            key={i}
            className="link-info"
            onClick={e => {
              e.stopPropagation();
              handleDownload(file);
            }}
          >
            File #{i + 1}
          </div>
        ))}
      </div>
    )
  );
};
export function sqlValueFormatter(
  type: string,
  options?: { label: string; value: string }[]
) {
  if (options?.length > 0) {
    return (
      v:
        | string
        | { value: string; label: string }
        | string[]
        | { value: string; label: string }[]
    ) => {
      return options !== undefined && Array.isArray(v)
        ? options
            .filter(o => v.some(vv => vv == o.value ?? o))
            ?.map(o => o.label)
        : options.find(o => stringComparison(v, o.value ?? o))?.label;
    };
  }
  switch (type) {
    case 'score':
      return v => {
        return (
          v && (
            <div className="">
              <CustomPopover
                title={'Analysis Score'}
                body={<AnalysisScore analysis={v} />}
              >
                <CircleProgress
                  now={v.score}
                  color={
                    v.score > 70
                      ? 'success'
                      : v.score > 50
                      ? 'warning'
                      : 'danger'
                  }
                />
              </CustomPopover>
            </div>
          )
        );
      };
    case 'file':
    case 'video':
    case 'audio':
      return (v: FileId[]) => v && <FileDownloader files={v} />;
    case 'range':
      return (v: number[]) => v && v[0] + ' - ' + v[2];
    case 'daterange':
      return (v: [Date, Date]) =>
        v &&
        format(new Date(v[0]), 'dd/MM/yyyy') +
          ' - ' +
          format(new Date(v[1]), 'dd/MM/yyyy');
    case 'date':
      return (v: Date) => {
        return v ? format(new Date(v), 'dd/MM/yyyy') : '';
      };
    case 'datetime':
      return (v: Date) =>
        v
          ? new Date().valueOf() - new Date(v).valueOf() < 24 * 60 * 60 * 1000
            ? formatDistance(new Date(v), new Date(), { addSuffix: true })
            : format(new Date(v), 'dd/MM/yyyy HH:mm:ss')
          : '';
    case 'bit':
    case 'switch':
    case 'checkbox':
    case 'boolean':
      return (v: boolean) => (
        <FontAwesomeIcon className="mx-1" icon={v ? faCheck : faTimes} />
      );
    case 'shifts':
      return (v: { [key: string]: string[] }) => {
        const shiftDays = v
          ? Object.keys(v).filter(k => v[k].filter(s => !!s).length > 0)
          : [];
        return (
          v && (
            <Flex wrap="wrap">
              {shiftDays.map(k => (
                <CustomTooltip
                  key={k}
                  content={
                    <>
                      <div>{v[k]?.filter(s => !!s).join(', ')}</div>
                    </>
                  }
                >
                  <div>
                    <SoftBadge bg="success" pill>
                      {k}
                      <SoftBadge className="ms-1">
                        {v[k].filter(s => !!s).length}
                      </SoftBadge>
                    </SoftBadge>
                  </div>
                </CustomTooltip>
              ))}
            </Flex>
          )
        );
      };
    case 'name':
    case 'postalAddress':
      return (v: { [key: string]: string }) => v && Object.values(v).join(', ');
    case 'transcribe':
      return v =>
        v && (
          <CustomTooltip content={v.text}>
            <div>{Math.round(v.score)}</div>
          </CustomTooltip>
        );
    case 'hardware':
      return v =>
        v && (
          <HardwareSummary
            hardware={v}
            className="gap-3"
            itemProps={{ size: '2x' }}
          />
        );
    default:
      return (v: any) => v;
  }
  // const getFormatter = () => {
  // };
  // const formatter = getFormatter();
  // return v => {
  //   const val = formatter(v);
  //   if (isReactRenderable(val)) {
  //     return val;
  //   }
  //   return '';
  // };
}
export function downloadValueFormatter(col: DbColumnInfo) {
  switch (col.type) {
    case 'file':
    case 'audio':
    case 'video':
      return (v: FileId[]) => v?.map(vv => getMediaStreamUrl(vv)).join(', ');
    case 'bit':
    case 'switch':
    case 'checkbox':
    case 'boolean':
      return v => (v ? 'TRUE' : 'FALSE');
    case 'transcribe':
      return v => v.score + ' | ' + v.text;
    default:
      return sqlValueFormatter(col.type, col.options);
  }
}
type CustomFieldProps<
  T extends { id?: number } = any,
  TField extends (keyof T & string) | string = (keyof T & string) | string
> = {
  edit?: boolean;
  visible?: boolean;
  multiple?: boolean;
  type?:
    | 'text'
    | 'textarea'
    | 'boolean'
    | 'number'
    | 'date'
    | 'datetime'
    | 'time'
    | 'daterange'
    | 'file'
    | 'video'
    | 'audio'
    | 'range'
    | 'icon'
    | 'currency'
    | 'currencyrange'
    | 'shifts'
    | 'name'
    | 'postalAddress'
    | 'switch'
    | 'checkbox'
    | 'rating'
    | 'bit'
    | string;
  header?: string;
  editorProps?: any;
  bulkEditProps?: {
    updateFn: () => Promise<any>;
  };
  filterProps?: any;
  filter?: boolean | ((props: any) => ReactNode);
  optionsQuery?: QueryObserverOptions<
    any,
    Error,
    { label: string; value: any }[]
  >;
  options?: {
    label: string;
    value: any;
  }[];
  sqlField?: string;
  sqlTable?: string;
  formatter?: <TTField extends (keyof T & string) | string = TField>(
    valuefn: () => TTField extends keyof T ? T[TTField] : any,
    rowData: T,
    formatter: (v: TTField extends keyof T ? T[TTField] : any) => any,
    sqlColumn: DbColumnInfo
  ) => any;
  className?: string;
  headerProps?: HTMLProps<HTMLTableCellElement>;
  cellProps?: HTMLProps<HTMLTableCellElement>;
  domain?: RoleDomain;
};
export type ColumnObject<
  T extends { id?: number } = any,
  TField extends (keyof T & string) | string = (keyof T & string) | string,
  TAccessed = TField extends keyof T ? T[TField] : any
> = VisibilityColumnDef &
  DisplayColumnDef<T> & {
    accessorFn?: (data: T) => TAccessed;
    id: TField;
  } & CustomFieldProps<T, TField>;
export type Column<T extends { id?: number } = any> =
  | (keyof T & string)
  | ColumnObject<T>;
export type DbColumnInfo = {
  TABLE_NAME: string;
  COLUMN_NAME: string;
  DATA_TYPE: string;
  type?: string;
  options?: {
    label: string;
    value: any;
  }[];
  IS_NULLABLE: boolean;
  MAXIMUM_LENGTH: number;
};
export type Action<T = any> = {
  name: string;
  variant?: string;
  modal?: () => JSX.Element;
  onClick: (row: RowType<T>) => void;
  disabled?: boolean | ((row: T) => boolean);
  show?: boolean | ((row: T) => boolean);
  icon: IconProp;
  confirm?: string | boolean;
};
export type FilterValue = {
  value: any;
  type: string;
  active: boolean;
};
type BulkAction<T> = {
  name: string;
  icon: IconProp;
  actionFn: (data: Partial<RowType<T>>[]) => Promise<any>;
};
export type FilterDef<T> = {
  id: keyof T & string;
  value: FilterValue;
};
export type RowClickHandler<T> = (row?: RowType<T>) => void;
export type AdvanceTableProviderProps<
  T extends { id?: number } = any,
  TAccessed extends T = T
> = {
  initialSort?: {
    id: keyof TAccessed & string;
    desc: boolean;
  }[];
  initialFilters?: FilterDef<TAccessed>[];
  children?: // | ReactNodeArray
  | ReactNode
    | JSX.Element
    | ((props: {
        rows: RowType<TAccessed>[];
        isLoading?: boolean;
      }) => ReactNode);
  actions?: Action<TAccessed>[];
  title?: string;
  sqlTables?: string[];
  sqlDb?: string;
  onRowClick?: RowClickHandler<TAccessed>;
  onNewClick?: () => void;
  onFiltersChanged?: (v: FilterDef<TAccessed>[]) => void;
  bulkActions?: BulkAction<TAccessed>[];
  selection?: boolean;
  updateFn?: (
    rows: Partial<RowType<TAccessed>>[],
    v: Partial<T>
  ) => Promise<any>;
  editable?: (keyof TAccessed & string)[];
  notEditable?: (keyof TAccessed & string)[];
  isLoading?: boolean;
  error?: Error | null;
  onDataLoaded?: (data: T[]) => T[];
  dataAccessor?: (d: Partial<RowType<T>>) => Partial<RowType<TAccessed>>;
  autoFocus?: boolean;
  tableActions?: Action<TAccessed>[];
  data?: T[];
  columns: Column<TAccessed>[];
  dataQuery?: QueryObserverOptions<T[], any>;
  perPage?: number;
  footer?: boolean;
  header?: boolean;
  tabs?: any;
  onEditClick?: (
    v: Partial<RowType<TAccessed>>[],
    data: Partial<TAccessed>
  ) => void;
  isNew?: (row: RowType<TAccessed>) => boolean;
  searchParamsAsFilters?: boolean;
  animateDirection?: 'forward' | 'backward';
  /**
   * This can be used to decide when to re-render the whole table. Change it to force a re-render
   */
  id?: string;
};
const AdvanceTableProvider = <
  TData extends { id?: number } = any,
  TAccessed extends TData = TData
>({
  initialSort = [],
  initialFilters = [],
  children,
  columns,
  data,
  dataQuery,
  perPage = 10,
  footer = true,
  header = true,
  onEditClick,
  actions,
  title = '',
  sqlTables = [],
  sqlDb,
  onRowClick,
  onNewClick,
  bulkActions,
  selection = !!bulkActions,
  editable,
  notEditable,
  isLoading: tableLoading,
  error: tableError,
  onDataLoaded = d => d,
  dataAccessor = row => row as any,
  autoFocus,
  tableActions = [],
  isNew,
  onFiltersChanged,
  searchParamsAsFilters = false,
  animateDirection,
  id: extId = null
}: AdvanceTableProviderProps<TData, TAccessed>) => {
  const dataQuerySetup = useMemo(() => {
    const query = dataQuery || { enabled: true, select: d => d };
    query.enabled = !!dataQuery;
    const select =
      dataQuery?.select ||
      function (v) {
        return v;
      };
    query.select = d => {
      // console.log('raw', d);
      const selected = select(d);
      // console.log('loaded', loaded);
      return selected;
    };
    query.staleTime = dataQuery?.staleTime || Infinity;
    return query;
  }, [dataQuery]);
  const manualData = useMemo(() => {
    // console.log('manualData', data || [], onDataLoaded(data || []));
    return onDataLoaded(data || []);
  }, [data, onDataLoaded]);
  const {
    data: fetchedData,
    error: dataError,
    isLoading: dataLoading
  } = useQuery(dataQuerySetup);
  const tableData = useMemo(
    () => ({
      id: uniqueId('tableData-'),
      data: data ? manualData : fetchedData || []
    }),
    [manualData, data, fetchedData]
  );
  // console.log('tableData', tableData, queryKey);
  const colQueryEnabled = useMemo(
    () => sqlTables.length > 0 && !!sqlDb,
    [sqlTables, sqlDb]
  );
  const { data: columnInfoData = [] } = useColumnInfo({
    db: sqlDb,
    tables: sqlTables,
    enabled: colQueryEnabled
  });
  const [editing, setEditingState] = useState<Partial<RowType<TAccessed>>[]>();
  const setEditing = (rows: Partial<RowType<TAccessed>>[]) => {
    // const accessed = rows.map(r => dataAccessor(r));
    if (onEditClick) {
      onEditClick(rows, getCommonDataValues(rows.map(r => r.original)));
    } else {
      setEditingState(rows);
    }
  };
  const canEdit = editable || notEditable || onEditClick;
  const RowContextMenu =
    actions === undefined && !canEdit
      ? null
      : ({ row }: { row: RowType<TData> }) => {
          if (!row) return null;
          return (
            <ActionMenu
              {...{
                row,
                actions,
                onEditClick:
                  canEdit &&
                  function () {
                    setEditing([dataAccessor(row)]);
                  },
                dataAccessor
              }}
            />
          );
        };
  // console.log('columnInfoData', columnInfoData);
  const defs: ProcessedColumnDef[] = useMemo(() => {
    // console.log('running defs', columnInfoData);
    const columnsToRender = (
      columns ||
      Object.keys(tableData?.data.length ? tableData.data[0] : {}).map(k => ({
        id: k as keyof TData & string
      }))
    ).filter(c => c);
    let arr: any[] = columnsToRender.map(column => {
      let col: Partial<ColumnObject<TData>> = {};
      if (typeof column === 'string') {
        col.id = column;
      } else {
        col = column as ColumnObject<TData, keyof TData & string>;
      }
      const sqlColumnPartial: Partial<DbColumnInfo> = {
        ...(columnInfoData.find(
          sql =>
            (!col.sqlTable || sql.TABLE_NAME.toLowerCase() === col.sqlTable) &&
            sql.COLUMN_NAME.toLowerCase() ===
              (col.sqlField || col.id).toLowerCase()
        ) || {})
      };
      if (col.type) {
        sqlColumnPartial.DATA_TYPE = col.type;
      }
      if (col.options) {
        sqlColumnPartial.options = col.options;
      }
      sqlColumnPartial.type = sqlColumnPartial.DATA_TYPE;
      const sqlColumn = sqlColumnPartial as DbColumnInfo;
      const sqlProps = getSqlEditProps(sqlColumn);
      // console.log('sqlProps', sqlProps, sqlColumn);
      let colFormatter: any = ({ getValue }: CellContext<any, any>) => {
        return sqlValueFormatter(sqlColumn.type, sqlColumn.options)(getValue());
      };

      if (col.domain) {
        const Icon: JSXElementConstructor<
          SelectedDomainItemsProps & DomainIconProps
        > = domainItems[col.domain];
        colFormatter = ({ getValue }: CellContext<any, any>) => {
          const v = getValue();
          const arr = !v || Array.isArray(v) ? v : [v];
          return (
            <div style={{ width: 200 }} className="overflow-hidden">
              <Icon className="my-0" ids={arr} size={'sm'} />
            </div>
          );
        };
      }
      if (col.formatter) {
        colFormatter = ({ getValue, row }: CellContext<any, any>) => {
          return col.formatter(
            () =>
              sqlValueFormatter(sqlColumn.type, sqlColumn.options)(getValue()),
            row.original,
            getValue,
            sqlColumn
          );
        };
      }
      const isEditable = editable
        ? !!editable.find(e => col.id == e)
        : notEditable
        ? !notEditable.find(e => col.id == e)
        : false;
      const downloadFormatter = (v: any) =>
        downloadValueFormatter(sqlColumn)(v);
      const def: ProcessedColumnDef = {
        header: camelToSentence(col.id),
        filterFn: (row: RowType<any>, id: string, filterValue: FilterValue) => {
          // const colId = col.field;
          const { value, type, active } = filterValue;
          if (!active) return true;
          const filterFunc = getRuleFunc(type, sqlProps.type);
          const result = filterFunc(
            getFilterParser(sqlProps.type, type)(row.getValue(col.id)),
            getValueParser(getRuleInputType(sqlProps.type, type), type)(value)
          );
          return result;
        },
        ...sqlProps,
        cell: colFormatter,
        editable: isEditable,
        download: downloadFormatter,
        ...col,
        accessorFn: (d: TData) => {
          if (col.accessorFn) {
            return col.accessorFn(dataAccessor({ original: d })?.original);
          }
          if (col.id in d) {
            return d[col.id as keyof TData];
          }
          return null;
        },
        accessorKey: col.id,
        id: col.id,
        meta: {
          ...sqlProps,
          editable: isEditable,
          download: downloadFormatter,
          ...col,
          inputType: col.options ? 'select' : col.type || sqlProps.inputType
        }
      };
      return def;
    });
    if (sqlTables) {
      arr = arr.concat(
        columnInfoData
          .filter(
            c =>
              !arr.some(
                d => d.id.toLowerCase() === c.COLUMN_NAME.toLowerCase()
              ) &&
              tableData?.data.some((d: any) => d[c.COLUMN_NAME] !== undefined)
          )
          .map(sqlCol => {
            const colId = sqlCol.COLUMN_NAME;
            // console.log('appending SQL column', colId);
            const isEditable = editable
              ? editable.find(e => colId == e)
              : notEditable
              ? !notEditable.find(e => colId == e)
              : false;
            const sqlProps = getSqlEditProps(sqlCol);
            return {
              id: colId,
              visible: false,
              header: camelToSentence(colId),
              filterFn: (
                row: RowType<any>,
                id: number,
                filterValue: FilterValue
              ) => {
                const { value, type, active } = filterValue;
                if (!active) return true;
                const filterFunc = getRuleFunc(type, sqlProps.type);
                return filterFunc(row.original[colId], value);
              },
              ...sqlProps,
              editable: isEditable,
              meta: {
                ...sqlProps,
                editable: isEditable,
                inputType: sqlProps.inputType
              }
            };
          })
      );
    }
    if (actions || canEdit) {
      arr.push({
        accessorKey: 'Action',
        id: 'Action',
        size: 50,
        isAction: true,
        enablePinning: false,
        enableSorting: false,
        download: false,
        enableColumnFilter: false,
        header: '',
        headerProps: {
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end'
        },
        cell: ({ row }: CellContext<any, any>) => (
          <div onClick={e => e.stopPropagation()}>
            <CardDropdown drop="start">
              <RowContextMenu row={row} />
            </CardDropdown>
          </div>
        )
      });
    }
    return arr;
  }, [columns, actions, columnInfoData, tableData]);
  // console.log('table data', tableData, columns, defs);

  const columnVisibility = useMemo(
    () =>
      defs.reduce((a, b) => {
        a[b.id] = b.visible === false ? false : true;
        return a;
      }, {}),
    [defs]
  );
  const [sorting, setSorting] = useState(initialSort);
  const paramFilters = useUrlParamFilters<TData>();
  const [filters, setFilters] = useState(initialFilters);
  const [urlFiltersSet, setUrlFiltersSet] = useState(false);
  useEffect(() => {
    if (paramFilters?.length > 0 && !urlFiltersSet && searchParamsAsFilters) {
      setFilters([...initialFilters, ...paramFilters]);
      setUrlFiltersSet(true);
    }
  }, [paramFilters]);
  const [pagination, setPaginationState] = useState({
    pageSize: perPage,
    pageIndex: 0,
    isForward: null
  });
  const setPagination = (newPagination: {
    pageSize: number;
    pageIndex: number;
  }) => {
    setPaginationState({
      ...newPagination,
      isForward: newPagination.pageIndex > pagination.pageIndex
    });
  };
  const columnsWithNewFlag: (Omit<ProcessedColumnDef, 'header'> & {
    header: any;
  })[] = useMemo(() => {
    return isNew
      ? [
          {
            id: 'isNew',
            enableColumnFilter: false,
            download: false,
            // headerProps: { style: { width: '100px' } },
            size: 50,
            enablePinning: false,
            cellProps: { className: 'p-0' },
            header: () => <></>,
            cell: ({ row }: CellContext<any, any>) =>
              isNew(row) && <SoftBadge bg={'success'}>New</SoftBadge>
          } as Omit<ProcessedColumnDef, 'header'> & { header: any }
        ].concat(defs)
      : defs;
  }, [defs]);
  const columnsWithSelect: (Omit<ProcessedColumnDef, 'header'> & {
    header: any;
  })[] = useMemo(() => {
    return selection
      ? [
          {
            id: 'select',
            isSelect: true,
            enablePinning: false,
            enableSorting: false,
            enableColumnFilter: false,
            download: false,
            // headerProps: { style: { width: '100px' } },
            size: 50,
            header: ({ table }: HeaderContext<any, any>) => (
              <div className="px-1 tour-select-checkbox">
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                  }}
                />
              </div>
            ),
            cell: ({ row }: CellContext<any, any>) => (
              <div
                className="px-1"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <IndeterminateCheckbox
                  {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    // indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler()
                  }}
                />
              </div>
            )
          } as Omit<ProcessedColumnDef, 'header'> & { header: any }
        ].concat(columnsWithNewFlag)
      : columnsWithNewFlag;
  }, [columnsWithNewFlag]);
  const {
    getHeaderGroups,
    getLeftHeaderGroups,
    getRightHeaderGroups,
    getRowModel,
    setGlobalFilter,
    getFilteredSelectedRowModel,
    getIsSomeRowsSelected,
    toggleAllRowsSelected,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
    getPrePaginationRowModel,
    getAllFlatColumns,
    getIsAllRowsSelected,
    resetRowSelection,
    getSelectedRowModel,
    getColumn
  } = useReactTable({
    columns: columnsWithSelect,
    data: tableData?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enablePinning: true,
    state: {
      sorting,
      columnFilters: filters,
      pagination: pagination,
      columnVisibility
    },
    initialState: {
      columnPinning: { right: ['Action'], left: ['select'] }
    },
    enableRowSelection: selection,
    enableMultiRowSelection: selection,
    onSortingChange: setSorting
    // debugAll: true
  });
  const getOnRowClick = useCallback((onRowClick, dataAccessor) => {
    return (
      onRowClick ||
      (selection
        ? function (row: RowType<any>) {
            row.toggleSelected();
          }
        : canEdit
        ? function (row: RowType<any>) {
            setEditing([dataAccessor(row)]);
          }
        : function () {})
    );
  }, []);
  const accessedBulkActions = (bulkActions || []).map(action => ({
    ...action,
    actionFn: (rows: Partial<RowType<any>>[]) =>
      action.actionFn(rows.map(r => dataAccessor(r)))
  }));
  const error = useMemo(() => {
    return tableError || dataError;
  }, [tableError, dataError]);
  const id = useMemo(() => uniqueId('table-' + (extId || '')), [extId]);
  const TableContext = getTableContext<TAccessed>();
  return error ? (
    error.status === 403 ? (
      <Error403 button={false} error={error} />
    ) : (
      <Error500 error={error} />
    )
  ) : (
    <TableContext.Provider
      value={{
        animateDirection,
        id,
        dataId: tableData?.id,
        getHeaderGroups,
        getLeftHeaderGroups,
        getRightHeaderGroups,
        getRowModel,
        setGlobalFilter,
        getFilteredSelectedRowModel,
        getPrePaginationRowModel,
        getIsSomeRowsSelected,
        toggleAllRowsSelected,
        setPagination,
        pagination,
        getCanPreviousPage,
        getCanNextPage,
        nextPage,
        previousPage,
        getAllFlatColumns: getAllFlatColumns as () => ProcessedColumn<TData>[],
        sorting,
        setSorting,
        setFilters: (vals: FilterDef<TData>[]) => {
          setFilters(vals);
          onFiltersChanged?.(vals);
        },
        filters,
        isLoading: (dataLoading && !!dataQuery) || tableLoading,
        title,
        getOnRowClick: () => getOnRowClick(onRowClick, dataAccessor),
        getOnNewClick: () => onNewClick,
        getIsAllRowsSelected,
        getSelectedRowModel,
        resetRowSelection,
        getTableActions: () => tableActions,
        RowContextMenu,
        editing,
        setEditing,
        getColumn
      }}
    >
      {tableData && (
        <Card className="tour-advance-table">
          {header && (
            <AdvanceTableHeader
              setEditing={setEditing}
              bulkActions={accessedBulkActions}
              edit={editable || notEditable}
              autoFocus={autoFocus}
            />
          )}
          {typeof children === 'function'
            ? children({
                rows: getRowModel().rows,
                isLoading: (dataLoading && !!dataQuery) || tableLoading
              })
            : children}
          {footer && <AdvanceTableFooter viewAllBtn={null} className={null} />}
        </Card>
      )}
      <TableTour />
    </TableContext.Provider>
  );
};
export default AdvanceTableProvider;
