import {
  MutateOptions,
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { apiError, apiSuccess } from 'apis/errors';
import CrudApi from 'apis/flex/CrudApi';
import { useMemo } from 'react';
import useCrudConfig from './useCrudConfig';
export type CrudFilters<TData> = Partial<Record<string & keyof TData, any>>;
type SettableUseQueryOptions = Omit<
  QueryObserverOptions,
  | 'select'
  | 'enabled'
  | 'queryFn'
  | 'queryKey'
  | 'staleTime'
  | 'initialData'
  | 'noReturnOnChange'
  | 'placeholderData'
  | 'structuralSharing'
>;

export type CountBy<TData = any> = string & keyof TData;
type CrudReturn<TData, TBy extends CountBy<TData>[]> = {
  count: number;
} & {
  [K in TBy[number]]: K extends keyof TData ? TData[K] : never;
};
export type CrudCounts<TData, TBy extends CountBy<TData>[]> = CrudReturn<
  TData,
  TBy
>[];
export type UseCrudProps<TData, TSelected> = SettableUseQueryOptions & {
  id?: number | string;
  filters?: CrudFilters<TData>;
  select?: (data: TData[]) => TSelected;
  enabled?: boolean;
  staleTime?: number;
  useFilter?: boolean;
  afterSave?: (data: TData) => void;
  includeDeleted?: boolean;
  getPublic?: boolean;
  noReturnOnChange?: boolean;
  count?: boolean;
  countBy?: CountBy<TData>[];
  allowedOnly?: boolean;
};
export type DoneCallback<TData = any> = (data?: TData) => void;
export type ErrorCallback = (error?: Error) => void;
const useDefaultCrud = <
  TData extends { id?: number; deletedBy?: number; deletedDate?: Date } = any,
  TSelected = TData[],
  TBy extends CountBy<TData>[] = CountBy<TData>[]
>(
  queryKey: string,
  api: CrudApi<any, any, TData>,
  {
    countBy,
    ...props
  }: Omit<UseCrudProps<TData, TSelected>, 'countBy'> & { countBy?: TBy } = {}
) => {
  const crudConfig = useCrudConfig() || {};
  const {
    id,
    filters,
    select,
    enabled = true,
    staleTime,
    useFilter = false,
    afterSave,
    includeDeleted,
    getPublic = false,
    noReturnOnChange,
    count,
    allowedOnly,
    ...rest
  }: UseCrudProps<any, any> = { ...props, ...crudConfig };
  const enableMemo = useMemo(
    () => !!((!!id || !!filters || useFilter) && enabled && !count && !countBy),
    [id, filters, useFilter, enabled]
  );
  const _queryKey = [
    queryKey,
    id,
    filters,
    useFilter,
    getPublic,
    staleTime,
    includeDeleted,
    count,
    countBy,
    allowedOnly
  ];
  const query = useQuery<TData[], Error, TSelected>({
    queryKey: _queryKey,
    queryFn: () => {
      if (useFilter) {
        if (getPublic) {
          return api.getPublic(filters, includeDeleted);
        }
        return api.get(filters, includeDeleted);
      } else if (id) {
        if (getPublic) {
          return api.getPublic({ id }, true);
        }
        return api.getOne(id);
      } else {
        return Promise.resolve([]);
      }
    },
    select,
    enabled: enableMemo,
    staleTime: enabled && (staleTime || 1000 * 10),
    ...rest
  });
  const countEnableMemo = useMemo(
    () => !!((!!filters || useFilter) && enabled && (count || countBy)),
    [id, filters, useFilter, enabled, count, countBy]
  );
  const { data: counts, isLoading: isCountsLoading } = useQuery<
    TData[],
    Error,
    CrudCounts<TData, TBy>
  >({
    queryKey: _queryKey,
    queryFn: () => {
      if (useFilter) {
        return api
          .counts<TBy>(countBy, filters, allowedOnly, includeDeleted)
          .then(d => d.map(c => ({ ...c, count: Number(c.count) })));
      } else {
        return Promise.resolve(null);
      }
    },
    enabled: countEnableMemo,
    staleTime: enabled && (staleTime || 1000 * 10),
    ...rest
  });
  // useEffect(() => {
  //   if (query.error) {
  //     apiError(query.error);
  //   }
  // }, [query.error]);
  const queryClient = useQueryClient();
  const {
    mutate: update,
    isLoading: isUpdating,
    error: updateError
  } = useMutation<
    TData,
    Error,
    {
      id: number;
      data: Partial<Omit<TData, 'id'>>;
      isSelf?: boolean;
      noReturn?: boolean;
    },
    { previous: TData; updated: any }
  >({
    mutationFn: ({ id, data, isSelf, noReturn = noReturnOnChange }) =>
      isSelf
        ? api.selfUpdate(id, data, noReturn)
        : api.update(id, data, noReturn),
    onMutate: async ({ id, data, isSelf, noReturn }) => {
      const _queryKey = [
        queryKey,
        id,
        filters,
        useFilter,
        getPublic,
        staleTime
      ];
      await queryClient.cancelQueries({ queryKey: _queryKey });

      // Snapshot the previous value
      const previous = queryClient.getQueryData<TData>(_queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(_queryKey, { id, data, isSelf, noReturn });

      // Return a context with the previous and new todo
      return { previous, updated: { id, data, isSelf, noReturn } };
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Updated!');
    },
    onError: (err, data, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(_queryKey, context.previous);
      apiError(err);
    }
  });

  const {
    mutate: bulkUpdate,
    isLoading: isBulkUpdating,
    error: bulkUpdateError
  } = useMutation<
    TData,
    Error,
    {
      ids: number[];
      data: Partial<Omit<TData, 'id'>>;
      isSelf?: boolean;
      noReturn?: boolean;
    },
    { previous: TData; updated: any }
  >({
    mutationFn: ({ ids, data, noReturn = noReturnOnChange }) =>
      api.updateBulk({ id: ids }, data, noReturn),
    onMutate: async ({ ids, data, isSelf, noReturn }) => {
      const _queryKey = [
        queryKey,
        id,
        filters,
        useFilter,
        getPublic,
        staleTime
      ];
      await queryClient.cancelQueries({ queryKey: _queryKey });

      // Snapshot the previous value
      const previous = queryClient.getQueryData<TData>(_queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(_queryKey, { id, data, isSelf, noReturn });

      // Return a context with the previous and new todo
      return { previous, updated: { id, data, isSelf, noReturn } };
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Updated!');
    },
    onError: (err, data, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(_queryKey, context.previous);
      apiError(err);
    }
  });

  const {
    mutate: add,
    isLoading: isAdding,
    error: addError
  } = useMutation<
    TData,
    Error,
    { vals: Omit<TData, 'id'>; isSelf?: boolean; noReturn?: boolean }
  >({
    mutationFn: ({ vals, isSelf, noReturn = noReturnOnChange }) =>
      isSelf ? api.selfInsert(vals, noReturn) : api.insert(vals, noReturn),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Created!');
    }
  });
  const {
    mutate: clone,
    isLoading: isCloning,
    error: cloneError
  } = useMutation<
    TData,
    Error,
    { id: number; isSelf?: boolean; noReturn?: boolean }
  >({
    mutationFn: ({ id, noReturn = noReturnOnChange }) =>
      api.clone(id, noReturn),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Created!');
      queryClient.refetchQueries(_queryKey);
    }
  });

  const {
    mutate: remove,
    isLoading: isRemoving,
    error: removeError
  } = useMutation<TData, Error, number>({
    mutationFn: id => api.remove(id),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Removed!');
      queryClient.refetchQueries(_queryKey);
    }
  });

  const {
    mutate: bulkAdd,
    isLoading: isBulkAdding,
    error: bulkAddError
  } = useMutation<
    TData[],
    Error,
    { vals: Omit<TData, 'id'>[]; noReturn: boolean }
  >({
    mutationFn: ({ vals, noReturn = noReturnOnChange }) =>
      api.insertBulk(vals, noReturn),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      apiSuccess('Created!');
    }
  });
  const upsert = (
    vals: TData,
    done: (data: TData) => void = () => {},
    isSelf?: boolean,
    noReturn?: boolean
  ) => {
    if (vals.id) {
      update(
        { id: vals.id, data: vals, isSelf, noReturn },
        {
          onSuccess: d => done(d)
        }
      );
    } else {
      add(
        { vals, isSelf, noReturn },
        {
          onSuccess: d => done(d)
        }
      );
    }
  };
  return {
    ...query,
    counts,
    error:
      query.error ||
      addError ||
      cloneError ||
      removeError ||
      bulkAddError ||
      updateError ||
      bulkUpdateError,
    isLoading:
      (enableMemo && query.isLoading) || (countEnableMemo && isCountsLoading),
    remove,
    isRemoving,
    update,
    bulkUpdate,
    isBulkUpdating,
    updateSelf: (
      vals: { data: TData; id: number; noReturn?: boolean },
      opts?: MutateOptions<
        TData,
        Error,
        {
          id: number;
          data: Partial<Omit<TData, 'id'>>;
          isSelf?: boolean;
          noReturn?: boolean;
        },
        { previous: TData; updated: any }
      >
    ) => update({ ...vals, isSelf: true }, opts),
    add: (
      vals: Omit<TData, 'id'>,
      opts?: MutateOptions<
        TData,
        Error,
        { vals: Omit<TData, 'id'>; isSelf?: boolean; noReturn?: boolean }
      >,
      noReturn?: boolean
    ) => add({ vals, isSelf: false, noReturn }, opts),
    clone: (
      id: number,
      opts?: MutateOptions<
        TData,
        Error,
        { id: number; isSelf?: boolean; noReturn?: boolean }
      >,
      noReturn?: boolean
    ) => clone({ id, isSelf: false, noReturn }, opts),
    addSelf: (
      vals: Omit<TData, 'id'>,
      opts?: MutateOptions<
        TData,
        Error,
        { vals: Omit<TData, 'id'>; isSelf?: boolean; noReturn?: boolean }
      >,
      noReturn?: boolean
    ) => add({ vals, isSelf: true, noReturn }, opts),
    isUpdating,
    isAdding,
    isCloning,
    upsert,
    upsertSelf: (
      vals: TData,
      done: (data: TData) => void,
      noReturn?: boolean
    ) => upsert({ ...vals }, done, true, noReturn),
    isUpserting: isUpdating || isAdding,
    bulkAdd: (
      vals: Omit<TData, 'id'>[],
      opts?: MutateOptions<
        TData[],
        Error,
        { vals: Omit<TData, 'id'>[]; noReturn: boolean }
      >,
      noReturn?: boolean
    ) => bulkAdd({ vals, noReturn }, opts),
    isBulkAdding
  };
};
export type UseDefaultCrudReturn<TData = any> = ReturnType<
  typeof useDefaultCrud<TData>
>;
export const defaultCrudHookBuilder =
  <TData extends { id?: number; deletedBy?: number; deletedDate?: Date } = any>(
    key: string,
    api: CrudApi<any, any, TData>,
    defaultProps?: UseCrudProps<TData, any>
  ) =>
  <TSelected = TData[], TBy extends CountBy<TData>[] = CountBy<TData>[]>({
    countBy,
    ...props
  }: Omit<UseCrudProps<TData, TSelected>, 'countBy'> & {
    countBy?: TBy;
  } = {}) => {
    const builder = createBuilder<TData, TSelected, TBy>(key, api, {
      ...defaultProps,
      ...props
    });
    return builder(countBy);
  };
const createBuilder = <
  TData extends { id?: number; deletedBy?: number; deletedDate?: Date },
  TSelected,
  TBy extends CountBy<TData>[]
>(
  key,
  api,
  props
) => {
  return (countBy: TBy) => {
    return useDefaultCrud<TData, TSelected, TBy>(key, api, {
      ...props,
      countBy
    });
  };
};
export default useDefaultCrud;
