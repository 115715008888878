import useCustomForm from 'components/common/customForms/hooks.js/useCustomForm';
import CustomFormWizard from 'components/common/customForms/Wizard/CustomFormWizard';
import { usePopoutData } from 'hooks/usePreviewWindow';
import React, { useEffect, useState } from 'react';
import { Pipeline } from './Pipelines';
import { validate, ValidationResult } from 'helpers/validation/validate';
import { useWatch } from 'react-hook-form';
import { Form, FormQuestion } from 'apis/flex/customForms';
import PipelineResults from './PipelineResults';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Expandable from 'components/common/Expandable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial } from '@fortawesome/free-solid-svg-icons';

const PipelineRunner = ({ form }) => {
  const { data: pipelines } = usePopoutData<Pipeline>({ name: 'pipeline' });
  const formData = useWatch();
  const [results, setResults] = useState<ValidationResult[][]>();
  const [questionLookup, setQuestionLookup] =
    useState<Map<string, FormQuestion>>();
  useEffect(() => {
    if (form)
      setQuestionLookup(
        new Map(form.sections.flatMap(s => s.questions).map(q => [q.label, q]))
      );
  }, [form]);
  useEffect(() => {
    if (pipelines?.questionFilters && formData && questionLookup) {
      const res = validate.mapResults(
        pipelines.questionFilters,
        rule => rule.inputType,
        rule =>
          questionLookup.get(rule.question)
            ? formData[questionLookup.get(rule.question)?.id]
            : formData[rule.question]
      );
      setResults(res);
    }
  }, [pipelines, formData, questionLookup]);
  //   console.log(form, pipelines, formData, results);
  return (
    <Card className="mt-3">
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faVial} size="1x" className="me-2" /> Test
          results
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <h5>
          {validate.combineErrors(results).length
            ? 'Passed (pipeline will run)'
            : 'Failed (pipeline will not run)'}
        </h5>
        <Expandable>
          <PipelineResults results={results} />
        </Expandable>
      </Card.Body>
    </Card>
  );
};
export default () => {
  const { formId } = useParams();
  const { form, isLoading } = useCustomForm({ formId: Number(formId) });
  return (
    <>
      <CustomFormWizard
        testMode
        bottomElement={<PipelineRunner form={form} />}
        form={form}
        isFormLoading={isLoading}
      />
    </>
  );
};
