import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, RowProps } from 'react-bootstrap';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import { Celebration } from './lottie/wrappers/Celebration';
export type SuccessPageProps = {
  title?: string;
  description?: string;
  button?: React.ReactNode;
  celebrate?: boolean;
  animation?: any;
};
const Success = ({
  title,
  description,
  button,
  celebrate,
  animation,
  ...rest
}: SuccessPageProps & RowProps) => {
  return (
    <>
      <Row {...rest}>
        <Col className="text-center">
          {celebrate ? (
            <Celebration />
          ) : (
            <div className="mx-auto" style={{ height: '40vh' }}>
              <Lottie
                animationData={animation || checkPrimaryLight}
                loop={true}
                className="h-100"
              />
            </div>
          )}
          <h4
            className="mb-3"
            dangerouslySetInnerHTML={{ __html: title || 'Success!' }}
          />
          <div
            className="fs-0"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {button}
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.node,
  animation: PropTypes.any,
  celebrate: PropTypes.bool
};

export default Success;
