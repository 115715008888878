import React from 'react';
import {
  faDotCircle,
  faLink,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { ResponsiveModal } from 'components/common/Modals';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import WizardInput from '../WizardInput';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch
} from 'react-hook-form';
import VideoPlayer from 'components/common/VideoPlayer';
import WizardInputLabel from '../WizardInputLabel';
import classNames from 'classnames';

const OptionButton = ({
  icon,
  variant = null,
  children,
  name,
  required,
  breakpoint,
  readOnly = false,
  disabled = false,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      defaultValue={''}
      rules={{ required }}
      render={({ fieldState: { error }, field: { value } }) => (
        <IconButton
          disabled={readOnly || disabled}
          breakpoint={breakpoint}
          variant={
            (error && 'falcon-danger') ||
            variant ||
            (value && 'primary') ||
            'falcon-primary'
          }
          className="rounded-pill d-flex align-items-center justify-content-start"
          icon={icon}
          {...rest}
        >
          {children}
        </IconButton>
      )}
    />
  );
};
const FormComponents = ({
  mediaType,
  type,
  urlField,
  fileIdField,
  label,
  required,
  fileId,
  url
}) => {
  //need both to be mounted in the non-modal render if there is no value so it gives both of them the invalid formatting
  //type should always be set before the modal is shown so users won't see both
  return (
    <>
      {type !== 'record' && type !== 'file' && urlField && (
        <>
          <WizardInput
            type="text"
            placeholder="youtu.be/shyeE7f0Nc"
            label={label || 'Enter a URL'}
            name={urlField}
            registerProps={{ required: required && !fileId }}
          />
          {url && <VideoPlayer url={url} />}
        </>
      )}
      {type !== 'url' && type !== 'file' && fileIdField && (
        <WizardInput
          type={mediaType}
          label={label || 'Record your ' + mediaType}
          name={fileIdField}
          registerProps={{ required: required && !url }}
          pluginProps={{ width: 300 }}
        />
      )}
      {type !== 'url' && type !== 'record' && fileIdField && (
        <WizardInput
          type={'file'}
          label={label || 'Select a file'}
          name={fileIdField}
          registerProps={{ required: required && !url }}
          pluginProps={{
            accept:
              mediaType === 'audio' ? { 'audio/*': [] } : { 'video/*': [] }
          }}
        />
      )}
    </>
  );
};
export type VideoUploaderProps = {
  type?: string;
  fileIdField?: string;
  urlField?: string;
  label?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  required?: boolean;
  instruction?: string;
  breakpoint?: string;
  onChange?: ({ fileId, url }) => any;
};
const VideoUploader = ({
  type: mediaType = 'video',
  fileIdField,
  urlField,
  label,
  hideLabel,
  disabled,
  required = true,
  instruction,
  breakpoint = 'lg',
  onChange = () => {}
}: VideoUploaderProps) => {
  const [type, setType] = useState('');
  const [show, setShow] = useState(false);
  const url = useWatch({ name: urlField });
  const fileId = useWatch({ name: fileIdField });
  const { setValue, getFieldState } = useFormContext();
  const { errors } = useFormState();
  const handleClick = type => () => {
    setType(type);
    setShow(true);
  };
  const hasError =
    !!getFieldState(fileIdField).error || !!getFieldState(urlField).error;
  const handleConfirm = () => {
    // methods.handleSubmit(valid => {
    //   if (valid) {
    //     if (type === 'url') {
    //       setValue(fileIdField, '');
    //       setValue(urlField, methods.getValues(urlField));
    //     }
    //     if (type === 'record' || type === 'file') {
    //       setValue(urlField, '');
    //       setValue(fileIdField, methods.getValues(fileIdField), {
    //         shouldValidate: hasError
    //       });
    //     }
    setShow(false);
    onChange({ fileId, url });
    //     if (hasError) {
    //       trigger();
    //     }
    //   }
    // },()=>trigger());
  };
  const handleClear = () => {
    if (type === 'url' && urlField) {
      setValue(urlField, '');
    }
    if ((type === 'record' || type === 'file') && fileIdField) {
      setValue(fileIdField, '');
    }
    setShow(false);
  };
  // const methods = useMediaForm();
  // useEffect(() => {
  //   methods.reset({ [urlField]: url, [fileIdField]: fileId });
  // }, [url, fileId]);
  return (
    <>
      <ResponsiveModal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          {/* <FormProvider {...methods}> */}
          <FormComponents
            mediaType={mediaType}
            type={type}
            urlField={urlField}
            fileIdField={fileIdField}
            label={label}
            required={required}
            fileId={fileId}
            url={url}
          />
          {/* </FormProvider> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => setShow(false)}>
            Cancel
          </Button>
          {((type === 'url' && url) ||
            ((type === 'record' || type === 'file') && fileId)) && (
            <Button variant="danger" onClick={handleClear}>
              Remove
            </Button>
          )}
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </ResponsiveModal>
      <WizardInputLabel
        label={label}
        id={fileIdField}
        hideLabel={hideLabel}
        required={required}
        instruction={instruction}
      />
      <div style={{ width: 'fit-content' }}>
        <Flex
          className={classNames('form-control gap-2 mb-3 p-0 shadow-none ', {
            'border-0': !hasError,
            'is-invalid pe-5': hasError,
            'is-valid pe-5': !!Object.keys(errors).length && !hasError
          })}
        >
          {fileIdField && (
            <OptionButton
              name={fileIdField}
              required={required && !url}
              disabled={disabled}
              icon={faDotCircle}
              onClick={handleClick('record')}
              breakpoint={breakpoint}
            >
              Record
            </OptionButton>
          )}
          {urlField && (
            <OptionButton
              name={urlField}
              required={required && !fileId}
              disabled={disabled}
              icon={faLink}
              onClick={handleClick('url')}
              breakpoint={breakpoint}
            >
              URL
            </OptionButton>
          )}
          {fileIdField && (
            <OptionButton
              name={fileIdField}
              required={required && !url}
              disabled={disabled}
              icon={faUpload}
              onClick={handleClick('file')}
              breakpoint={breakpoint}
            >
              Upload
            </OptionButton>
          )}
        </Flex>
      </div>
    </>
  );
};
export default VideoUploader;
