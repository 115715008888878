import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { truncate } from 'lodash';
import { truncateMiddle } from 'helpers/strings';
const Truncate = ({
  text = '',
  length = 30,
  children,
  middle,
  content
}: {
  text?: string;
  length?: number;
  children?: string;
  middle?: boolean;
  content?: string;
}) => {
  const childText = text || children || '';
  let truncatedText = '';
  if (middle) {
    truncatedText = truncateMiddle(childText, length);
  } else {
    truncatedText = truncate(childText, { length, omission: '...' });
  }
  return (
    <>
      {childText.length > length ? (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }}>
              {content || childText}
            </Tooltip>
          }
        >
          <span>{truncatedText}</span>
        </OverlayTrigger>
      ) : (
        childText
      )}
    </>
  );
};
export default Truncate;
