import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

export type IconButtonProps = {
  icon: IconProp;
  iconAlign?: 'left' | 'right' | 'middle';
  iconClassName?: string;
  transform?: string;
  children?: ReactNode;
  loading?: boolean;
  breakpoint?: string;
  hideIcon?: boolean;
  size?: 'sm' | 'lg';
  iconSize?: SizeProp;
  className?: string;
  justify?: 'start' | 'center' | 'end';
  disabled?: boolean;
} & Partial<ButtonProps>;
const IconButton = ({
  icon,
  iconAlign = 'left',
  iconClassName = '',
  transform = null,
  children = null,
  loading = false,
  breakpoint = 'md',
  hideIcon = false,
  size = 'lg',
  iconSize = null,
  className = '',
  justify = 'center',
  disabled = false,
  ...rest
}: IconButtonProps) => {
  const { breakpoints } = useBreakpoints();
  return (
    <Button
      disabled={loading || disabled}
      size={size === 'sm' ? 'sm' : null}
      className={classNames(
        `d-inline-flex align-items-center justify-content-${justify} transition-base`,
        className
      )}
      {...rest}
    >
      {breakpoints.up(breakpoint) && iconAlign === 'right' && children}
      {loading ? (
        <span style={{ transform: 'scale(0.8)' }}>
          <Spinner
            className={classNames({
              'me-1':
                breakpoints.up(breakpoint) && children && iconAlign === 'left',
              'ms-1':
                breakpoints.up(breakpoint) && children && iconAlign === 'right'
            })}
            // style={style}
            as="span"
            animation="border"
            size={'sm'}
            role="status"
            aria-hidden="true"
          />
        </span>
      ) : (
        (!hideIcon || breakpoints.down(breakpoint)) && (
          <FontAwesomeIcon
            icon={icon}
            size={iconSize}
            className={classNames(iconClassName, {
              'me-1':
                breakpoints.up(breakpoint) && children && iconAlign === 'left',
              'ms-1':
                breakpoints.up(breakpoint) && children && iconAlign === 'right'
            })}
            transform={transform}
          />
        )
      )}
      {breakpoints.up(breakpoint) &&
      (iconAlign === 'left' || iconAlign === 'middle')
        ? children
        : false}
    </Button>
  );
};

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.any.isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right', 'middle']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string,
  loading: PropTypes.bool,
  iconSize: PropTypes.string
};

export default IconButton;
