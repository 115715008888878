import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
export type AvatarProps = {
  size?: string;
  rounded?: string;
  src?: any;
  name?: string;
  emoji?: ReactNode;
  className?: string;
  mediaClass?: string;
  isExact?: boolean;
  icon?: any;
  style?: any;
  isLoading?: boolean;
};
const Avatar = ({
  size,
  rounded,
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact,
  icon,
  style,
  isLoading
}: AvatarProps) => {
  const classes = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src) && src.length > 1) {
        return (
          <div
            style={style}
            className={`${mediaClasses} overflow-hidden h-100 d-flex`}
          >
            <div className="w-50 border-right">
              <img src={src[0]} alt="" />
            </div>
            {!!src[1] && (
              <div className="w-50 d-flex flex-column">
                <img
                  src={src[1]}
                  alt=""
                  className={classNames('h-50 border-bottom')}
                />
                {!!src[2] && (
                  <img src={src[2]} alt="" className={classNames('h-50')} />
                )}
              </div>
            )}
          </div>
        );
      } else {
        return <img style={style} className={mediaClasses} src={src} alt="" />;
      }
    }

    if (name) {
      return (
        <div style={style} className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : (name + '').match(/\b\w/g).join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex style={style} className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div style={style} className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    );
  };

  return (
    <div style={style} className={classes}>
      {isLoading ? <Skeleton className="h-100" circle /> : getAvatar()}
    </div>
  );
};

export const AvatarGroup = ({ children, dense, className }) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {children}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.any,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string,
  style: PropTypes.object,
  isLoading: PropTypes.bool
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  isExact: false
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool
};

export default Avatar;
