import { Department } from 'apis/flex/hr';
import { DomainItemSelectorProps } from 'components/app/users/widgets/selector/UserSelector';
import React from 'react';
import useDepartments from './useDepartments';
import { domainConfigs } from 'components/notification/config';
import ItemSelector, { SelectorItem } from 'components/common/ItemSelector';
import { useNavigate } from 'react-router-dom';
import DomainItemIcon, {
  DomainIconItemProps
} from 'components/common/DomainItemIcon';
const useItems = ({
  filter = () => true,
  mutator = () => ({})
}: {
  filter?: (course: Department) => boolean;
  mutator?: (course: Department) => Partial<Department>;
}) => {
  return useDepartments<SelectorItem[]>({
    useFilter: true,
    select: d =>
      d.filter(filter).map((dep: Department) => ({
        ...domainConfigs.department.format(dep),
        ...mutator(dep)
      }))
  });
};
export const DepartmentSelector = ({
  filter = () => true,
  ...rest
}: DomainItemSelectorProps) => {
  const { data, isLoading } = useItems({
    filter
  });
  const nav = useNavigate();
  return (
    <ItemSelector
      name="departmentId"
      label="Department"
      placeholder="Select a department"
      domain="department"
      data={data}
      isLoading={isLoading}
      onNewClick={() => nav('/hr/company/departments/new')}
      {...rest}
    />
  );
};
export const SelectedDepartment = ({
  ids = [],
  ...props
}: Omit<DomainIconItemProps, 'data'> & { ids?: number[] }) => {
  const { data } = useItems({
    filter: emp => ids?.some(id => id == emp.id)
  });
  return <DomainItemIcon domain="department" data={data} {...props} />;
};
