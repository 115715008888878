import { distance } from 'fastest-levenshtein';
export function parseUKCurrency(number) {
  // Convert number to string and remove any non-digit characters
  const numberString = String(number).replace(/[^\d.-]/g, '');

  // Split the string into whole and decimal parts
  const [wholePart, decimalPart] = numberString.split('.');

  // Format the whole part with commas for thousands separator
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine whole and decimal parts with the appropriate currency symbols
  let currencyString = '£' + formattedWholePart;
  if (decimalPart !== undefined) {
    currencyString += '.' + decimalPart.padEnd(2, '0');
  } else {
    currencyString += '.00';
  }

  return currencyString;
}
export function removeHtmlTags(str) {
  // Use a regular expression to match HTML tags and replace them with an empty string
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}
export function ensureURLFormat(url) {
  // Add protocol if missing
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }

  // Parse the URL to handle www. and trailing slash
  const urlObject = new URL(url);

  // Add www. if missing (and not already part of a subdomain)
  if (!/^www\./i.test(urlObject.hostname)) {
    urlObject.hostname = 'www.' + urlObject.hostname;
  }

  // Add trailing slash if missing
  if (!urlObject.pathname.endsWith('/')) {
    urlObject.pathname += '/';
  }

  return urlObject.toString();
}
export function parseUKCurrencyToNumber(currencyString) {
  // Remove currency symbol and commas
  const cleanedString = currencyString.replace(/[^\d.-]/g, '');

  // Convert to number
  const number = parseFloat(cleanedString);

  return number;
}

export function matchWildcard(pattern, str) {
  // Base case: if both pattern and string are empty, return true
  if (pattern.length === 0 && str.length === 0) {
    return true;
  }

  // If the pattern is empty but the string isn't, return false
  if (pattern.length === 0) {
    return false;
  }

  // If the pattern starts with '*', we have two options:
  // 1. Ignore the '*' character and try to match the remaining pattern with the string
  // 2. Ignore the first character of the string and try to match the pattern with the remaining string
  if (pattern[0] === '*') {
    return (
      matchWildcard(pattern.substring(1), str) ||
      (str.length > 0 && matchWildcard(pattern, str.substring(1)))
    );
  }

  // If the pattern's first character matches the string's first character
  // or the pattern's first character is '?', then continue to the next characters
  if (pattern[0] === str[0] || pattern[0] === '?') {
    return matchWildcard(pattern.substring(1), str.substring(1));
  }

  // If none of the above conditions are met, return false
  return false;
}
export function truncateMiddle(fullStr, strLen, separator = '...') {
  if (fullStr.length <= strLen) return fullStr;

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}
export function compareStrings(string1, string2) {
  const editDistance = distance(string1, string2);
  const maxDistance = Math.max(string1.length, string2.length);
  const levenshteinScore = 1 - editDistance / maxDistance;
  return levenshteinScore;
}
export const extractTextFromChildren = children => {
  if (typeof children === 'string') {
    return children;
  }

  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join('');
  }

  if (typeof children === 'object' && children !== null) {
    return extractTextFromChildren(children.props?.children);
  }

  return '';
};
export const replaceHandlebarsWithValues = (text, values) => {
  return text.replace(/{{(.*?)}}/g, (match, key) => values[key] || '');
};
