import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { ErrorComponentLayout } from 'layouts/ErrorLayout';

const LoginRedirect = ({ button }) => {
  return (
    <ErrorComponentLayout>
      <Card className="text-center h-100">
        <Card.Body className="p-5">
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            Logging you back in...
          </p>
        </Card.Body>
        <Card.Footer>{button}</Card.Footer>
      </Card>
    </ErrorComponentLayout>
  );
};
LoginRedirect.propTypes = {
  error: PropTypes.object,
  button: PropTypes.any
};
export default LoginRedirect;
