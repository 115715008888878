import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { CustomNavigationClient } from './NavigationClient';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { publicLoginRequest } from 'authConfig';
import PageLoader from 'components/common/PageLoader';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { setItemToStore } from 'helpers/utils';
import { flexLogout } from 'apis/flex/auth';
import IconButton from 'components/common/IconButton';
import LoginError from 'components/errors/LoginError';
import LoginRedirect from 'components/errors/LoginRedirect';
import useLogin from './useLogin';
import { UserProvider } from 'hooks/useUser';

export const ClientSideNavigation = ({ pca, children }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
};
ClientSideNavigation.propTypes = {
  pca: PropTypes.object,
  children: PropTypes.node
};
const LoginBtn = () => {
  const { instance } = useMsal();
  return (
    <IconButton
      onClick={() => {
        flexLogout();
        setItemToStore('client-access-token', null);
        const logoutRequest = {
          account: instance.getActiveAccount(),
          postLogoutRedirectUri: process.env.REACT_APP_PUBLIC_URL
        };
        instance.logoutRedirect(logoutRequest);
      }}
      icon={faChevronLeft}
      variant="primary"
      size="sm"
    >
      Back to login
    </IconButton>
  );
};
const FlexAuthProvider = ({ children }) => {
  const { data, error, isLoading, errorCount } = useLogin();
  return error ? (
    errorCount < 20 ? (
      <LoginRedirect />
    ) : (
      <LoginError button={<LoginBtn />} error={error} />
    )
  ) : data ? (
    <UserProvider user={data}>{children}</UserProvider>
  ) : (
    isLoading && <PageLoader message="Building profile..." />
  );
};

FlexAuthProvider.propTypes = {
  children: PropTypes.node
};
const AuthLoader = () => {
  return <PageLoader message="Authenticating..." />;
};
const AuthError = props => {
  return <LoginError {...props} button={<LoginBtn />} />;
};
const PrivateProvider = ({ children }) => {
  const { instance } = useMsal();
  const authRequest = publicLoginRequest;
  return (
    <ClientSideNavigation pca={instance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={AuthError}
        loadingComponent={AuthLoader}
      >
        <FlexAuthProvider>{children}</FlexAuthProvider>
      </MsalAuthenticationTemplate>
    </ClientSideNavigation>
  );
};
PrivateProvider.propTypes = {
  children: PropTypes.node
};
export default PrivateProvider;
