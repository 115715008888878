import React, { HTMLProps, ReactNode } from 'react';
import classNames from 'classnames';
import { Variant } from 'react-bootstrap/esm/types';

const SoftBadge = ({
  bg = 'primary',
  pill,
  children,
  className,
  ...rest
}: {
  bg?: Variant;
  pill?: boolean;
  children: ReactNode;
  className?: string;
} & HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={classNames(className, `badge badge-soft-${bg}`, {
        'rounded-pill': pill
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export default SoftBadge;
