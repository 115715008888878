import Avatar from 'components/common/Avatar';
import React, { ReactNode } from 'react';
import blankUser from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Truncate from './Truncate';
export type SelectorItemData = {
  avatar?: string;
  icon?: ReactNode;
  color?: string;
  label: string;
  info?: Record<string, any>;
  value?: any;
  tags?: ReactNode;
};
export type SelectorItemProps = {
  data: SelectorItemData;
  avatar?: string;
  icon?: ReactNode;
  compact?: boolean;
  isLoading?: boolean;
  color?: string;
  iconWidth?: string | number;
  size?: 'sm' | 'md' | 'lg';
  show?: boolean;
  onNameClick?: (data: SelectorItemData) => void;
};
export default ({
  data,
  avatar = blankUser,
  icon,
  compact,
  isLoading,
  color = 'primary',
  size = 'md',
  show,
  onNameClick
}: SelectorItemProps) => {
  const widths = {
    sm: 32,
    md: 56,
    lg: 98
  };
  const sizes = {
    sm: 'xl',
    md: '3xl',
    lg: '4xl'
  };
  const avatarSize = sizes[size];
  const iconWidth = widths[size];
  const margin = compact ? '0.5rem' : '1rem';
  return (
    <Flex
      className={classNames(
        'align-items-center position-relative d-flex text-start'
      )}
    >
      {data.avatar ? (
        <Avatar
          src={data.avatar || avatar}
          className={`status-offline`}
          mediaClass={
            'border border-4 rounded-circle border-' + color + '-subtle bg-100'
          }
          isLoading={isLoading}
          size={avatarSize}
        />
      ) : data.icon ? (
        <div
          style={{ width: iconWidth, height: iconWidth }}
          className={`align-items-center border border-3 bg-100 border-${
            data.color || color
          }-subtle d-flex fs-3 justify-content-center rounded-circle shadow-none text-${
            data.color || color
          }`}
        >
          {data.icon}
        </div>
      ) : icon ? (
        <div
          style={{ width: iconWidth, height: iconWidth }}
          className={`align-items-center border border-3 bg-100 border-${
            data.color || color
          }-subtle d-flex fs-3 justify-content-center rounded-circle shadow-none text-${
            data.color || color
          }`}
        >
          {icon}
        </div>
      ) : (
        <Avatar
          src={avatar}
          className={`status-offline`}
          mediaClass={
            'border border-4 rounded-circle border-' + color + '-subtle bg-100'
          }
          isLoading={isLoading}
          size={avatarSize}
        />
      )}
      <motion.div
        className="overflow-hidden text-nowrap"
        key={show.toString()}
        custom={show}
        variants={{
          initial: i => ({
            maxWidth: i ? '100%' : 0,
            marginLeft: i ? margin : 0
          }),
          animate: i => ({
            maxWidth: i ? '100%' : 0,
            marginLeft: i ? margin : 0
          }),
          hover: { maxWidth: '100%', marginLeft: margin }
        }}
      >
        <h6
          className={classNames('fw-semi-bold d-flex mb-0', {
            'btn-link': !!onNameClick
          })}
          onClick={() => onNameClick && onNameClick(data)}
        >
          <Truncate length={compact && 15}>{data.label}</Truncate>
          {data.tags && <div className="ms-2">{data.tags}</div>}
        </h6>
        {data.info &&
          !compact &&
          Object.keys(data.info)
            .filter(name => !!data.info[name])
            .filter((_, i) => i < 4)
            .map(name => (
              <div
                key={name}
                className="fs--2 lh-1 mt-1 text-500 d-flex align-items-center"
              >
                {data.info[name]}
              </div>
            ))}
      </motion.div>
    </Flex>
  );
};
