import ReactECharts from 'echarts-for-react'; // or var ReactECharts = require('echarts-for-react');
import React from 'react';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
// import EChartsReactCore from 'echarts-for-react/lib/core';
import { EChartsCoreOption } from 'echarts';
echarts.use([CanvasRenderer, SVGRenderer]);

const HalfDoughnutChart = ({ color = 'primary', target, reached, height }) => {
  const option: EChartsCoreOption = {
    series: [
      {
        color: [getColor(color), getColor('gray-300')],
        // name: 'Score',
        label: null,
        type: 'pie',
        radius: ['80%', '100%'],
        // adjust the start and end angle
        startAngle: 180,
        endAngle: 360,
        bottom: '-95%',
        itemStyle: {
          borderRadius: 5
        },
        data: [
          { value: reached, name: 'Score' },
          { value: target - reached, name: '' }
        ]
      }
    ]
  };
  return <ReactECharts style={{ height }} option={option} />;
};

HalfDoughnutChart.propTypes = {
  target: PropTypes.number,
  reached: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.any
};

export default HalfDoughnutChart;
