import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import WizardInput from 'components/wizard/WizardInput';
import React, { useState, useTransition } from 'react';
import { useEffect } from 'react';
import {
  Form,
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { getSqlEditProps } from '../advance-table-v2/sql';
import is from 'is_js';
import Skeleton from 'react-loading-skeleton';
import LoadingButton from '../LoadingButton';
import { getSqlCols } from 'apis/flex/adminTable';
import Flex from '../Flex';
import { Button, Col, Row } from 'react-bootstrap';
import { useMemo } from 'react';
import { FilterValueInput } from '../advance-table-v2/AdvanceTableFilters';
const EditorField = ({ field }) => {
  const active = useWatch({ name: 'active.' + field.id });
  const inputProps = useMemo(
    () => ({
      formGroupProps: { className: 'w-100 mb-3' },
      name: field.id,
      label: field.title,
      disabled: !active,
      multiple: false,
      ...field.inputProps
    }),
    [field, active]
  );
  const { setValue } = useFormContext();

  return (
    <Flex alignItems={'start'} className={'w-100 position-relative'}>
      <WizardInput
        name={'active.' + field.id}
        type="switch"
        registerProps={{ required: false }}
        hideLabel
      />
      {field.editor ? (
        field.editor(inputProps)
      ) : (
        <FilterValueInput
          column={field}
          size="md"
          getFilterName={x =>
            x === 'type'
              ? 'comparitorForFilter'
              : x === 'active'
              ? 'active.' + field.id
              : field.id
          }
          {...inputProps}
        />
      )}
      {!active && (
        <div
          className="position-absolute w-100 h-100 bg-300 bg-opacity-10"
          onClick={() => {
            setValue('active.' + field.id, true);
            // setValue(getFilterName('type'), types[0].value);
            // setFocus(getFilterName('name'));
          }}
        ></div>
      )}
    </Flex>
  );
};
EditorField.propTypes = {
  field: PropTypes.object
};
const EditorForm = ({ sqlTables = [], fields }) => {
  const { data: columnData = {}, isLoading: columnsLoading } = useQuery({
    queryFn: () => getSqlCols(sqlTables).then(d => d.data),
    enabled: sqlTables.length > 0,
    staleTime: Infinity,
    select: d =>
      d.reduce((a, b) => {
        a[b.COLUMN_NAME] = b;
        return a;
      }, {}),
    queryKey: [...sqlTables, 'sqlColumns']
  });
  const fieldArray = useMemo(
    () =>
      fields.map(field => {
        const fieldObj = is.string(field) ? { id: field } : field;
        const sqlProps = getSqlEditProps(columnData[fieldObj.id]);
        const typeWithSelectSwitch = fieldObj.options?.filter(d => d).length
          ? 'select'
          : fieldObj.inputType || sqlProps.inputType;
        return {
          inputProps: fieldObj.editor ? {} : sqlProps,
          ...fieldObj,
          inputType: typeWithSelectSwitch,
          name: fieldObj.id
        };
      }),
    [fields, columnData]
  );
  const mappedFields = useMemo(
    () =>
      fieldArray.map((field, i) => (
        <Col
          key={i}
          xs={12}
          lg={6}
          className="align-items-center d-flex"
          {...field.colProps}
        >
          <EditorField field={field} />
        </Col>
      )),
    [fieldArray]
  );
  return columnsLoading && sqlTables.length ? (
    <Skeleton count={7} className="mb-3" />
  ) : (
    <Row className="g-2">{mappedFields}</Row>
  );
};
EditorForm.propTypes = {
  sqlTables: PropTypes.array,
  fields: PropTypes.array
};
const AdminEditor = ({ sqlTables, fields, data, mutateQuery, onClose }) => {
  const { mutate, isLoading: loading } = useMutation(mutateQuery);
  const [submitting, setSubmitting] = useState();
  const [isSubmitting, startSubmitting] = useTransition();
  // const { data } = useQuery(dataQuery);
  const methods = useForm({
    defaultValues: { ...data, comparitorForFilter: '==' }
  });
  const handleSubmit = vals => {
    setSubmitting(true);
    startSubmitting(() => {
      mutate(
        Object.keys(vals).reduce((a, b) => {
          if (vals.active[b]) {
            a[b] = vals[b];
          }
          return a;
        }, {}),
        { onSettled: () => setSubmitting(false) }
      );
    });
  };
  useEffect(() => {
    if (data) {
      methods.reset({
        ...data,
        active: Object.keys(data).reduce((a, b) => {
          a[b] = false;
          return a;
        }, {})
      });
    }
  }, [data]);
  const active = methods.watch('active');
  const activeKeys = active && Object.keys(active).filter(k => active[k]);
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleSubmit)}>
        <EditorForm {...{ sqlTables, fields }} />
        <Flex justifyContent={'end'}>
          {onClose && (
            <Button variant="link" type="button" onClick={onClose}>
              Cancel
            </Button>
          )}
          <LoadingButton
            disabled={active && activeKeys.length === 0}
            type="submit"
            loading={submitting || isSubmitting || loading}
          >
            Apply
          </LoadingButton>
        </Flex>
      </Form>
    </FormProvider>
  );
};
AdminEditor.propTypes = {
  sqlTables: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.array,
  data: PropTypes.object,
  mutateQuery: PropTypes.object,
  onClose: PropTypes.func
};
export default AdminEditor;
