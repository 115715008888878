import React, {
  JSXElementConstructor,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useTransition
} from 'react';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import Flex from '../Flex';
import PropTypes from 'prop-types';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import LoadingButton from '../LoadingButton';
import AdvanceTableSearchBox from './AdvanceTableSearchBox';
import { useAdvanceTable } from './AdvanceTableProvider';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { camelToSentence } from 'helpers/utils';
import { useQuery } from '@tanstack/react-query';
import { ItemSelectorProps } from '../ItemSelector';
import ApplicantSelector from 'components/app/recruitment/applicants/ApplicantSelector';
import FormPicker from '../customForms/widgets/FormPicker';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';
import TrainingCoursePicker from 'components/app/hr/training/widgets/TrainingCoursePicker';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import { RoleDomain } from 'apis/flex/users';
import { getRuleInputType, getRuleOptions } from 'helpers/validation/rules';

const ActionButtons = ({
  onSubmit,
  onReset,
  submitting,
  resetting
}: {
  onSubmit: () => void;
  onReset: () => void;
  submitting: boolean;
  resetting: boolean;
}) => {
  const [submitClicked, startSubmitting] = useTransition();
  const [resetClicked, startResetting] = useTransition();
  const handleApplyClick = () => {
    startSubmitting(() => {
      onSubmit();
    });
  };
  const handleResetClick = () => {
    startResetting(() => {
      onReset();
    });
  };
  return (
    <Flex justifyContent={'end'} className={'gap-1'}>
      <LoadingButton
        loading={submitClicked || submitting}
        onClick={handleApplyClick}
        variant="primary"
      >
        Apply
      </LoadingButton>
      <LoadingButton
        loading={resetClicked || resetting}
        variant="secondary"
        onClick={handleResetClick}
      >
        Reset
      </LoadingButton>
    </Flex>
  );
};
ActionButtons.propTypes = {
  setResetting: PropTypes.func,
  setSubmitting: PropTypes.func,
  submitting: PropTypes.bool,
  resetting: PropTypes.bool
};

const customFilterInputs = {
  name: 'text',
  postalAddress: 'text',
  shifts: 'test'
};
const convertCustomInputTypes = type => {
  return customFilterInputs[type] || type;
};
export const FilterTypeInput = ({
  column,
  getFilterName,
  active,
  ...rest
}: {
  column: {
    inputType: string;
    options?: (string | { value: string; label: string })[];
    domain?: RoleDomain;
  };
  active?: boolean;
  getFilterName: (type: string) => string;
} & Partial<WizardInputProps>) => {
  const columnFilterType = convertCustomInputTypes(column.inputType);
  const typedCol = { ...column, type: columnFilterType };
  const types = getRuleOptions(typedCol);
  const { setValue } = useFormContext();
  const isActive = useWatch({ name: getFilterName('active') }) || active;
  const type = useWatch({ name: getFilterName('type') });
  useEffect(() => {
    if (!isActive) {
      setValue(getFilterName('type'), null);
    } else if (!type) {
      setValue(getFilterName('type'), types[0].value);
    }
  }, [isActive]);
  return (
    <WizardInput
      type="select"
      options={types}
      name={getFilterName('type')}
      hideLabel
      registerProps={{
        disabled: !isActive,
        required: !!isActive
      }}
      pluginProps={{ size: 'sm' }}
      {...rest}
    />
  );
};
const domainPickers = {
  applicant: ApplicantSelector,
  form: FormPicker,
  user: UserSelector,
  'training-course': TrainingCoursePicker,
  contract: ContractPicker
};
export const FilterValueInput = ({
  column,
  controlProps = { step: 1 },
  data = [],
  getFilterName,
  active,
  size = 'sm',
  ...rest
}: {
  column: {
    id: string;
    inputType: string;
    filter?: (props: any) => ReactNode;
    options?: (string | { value: string; label: string })[];
    optionsQuery?: any;
    domain?: RoleDomain;
  };
  active?: boolean;
  controlProps?: any;
  data?: any;
  getFilterName: (type: string) => string;
  size?: string | 'sm';
} & Partial<WizardInputProps>) => {
  const isActive = useWatch({ name: getFilterName('active') }) || active;
  const columnFilterType = convertCustomInputTypes(column.inputType);
  const typedCol = { ...column, inputType: columnFilterType };
  const inputType = typedCol.inputType;
  const filterType = useWatch({ name: getFilterName('type') });
  const { setFocus, resetField } = useFormContext();
  const filteredInputType = getRuleInputType(inputType, filterType);
  const { data: optionsData, isLoading: optionsLoading } = useQuery<any[]>({
    enabled: !!typedCol.optionsQuery,
    ...(typedCol.optionsQuery || {})
  });
  // let options: any[] = typedCol.options || optionsData;
  // if (
  //   Array.isArray(filteredInputType) &&
  //   typeof filteredInputType !== 'string'
  // ) {
  //   options = filteredInputType;
  // }
  const pluginProps: Record<string, any> = {
    step: 1,
    min: 0,
    max: 1
  };
  if (filteredInputType === 'range') {
    if (inputType === 'score') {
      pluginProps.step = 1;
      pluginProps.min = 0;
      pluginProps.max = 100;
    } else {
      pluginProps.step = controlProps.step || 1;
      pluginProps.min = 0;
      pluginProps.max = Math.max(
        5,
        Math.max(
          ...data.filter(d => !isNaN(d[typedCol.id])).map(d => d[typedCol.id])
        )
      );
    }
  }
  useEffect(() => {
    if (isActive) {
      setFocus(getFilterName('value'));
      // resetField(getFilterName('value'));
    }
  }, [isActive]);
  if (filteredInputType === null) {
    return (
      <div
        {...{
          ...{ className: 'mb-3 position-relative' },
          ...rest?.formGroupProps
        }}
      ></div>
    );
  }
  const inputProps = {
    name: getFilterName('value'),
    hideLabel: true,
    loading: optionsLoading && typedCol.optionsQuery,
    disabled: !isActive,
    formControlProps: controlProps,
    registerProps: { required: false },
    pluginProps: {
      ...pluginProps,
      variant: isActive ? 'primary' : 'secondary'
    },
    ...rest
  };
  const DomainPicker: JSXElementConstructor<Omit<ItemSelectorProps, 'data'>> =
    domainPickers[typedCol.domain];
  const options =
    typedCol.options ||
    optionsData ||
    (Array.isArray(filteredInputType) && filteredInputType) ||
    [];
  if (!filteredInputType) return null;
  return typedCol.filter ? (
    typedCol.filter(inputProps)
  ) : DomainPicker ? (
    <DomainPicker
      multiple
      {...inputProps}
      // {...inputProps.pluginProps}
      compact
      size="sm"
    />
  ) : (
    <WizardInput
      type={
        Array.isArray(filteredInputType) ? 'multiSelect' : filteredInputType
      }
      options={options}
      {...inputProps}
      pluginProps={{ size, ...inputProps.pluginProps }}
    />
  );
};
FilterValueInput.propTypes = {
  column: PropTypes.object,
  isActive: PropTypes.bool,
  controlProps: PropTypes.object,
  data: PropTypes.array,
  getFilterName: PropTypes.func
};
export const Filter = ({ column, data, name }) => {
  const { setValue } = useFormContext();
  const getFilterName = type => name + '.' + type;
  const isActive = useWatch({ name: getFilterName('active') });
  return (
    <Flex alignItems={'end'} wrap={'wrap'} className={'mb-3'}>
      <label className="form-label w-100">
        {column.header || camelToSentence(column.id)}
      </label>
      <Flex
        alignItems={'center'}
        wrap={'wrap'}
        className={'w-100 position-relative'}
      >
        <WizardInput
          type="switch"
          hideLabel
          registerProps={{ required: false }}
          name={getFilterName('active')}
          formGroupProps={{
            style: { width: '10%' },
            className: 'my-auto mb-auto'
          }}
        />
        <FilterTypeInput
          getFilterName={getFilterName}
          column={column}
          formGroupProps={{
            style: { width: '39%' },
            className: 'm-auto mb-auto'
          }}
        />
        <FilterValueInput
          formGroupProps={{
            className: 'w-50 m-auto mb-auto'
          }}
          getFilterName={getFilterName}
          column={column}
          controlProps={column.filterControlProps}
          data={data}
        />
        {!isActive && (
          <div
            className="position-absolute w-100 h-100 bg-300 bg-opacity-10"
            onClick={() => {
              setValue(getFilterName('active'), true);
              // setValue(getFilterName('type'), types[0].value);
              // setFocus(getFilterName('name'));
            }}
          ></div>
        )}
      </Flex>
    </Flex>
  );
};
Filter.propTypes = {
  column: PropTypes.object,
  data: PropTypes.array,
  index: PropTypes.number
};
export const convertTableFiltersToFormFields = filters => {
  return (filters || []).reduce(
    (a, b) => ({
      ...a,
      [b.id]: b.value || {
        value: '',
        type: '',
        active: false
      }
    }),
    {}
  );
};
export const convertFormFieldsToTableFilters = (
  vals: FilterFormValues
): TableFilters => {
  return Object.keys(vals).map(k => ({
    id: k,
    value: { ...vals[k], active: !!vals[k]?.type }
  }));
};
export type TableFilters = {
  id: string;
  value: {
    active: boolean;
    value: string;
    type: string;
  };
}[];
export type FilterFormValues = Record<
  string,
  {
    value: string;
    type: string;
    active: boolean;
  }
>;
export const TableFilterProvider = ({ children, replace = false }) => {
  const { filters, setPagination, pagination, setFilters, getAllFlatColumns } =
    useAdvanceTable();
  const defaultValues = useMemo(
    () =>
      getAllFlatColumns()
        .filter(c => c.columnDef.enableColumnFilter !== false)
        .reduce(
          (a, b) => ({
            ...a,
            [b.columnDef.id]: { value: '', type: '', active: false }
          }),
          {} as FilterFormValues
        ),
    [getAllFlatColumns]
  );
  const methods = useForm<FilterFormValues, any, FilterFormValues>({
    defaultValues
    // values: filters
  });
  useEffect(() => {
    if (filters.length > 0) setPagination({ ...pagination, pageIndex: 0 });
    // console.log('resetting filters', filters, defaultValues);
    methods.reset({
      ...defaultValues,
      ...convertTableFiltersToFormFields(filters)
    });
  }, [filters]);
  const handleSubmit = (done, err) =>
    methods.handleSubmit(vals => {
      if (replace) {
        setFilters(convertFormFieldsToTableFilters(vals));
      } else {
        setFilters(f => {
          const dedupe = new Map(
            [...f, ...convertFormFieldsToTableFilters(vals)].map(v => [v.id, v])
          );
          return [...dedupe.values()];
        });
      }
      done();
    }, err);
  const reset = () => {
    setFilters([]);
  };
  return (
    <FormProvider {...methods} handleSubmit={handleSubmit} reset={reset}>
      {children}
    </FormProvider>
  );
};
const FilterForm = ({ onSubmit, columns }) => {
  const { getPrePaginationRowModel } = useAdvanceTable();
  const startSubmitting = useTransition()[1];
  const data = useMemo(
    () => getPrePaginationRowModel().rows.map(r => r.original),
    [getPrePaginationRowModel]
  );
  const handleFilterChange = () => {
    setSubmitting(false);
    setResetting(false);
    onSubmit();
  };
  const [submitting, setSubmitting] = useState<boolean>();
  const [resetting, setResetting] = useState<boolean>();
  const { handleSubmit, reset } = useFormContext();
  const [search, setSearch] = useState();
  const handleFormSubmit = () => {
    startSubmitting(() => {
      handleSubmit(handleFilterChange, console.error)();
    });
  };
  const handleReset = () => {
    reset({});
    handleFilterChange();
  };
  return (
    <>
      <Form
        action="()=>{}"
        onSubmit={e => {
          e.preventDefault();
          handleFormSubmit();
        }}
        className="overflow-y-scroll"
        style={{ height: 'calc(100% - 38px)' }}
      >
        <AdvanceTableSearchBox
          placeholder="Search filters..."
          onChange={val => setSearch(val)}
          className={'mb-4'}
          autoFocus
          formControlProps={null}
        />
        {columns
          .filter(
            c =>
              !search || (c.header || c.id).toLowerCase().indexOf(search) > -1
          )
          // .sort((a, b) => {
          //   return (b.value.active ? 1 : 0) - (a.value.active ? 1 : 0);
          // })
          .map(col => (
            <Filter name={col.id} column={col} key={col.id} data={data} />
          ))}
        <Button className="d-none" type="submit"></Button>
      </Form>
      <div className="">
        <ActionButtons
          onSubmit={handleFormSubmit}
          onReset={handleReset}
          {...{
            resetting,
            submitting
          }}
        />
      </div>
    </>
  );
};
const FilterProvider = ({ onSubmit }) => {
  // const { data } = useAdminData();
  // const { adminFilters, filterColumns } = useAdminFilters();
  const { getAllFlatColumns } = useAdvanceTable();
  const fields = useMemo(
    () =>
      getAllFlatColumns()
        .map(col => col.columnDef)
        .filter(
          col =>
            col.filter !== false &&
            !col.isSelect &&
            !col.isAction &&
            col.enableColumnFilter !== false
        ),
    [getAllFlatColumns]
  );

  return (
    <TableFilterProvider replace>
      <FilterForm onSubmit={onSubmit} columns={fields} />
    </TableFilterProvider>
  );
};
export const AdvanceTableFilters = ({
  filterShow,
  setFilterShow
}: {
  filterShow: boolean;
  setFilterShow: (filterShow: boolean) => void;
}) => {
  function handleClose() {
    setFilterShow(false);
  }
  return (
    <Offcanvas
      className="tour-filters-body"
      show={filterShow}
      onHide={handleClose}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pe-0">
        <FilterProvider onSubmit={handleClose} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};
AdvanceTableFilters.propTypes = {
  filterShow: PropTypes.bool,
  setFilterShow: PropTypes.func
};
