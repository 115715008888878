import { useMsal } from '@azure/msal-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { flexLogin } from 'apis/flex/auth';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import { socket } from 'index';
import { useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { User } from 'components/app/users/types';
import { publicLoginRequest } from 'authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { LoginContext } from 'context/Context';

const attemptLogin = async () => {
  const { data: user } = await flexLogin();
  if (!user) throw new Error('No data');
  setItemToStore('loginErrorCount', 0);
  return user;
};

export default () => {
  const { settings, setSettings } = useContext(LoginContext);
  const t = useMsal();
  const queryClient = useQueryClient();
  const errorCount = getItemFromStore('loginErrorCount', 0);
  const query = useQuery<any, Error, User>({
    staleTime: 1000 * 60 * 10,
    // refetchOnWindowFocus: false,
    queryFn: async () => {
      try {
        const user = await attemptLogin();
        return user;
      } catch (e) {
        setItemToStore('loginErrorCount', errorCount + 1);
        const newErrorCount = getItemFromStore('loginErrorCount', 0);
        if (newErrorCount > 10) {
          throw new Error('Too many errors');
        }
        await t.instance
          .acquireTokenSilent(publicLoginRequest)
          .catch(async e => {
            if (e instanceof InteractionRequiredAuthError) {
              return await t.instance.acquireTokenRedirect(publicLoginRequest);
            }
          });
        return attemptLogin();
      }
    },
    queryKey: ['flexLogin']
  });
  const currentToken = getItemFromStore('client-access-token', null);
  useEffect(() => {
    if (!currentToken && !query.isFetching && !query.isError) {
      query.refetch();
    }
  }, [currentToken]);
  useEffect(() => {
    const sendLogin = () => {
      socket.emit('login', query.data);
    };
    if (query.data && query.data.firstName) {
      queryClient.invalidateQueries(['Notifications']);
      Sentry.setUser({
        email: query.data.email,
        name: query.data.firstName + ' ' + query.data.surname
      });
      sendLogin();
      socket.io.on('reconnect', sendLogin);
      if (!settings.welcomed) {
        setSettings(s => ({ ...s, welcomed: true }));
        toast.success('Welcome back ' + query.data.firstName + '!');
      }
    }
    return () => {
      socket.io.off('reconnect', sendLogin);
    };
  }, [query.data]);
  return { ...query, errorCount };
};
