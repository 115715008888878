import React from 'react';
import { Employee } from 'apis/flex/hr';
import ItemSelector, { SelectorItem } from 'components/common/ItemSelector';
import useEmployees from '../hooks/useEmployees';
import {
  DomainItemSelectorProps,
  SelectedDomainItemsProps
} from 'components/app/users/widgets/selector/UserSelector';
import DomainItemIcon from 'components/common/DomainItemIcon';

const useEmployeeItems = ({
  filter = () => true,
  mutator = () => ({})
}: {
  filter?: (course: Employee) => boolean;
  mutator?: (course: Employee) => Partial<SelectorItem>;
}) => {
  return useEmployees<SelectorItem[]>({
    useFilter: true,
    select: d =>
      d.filter(filter).map((emp: Employee) => ({
        label: emp.firstName + ' ' + emp.surname,
        value: emp.id,
        info: {
          jobTitle: emp.jobTitle
        },
        avatar: emp.avatar,
        ...mutator(emp)
      }))
  });
};
export const EmployeeSelector = ({
  filter = () => true,
  ...rest
}: DomainItemSelectorProps) => {
  const { data, isLoading } = useEmployeeItems({
    filter
  });
  return (
    <ItemSelector
      name="employeeId"
      label="Employee"
      placeholder="Select an employee"
      domain="employee"
      data={data}
      isLoading={isLoading}
      {...rest}
    />
  );
};
export const SelectedEmployees = ({
  ids,
  ...props
}: SelectedDomainItemsProps) => {
  const { data, isLoading } = useEmployeeItems({
    filter: emp => ids?.some(id => id == emp.id)
  });
  return (
    <DomainItemIcon
      domain="employee"
      isLoading={isLoading}
      data={data}
      {...props}
    />
  );
};
