import React from 'react';
import WizardInput from '../../../wizard/WizardInput';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

export const NINumberInput = ({ review, inputProps, registerProps }) => (
  <WizardInput
    type="ni"
    label="National Insurance number"
    placeholder="AB123456C"
    name="ni"
    registerProps={{
      ...registerProps,
      setValueAs: v => v && v.toString().trim().toUpperCase(),
      pattern: {
        value:
          /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i,
        message: 'National Insurance number is not valid'
      }
    }}
    formControlProps={{
      disabled: review
    }}
    {...inputProps}
  />
);
NINumberInput.propTypes = {
  review: PropTypes.bool,
  inputProps: PropTypes.object,
  registerProps: PropTypes.object
};
export const PhoneInput = ({ review, inputProps, registerProps }) => {
  return (
    <WizardInput
      type="telephone"
      name="telephone"
      placeholder="07000000000"
      registerProps={{
        ...registerProps,
        setValueAs: v => v && v.toString().replaceAll(' ', ''),
        pattern: {
          value:
            /^((\+44\s?\d{4}|\(?\d{5}\)?)\s?\d{6})|((\+44\s?|0)7\d{3}\s?\d{6})$/i,
          message: 'Invalid phone number'
        }
      }}
      formControlProps={{
        disabled: review
      }}
      {...inputProps}
    />
  );
};
PhoneInput.propTypes = NINumberInput.propTypes;
export const EmailInput = ({ review, inputProps, registerProps }) => {
  const firstEmail = useWatch({ name: inputProps?.name || 'email' });
  return (
    <>
      <WizardInput
        type="email"
        label="Email"
        name="email"
        placeholder="abc@def.com"
        formGroupProps={{ className: 'mb-3' }}
        registerProps={{
          ...registerProps,
          pattern: {
            value:
              /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
            message: 'Email is not valid'
          }
        }}
        formControlProps={{
          disabled: review
        }}
        {...inputProps}
      />
      {!review && !inputProps?.disabled && (
        <WizardInput
          type="confirmEmail"
          label="Confirm Email"
          name="temp-confirmEmail"
          placeholder="abc@def.com"
          formGroupProps={{ className: 'mb-3' }}
          registerProps={{
            required: 'Please confirm your email address',
            validate: val => firstEmail === val || 'Emails do not match!'
          }}
          formControlProps={{
            disabled: review
          }}
        />
      )}
    </>
  );
};
EmailInput.propTypes = NINumberInput.propTypes;
export const PostcodeInput = ({ review, registerProps, inputProps }) => {
  return (
    <WizardInput
      type="postcode"
      label="Current Postcode"
      name="postcode"
      placeholder="AB12 3CD"
      registerProps={{
        ...registerProps,
        validate: async value => {
          const response = await fetch(
            'https://api.postcodes.io/postcodes/' + value
          );
          return response.ok || 'You have entered an invlid postcode';
        }
      }}
      formControlProps={{
        disabled: review
      }}
      {...inputProps}
    />
  );
};
PostcodeInput.propTypes = NINumberInput.propTypes;

export const DOBInput = ({ review, registerProps, inputProps }) => {
  const minDob = new Date(
    new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 100
  );
  const maxDob = new Date(
    new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 16
  );
  return (
    <WizardInput
      type="date"
      label="Date of Birth"
      name="dob"
      placeholder="DD/MM/YYYY"
      pluginProps={{
        yearDropdownItemNumber: 50,
        filterDate: d => d.getTime() > minDob && d < maxDob,
        openToDate: maxDob
      }}
      registerProps={{ ...registerProps }}
      formGroupProps={{ className: 'mb-3' }}
      formControlProps={{
        disabled: review
      }}
      {...inputProps}
    />
  );
};
DOBInput.propTypes = NINumberInput.propTypes;

export const TitleInput = ({ review, registerProps, inputProps }) => {
  return (
    <WizardInput
      label="Title"
      name="title"
      type="select"
      options={['Ms', 'Miss', 'Mrs', 'Mr', 'Dr', 'Mx']}
      registerProps={registerProps}
      formControlProps={{
        disabled: review
      }}
      {...inputProps}
    />
  );
};
TitleInput.propTypes = NINumberInput.propTypes;

export const NameInput = ({ middle = true, review }) => (
  <Row className="g-2 mb-3">
    <TitleInput inputProps={{ formGroupProps: { as: Col, sm: 3 } }} />
    <WizardInput
      label="First name"
      name="firstName"
      placeholder="First name"
      formGroupProps={{ as: Col, sm: middle ? 3 : 4 }}
      formControlProps={{
        disabled: review,
        maxLength: 255
      }}
    />
    {middle && (
      <WizardInput
        label="Middle name(s)"
        name="middleName"
        placeholder="Middle name(s)"
        formGroupProps={{ as: Col, sm: 3 }}
        registerProps={{ required: false }}
        formControlProps={{
          disabled: review,
          maxLength: 255
        }}
      />
    )}
    <WizardInput
      label="Surname"
      name="surname"
      placeholder="Surname"
      formGroupProps={{ as: Col, sm: middle ? 3 : 4 }}
      formControlProps={{
        disabled: review,
        maxLength: 255
      }}
    />
  </Row>
);
NameInput.propTypes = {
  middle: PropTypes.bool,
  review: PropTypes.bool
};
const PersonalForm = ({ review }) => {
  // const { register } = useFormContext();
  return (
    <>
      <NameInput review={review} />
      <DOBInput review={review} />
      <PostcodeInput review={review} />
      <NINumberInput review={review} />
      <EmailInput review={review} />
      <PhoneInput review={review} />
    </>
  );
};
PersonalForm.propTypes = {
  review: PropTypes.bool
};
export default PersonalForm;
