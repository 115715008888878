import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import Linkify from 'linkify-react';
import { Form } from 'react-bootstrap';
import CustomWizardInput from './CustomWizardInput';
import VideoPlayer, { VideoPlayerProps } from 'components/common/VideoPlayer';
import { getMediaStreamUrl } from 'helpers/utils';
import { FormQuestion } from 'apis/flex/customForms';
import AudioPlayer, { PlayEventHandler } from 'components/common/AudioPlayer';
import { validate } from 'helpers/validation/validate';
const QuestionVideo = ({
  question,
  ...rest
}: { question: FormQuestion } & Partial<VideoPlayerProps>) => {
  const src =
    question.includeVideo &&
    (question.videoUrl ||
      (question.videoFile && getMediaStreamUrl(question.videoFile)));
  return (
    src && (
      <div className="w-100 border rounded-3 mb-4">
        <VideoPlayer url={src} {...rest} />
      </div>
    )
  );
};
QuestionVideo.propTypes = {
  question: PropTypes.object
};
const QuestionAudio = ({
  question,
  onPlay
}: {
  question: FormQuestion;
  onPlay?: PlayEventHandler;
}) => {
  const src =
    question.includeAudio &&
    (question.audioUrl ||
      (question.audioFile && getMediaStreamUrl(question.audioFile)));

  return (
    src && (
      <div className="w-100 mb-4">
        <AudioPlayer
          width={400}
          className="w-100"
          url={src}
          controls
          onPlay={onPlay}
        />
      </div>
    )
  );
};
const useMediaPlays = ({ question }: { question: FormQuestion }) => {
  const [mediaPlays, setMediaPlays] = React.useState<number>(0);
  const [showMedia, setShowMedia] = React.useState<boolean>(true);
  const handlePlay = () => {
    if (question.maxMediaPlays && mediaPlays + 1 >= question.maxMediaPlays) {
      setShowMedia(false);
      return;
    }
    setMediaPlays(mediaPlays + 1);
  };
  return {
    plays: mediaPlays,
    maxPlays: question.maxMediaPlays,
    handlePlay,
    show: showMedia
  };
};
const QuestionMedia = ({
  question,
  ...mediaProps
}: { question: FormQuestion } & Partial<VideoPlayerProps> & {
    onPlay?: PlayEventHandler;
  }) => {
  const { show: showAudio, handlePlay: handleAudio } = useMediaPlays({
    question
  });
  const { show: showVideo, handlePlay: handleVideo } = useMediaPlays({
    question
  });
  return (
    <>
      {showAudio && (
        <QuestionAudio
          {...mediaProps}
          onPlay={handleAudio}
          question={question}
        />
      )}
      {showVideo && (
        <QuestionVideo
          {...mediaProps}
          onPlay={handleVideo}
          question={question}
        />
      )}
    </>
  );
};

const CustomWizardQuestion = ({
  question,
  allQuestions
}: {
  question: FormQuestion;
  allQuestions: FormQuestion[];
}) => {
  // console.log(question);
  const { resetField } = useFormContext();
  const maskRules = question.masking || [];
  const maskQuestions = maskRules
    .flatMap(andRules =>
      (Array.isArray(andRules) ? andRules : !andRules ? [] : [andRules]).map(
        m => ({
          ...m,
          question: allQuestions.find(q => q.label?.toString() === m.question)
        })
      )
    )
    .filter(m => m.question && m.question.dataIndex < question.dataIndex);
  const maskNames =
    question.masking && maskQuestions.map(m => m.question?.id?.toString());
  const dataWatch = useWatch({
    name: maskNames,
    disabled: !maskNames?.length
  });
  const maskData =
    !maskNames?.length ||
    (dataWatch || []).reduce((a, b, i) => {
      return { ...a, [maskQuestions[i].question.label.toString()]: b };
    }, {});

  const shouldShow = useMemo(
    () =>
      validate.trueIfAllPass(
        maskRules,
        rule => {
          return allQuestions.find(
            q => q.label?.toString() === rule.question?.toString()
          )?.inputType;
        },
        rule => {
          return maskData[rule.question];
        }
        // r =>
        //   !!allQuestions.find(
        //     q => q.label?.toString() === r.question?.toString()
        //   ) && maskData !== true
      ) === true,
    [maskRules, maskData, allQuestions]
  );
  console.log('running masking', question, maskRules, maskData, shouldShow);
  useEffect(() => {
    if (!shouldShow) {
      resetField(question.id.toString(), { defaultValue: '' });
    }
  }, [shouldShow]);

  if (!shouldShow) {
    return <></>;
  }
  if (question.inputType === 'info') {
    return (
      <div className="mb-3">
        <Linkify options={{ target: '_blank', className: 'text-break' }}>
          <Form.Label
            className="text-center text-word-break"
            // style={{ lineBreak: 'anywhere' }}
            dangerouslySetInnerHTML={{ __html: question.questionText }}
          />
        </Linkify>
        <QuestionMedia question={question} />
      </div>
    );
  }
  return (
    <>
      <QuestionMedia question={question} />
      <CustomWizardInput question={question} />
    </>
  );
};

CustomWizardQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  allQuestions: PropTypes.array.isRequired
};

export default CustomWizardQuestion;
