import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useUser } from './useUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from 'components/common/Tooltip';
import { createContext } from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import is from 'is_js';
import { isIterableArray } from 'helpers/utils';
import { User } from 'components/app/users/types';
import { useQuery } from '@tanstack/react-query';
import { ItemOwnership, getItemOwnership } from 'apis/flex/auth';
const useItemOwnership = ({ enabled = true }) => {
  return useQuery({
    queryKey: ['itemOwnership'],
    queryFn: getItemOwnership,
    staleTime: Infinity,
    enabled
  });
};
export const isAgent = u => !!u.agentId;
export const isSupervisor = u => {
  // console.log('isSupervisor', u.roles, u.roles?.user || u.roles?.super);
  return u.roles?.user || u.roles?.super;
};
const isAllowed = (
  user,
  _domain,
  _action,
  ids?: number[],
  itemOwnership?: ItemOwnership[]
) => {
  if (!_domain) {
    return true;
  }
  if (!user) {
    return false;
  }
  if (!user.roles) {
    return false;
  }
  const domain = _domain.toLowerCase();
  const action = _action?.toLowerCase() || 'view';

  const domainOwnership = itemOwnership?.filter(i => i.domain === domain);
  const itemMap = new Map(domainOwnership?.map(i => [Number(i.itemId), i]));
  const isItemOwner = ids?.every(id => !!itemMap.get(Number(id)));
  const isAdmin = domain => !!user.roles?.[domain]?.admin;
  const canView = domain => !!user.roles?.[domain];
  const canEdit = domain =>
    isAdmin(domain) ||
    !!user.roles?.[domain]?.editor ||
    !!user.roles?.[domain]?.creator;
  const canCreate = domain =>
    isAdmin(domain) || !!user.roles?.[domain]?.creator;

  //if I'm an admin of either this domain or 'super', I can view and edit everything. Skip further checks
  if (isAdmin(domain) || isAdmin('super')) return true;

  //if I don't have role permission to perform action, I'm not allowed
  if (
    action === 'view' &&
    !canView(domain) &&
    !canView('super') &&
    !isItemOwner
  )
    return false;

  if (action === 'create' && !canCreate(domain) && !canCreate('super'))
    return false;
  if (action === 'edit' && !canEdit(domain) && !canEdit('super')) return false;

  //it's assumed at this point that I'm allowed to perform the action

  return true;
};
export const useIsAllowed = () => {
  const user = useUser();
  return useCallback(
    (roles, level = null, itemIds = null, itemOwnership = null) => {
      const rolesArr = !roles || Array.isArray(roles) ? roles : [roles];
      return (
        !rolesArr ||
        rolesArr.some(role =>
          isAllowed(
            user,
            role.split('.')[0],
            level || role.split('.')[1],
            itemIds,
            itemOwnership
          )
        )
      );
    },
    [user]
  );
};
const LockedWrapper = ({ children }) => {
  return React.Children.map(children, (child, i) => {
    if (!child) return;
    const cloned = React.cloneElement(child, {
      ...child.props,
      key: i,
      className: classNames(
        child.props.className,
        'position-relative pointer-events-none'
      ),
      disabled: true,
      children: isIterableArray(child.props.children) && [
        ...child.props.children,
        <CustomTooltip key={'tt' + i} content="Insufficient permissions">
          <div
            style={{ pointerEvents: 'all' }}
            className="align-items-center bg-300 d-flex h-100 justify-content-center opacity-50 position-absolute px-2 rounded-3 start-0 top-0 text-1100 w-100"
          >
            <FontAwesomeIcon icon={faLock} />
          </div>
        </CustomTooltip>
      ]
    });
    return cloned;
  });
};
LockedWrapper.propTypes = {
  children: PropTypes.node
};
const returns = (ifCan, can, isRole) => {
  return useMemo(
    () => ({
      ifCanEdit: ifCan('Edit'),
      ifCanView: ifCan('View'),
      ifCanDelete: ifCan('Delete'),
      ifCanCreate: ifCan('Create'),
      ifCan,
      canDelete: can('Delete'),
      canCreate: can('Create'),
      canEdit: can('Edit'),
      canView: can('View'),
      can,
      is: isRole,
      isAdmin: isRole('admin')
    }),
    [ifCan, can, isRole]
  );
};
export const useGuard = ({ roles = [], itemIds = [] }) => {
  const config = useConfig();
  const props = {
    ...config,
    roles,
    itemIds
  };
  const isAllowed = useIsAllowed();
  const user = useUser();
  const { data: itemOwnership } = useItemOwnership({ enabled: !!user?.id });
  const can = useCallback(
    (level, itemIds) => {
      const ids = itemIds || props.itemIds;
      const idArr = !ids || is.array(ids) ? ids : [ids];
      return isAllowed(roles, level, idArr, itemOwnership);
    },
    [props.itemIds, props.roles, isAllowed]
  );
  const ifCan = (level, itemIds) => {
    if (can(level, itemIds)) {
      return jsx => jsx;
    } else {
      return jsx => <LockedWrapper>{jsx}</LockedWrapper>;
    }
  };
  const isRole = level => {
    if (roles?.every(r => user?.roles?.[r]?.[level])) return true;
    if (user?.roles?.super?.[level]) return true;
    return false;
  };
  return returns(ifCan, can, isRole);
};
export const Guard = ({ roles = [], itemIds = null, children }) => {
  const { ifCan } = useGuard({ roles, itemIds });
  return ifCan()(children);
};
Guard.propTypes = {
  roles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  ids: PropTypes.any,
  children: PropTypes.node
};
export const UserGuard = ({ users, children, level = 'View' }) => {
  const config = useConfig();
  const props = {
    ...config,
    users,
    level
  };
  return useGuard({
    roles: ['user.' + level],
    itemIds: props.users.map(u => u.id)
  }).ifCan(props.level)(children);
};
UserGuard.propTypes = {
  users: PropTypes.any,
  children: PropTypes.node,
  level: PropTypes.string
};
const GuardContext = createContext({});
const useConfig = () => useContext(GuardContext);
export const GuardConfig = ({ users, children, level = 'View', ...rest }) => {
  return (
    <GuardContext.Provider value={{ users, level, ...rest }}>
      {children}
    </GuardContext.Provider>
  );
};
GuardConfig.propTypes = UserGuard.propTypes;
export const useUserGuard = ({
  users,
  level
}: {
  users: User[];
  level?: string;
}) => {
  const user = useUser();
  const config = useConfig();
  const props = {
    ...config,
    users,
    level
  };
  const isSelf = props.users?.every(u => u.id == user?.id);
  return {
    ...useGuard({
      roles: ['user.' + props.level],
      itemIds: props.users.filter(d => d).map(u => u.id)
    }),
    isSelf
  };
};
