import { Campaign } from 'apis/flex/recruitment';
import useCampaigns from './useCampaigns';
import { UseCrudProps } from 'hooks/useDefaultCrud';

export default <T = Campaign>({
  id,
  select = d => d as T,
  ...rest
}: UseCrudProps<Campaign, T>) => {
  return useCampaigns<T>({ id, ...rest, select: d => select(d)[0] });
};
