import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
const PageLoader = ({ message, title, height }) => {
  return (
    <div
      className={
        'w-100 vh-' +
        (height || 100) +
        ' d-flex flex-column flex-direction-column justify-content-center align-items-center '
      }
    >
      <div
        className={
          'w-100 d-flex flex-column flex-direction-column justify-content-center align-items-center '
        }
      >
        <Spinner style={{ margin: 'auto' }} animation="border" role="status">
          <span className="visually-hidden">{title || 'Loading...'}</span>
        </Spinner>
        {message && <h6 className="mt-1">{message}</h6>}
      </div>
    </div>
  );
};
PageLoader.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default PageLoader;
