import { getItemFromStore } from 'helpers/utils';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export default ({
  targetHref,
  targetStoreName,
  data
}: {
  targetHref: string;
  targetStoreName: string;
  data: any;
}) => {
  const [popoutData, setPopoutData] = useState(null);
  const [popout, setPopout] = useState<Window>(null);
  const getVals = () => data;
  const updateData = debounce(() => {
    // console.log('updating data', getVals());
    setPopoutData(getVals());
  }, 2000);
  useEffect(() => {
    updateData();
    return updateData.cancel;
  }, [data]);
  const updatePopout = () => {
    if (popout && popout.sessionStorage) {
      popout.sessionStorage.setItem(
        'previewer-' + targetStoreName,
        JSON.stringify(popoutData)
      );
      // console.log("dispatching 'dataUpdated'", popoutData);
      popout.dispatchEvent(new CustomEvent('dataUpdated'));
    }
  };
  useEffect(() => {
    // console.log('popout in useEffect', popout, popoutData);
    updatePopout();
  }, [popoutData, popout]);
  let interval;
  const handlePopout = () => {
    const newWindow = window.open(
      targetHref,
      'Preview',
      'width=600,height=660'
    );
    interval = setInterval(() => {
      if (!newWindow.name) {
        setPopout(null);
        clearInterval(interval);
      }
    }, 500);
    setTimeout(() => {
      updateData();
    }, 2000);
    setPopout(newWindow);
  };
  return {
    show: handlePopout,
    isShown: !!popout,
    hide: () => setPopout(null),
    window: popout
  };
};
export const usePopoutData = <TData = any>({ name: storeName }) => {
  const [returnData, setReturnData] = useState<TData>();
  const updateFromStore = () => {
    const data = getItemFromStore('previewer-' + storeName, {}, sessionStorage);
    // console.log('updating from store', data);
    setReturnData(data);
  };
  useEffect(() => {
    window.addEventListener('dataUpdated', updateFromStore);
    return () => window.removeEventListener('dataUpdated', updateFromStore);
  }, []);
  useEffect(() => {
    const store = getItemFromStore(
      'previewer-' + storeName,
      {},
      sessionStorage
    );
    // console.log('running initial grab from store', store);
    setReturnData(store);
  }, []);
  // console.log('data in previewpopout', returnData);
  return { data: returnData };
};
