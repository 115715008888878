import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
export default forwardRef<ReCAPTCHA, { onSuccess: () => void }>(
  ({ onSuccess }: { onSuccess: () => void }, ref) => {
    return (
      <div className="form-text text-danger p-3 d-none">
        <ReCAPTCHA
          badge="inline"
          size="invisible"
          ref={ref}
          onChange={onSuccess}
          sitekey={'6LcK2SYqAAAAAJZc_Hb_eBFeSFidQS3iWKBFbAVg'}
          onError={console.error}
        />
      </div>
    );
  }
);
