import React from 'react';
import CustomTooltip from './Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Wrapper from 'helpers/Wrapper';
import classNames from 'classnames';
export default ({ locked, children, tooltip = '', className = null }) => {
  return (
    <Wrapper
      condition={locked}
      wrapper={children => (
        <CustomTooltip content={tooltip}>
          <div
            className={classNames(
              className,
              'position-relative pointer-events-none'
            )}
          >
            <div
              style={{ filter: 'grayscale(1)' }}
              className="opacity-50 h-100"
            >
              {children}
            </div>
            <div
              style={{ pointerEvents: 'all' }}
              className="align-items-center d-flex h-100 justify-content-center position-absolute px-2 rounded-3 start-0 top-0 w-100 text-1100"
            >
              <FontAwesomeIcon
                style={{ height: '40%', width: '40%' }}
                icon={faLock}
              />
              <div className="opacity-50 position-absolute text-1100 w-100 h-100 bg-300 rounded-3" />
            </div>
          </div>
        </CustomTooltip>
      )}
    >
      {children}
    </Wrapper>
  );
};
