//ANY CHANGES to this file must be replicated in the API 'helpers/validation' library. Must not require any dependancies
/* eslint no-restricted-imports: ["error", {
  "paths": ["*"],
  "patterns": ["*.*"]
}] */
//Custom input types

import _ from 'lodash';
const log = (obj, ...args: any[]) => console.info(obj, ...args);

//SHIFTS
type Time = `${string}:${string}`;
export type Weekday =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';
export type ShiftValue = Record<Weekday, `${Time}-${Time}`[]>;
export const convertShiftTimeValueToShift = (time: `${Time}-${Time}`) => {
  const parts = time.split('-') as Time[];
  return {
    start: parts[0],
    end: parts[1]
  };
};
export const shiftToHours = (s: { start: Time; end: Time }) => {
  const [startH, startM] = s.start.split(':');
  const [endH, endM] = s.end.split(':');
  return (
    ((Number(endH) - Number(startH)) * 60 + Number(endM) - Number(startM)) / 60
  );
};
const reduceShiftHours = (func: 'min' | 'max', v: ShiftValue) => {
  return Object.keys(v)
    .filter(b => v[b]?.length)
    .reduce<number>(
      (a, b: Weekday) =>
        a +
        Math[func](
          ...v[b].map(s => shiftToHours(convertShiftTimeValueToShift(s)))
        ),
      0
    );
};
export const getMaxShiftHours = (v: ShiftValue) => reduceShiftHours('max', v);
export const getMinShiftHours = (v: ShiftValue) => reduceShiftHours('min', v);

//Parsers

export const parseValueForComparison = v => {
  if (v === undefined || v === null) return v;
  if (typeof v === 'object') return Object.values(v).join(' ');
  return JSON.stringify(v).toLowerCase();
};
export const getValueParser = (dataType, ruleType) => {
  switch (dataType) {
    case 'number':
      return v => parseInt(v || 0);
    case 'range':
      return v => [parseInt(v[0] || 0), parseInt(v[1] || 0)];
    default:
      return v =>
        ['in', '!in', 'allin', '!allin'].includes(ruleType) &&
        v &&
        !Array.isArray(v)
          ? [v]
          : v;
  }
};

//comparison

export const stringComparison = (v: any, o: any, includes?: boolean) => {
  return v !== undefined && v !== null && o !== undefined && o !== null
    ? includes
      ? parseValueForComparison(v).includes(o?.toString().toLowerCase())
      : parseValueForComparison(v) === parseValueForComparison(o)
    : false;
};
const isDateInLast = (date, inLastMs) =>
  new Date(date).getTime() > new Date().getTime() - inLastMs;

const validationFunctions = type => {
  switch (type) {
    case 'empty':
      return a => a === '' || a === undefined || a === null;
    case '!empty':
      return a => a !== '' && a !== undefined && a !== null;
    case '<':
      return (a, b) => new Date(a) < new Date(b);
    case '>':
      return (a, b) => new Date(a) > new Date(b);
    case 'between':
      return (a, b) =>
        new Date(a) >= new Date(b[0]) && new Date(a) <= new Date(b[1]);
    case '!between':
      return (a, b) =>
        new Date(a) < new Date(b[0]) || new Date(a) > new Date(b[1]);
    case '==':
      return (a, b) => stringComparison(a, b);
    case '!=':
      return (a, b) => !stringComparison(a, b);
    case '!contains':
      return (a, b) => !stringComparison(a, b, true);
    case 'contains':
      return (a, b) => stringComparison(a, b, true);
    case 'in':
      return (a, b) =>
        (Array.isArray(a) ? a : [a]).some(v =>
          (Array.isArray(b) ? b : [b]).some(o => stringComparison(v, o))
        );
    case '!in':
      return (a, b) =>
        !(Array.isArray(a) ? a : [a]).some(v =>
          (Array.isArray(b) ? b : [b]).some(o => stringComparison(v, o))
        );
    case '!allin':
      return (a, b) =>
        !(Array.isArray(b) ? b : [b]).every(v =>
          (Array.isArray(a) ? a : [a]).some(o => stringComparison(v, o))
        );
    case 'allin':
      return (a, b) =>
        (Array.isArray(b) ? b : [b]).every(v =>
          (Array.isArray(a) ? a : [a]).some(o => stringComparison(v, o))
        );
    case '>count':
      return (a, b) => (Array.isArray(a) ? a : [a]).length > (b || 0);
    case '<count':
      return (a, b) => (Array.isArray(a) ? a : [a]).length < (b || 0);
    case '>shiftDays':
      return (s, b) =>
        Object.keys(s).reduce((a, b) => a + (s[b].some(ss => ss) ? 1 : 0), 0) >
        (b || 0);
    case '<shiftDays':
      return (s, b) =>
        Object.keys(s).reduce((a, b) => a + (s[b].some(ss => ss) ? 1 : 0), 0) <
        (b || 0);
    case '<shiftMinHours':
      return (s, b) => getMinShiftHours(s) < (b || 0);
    case '>shiftMinHours':
      return (s, b) => getMinShiftHours(s) > (b || 0);
    case '<shiftMaxHours':
      return (s, b) => getMaxShiftHours(s) > (b || 0);
    case '>shiftMaxHours':
      return (s, b) => getMaxShiftHours(s) < (b || 0);
    case 'lastYears':
      return (v, b) => isDateInLast(v, b * 365 * 24 * 60 * 60 * 1000);
    case 'lastDays':
      return (v, b) => isDateInLast(v, b * 24 * 60 * 60 * 1000);
    case 'lastHours':
      return (v, b) => isDateInLast(v, b * 60 * 60 * 1000);
    case 'lastMinutes':
      return (v, b) => !isDateInLast(v, b * 60 * 1000);
    case '!lastYears':
      return (v, b) => !isDateInLast(v, b * 365 * 24 * 60 * 60 * 1000);
    case '!lastDays':
      return (v, b) => !isDateInLast(v, b * 24 * 60 * 60 * 1000);
    case '!lastHours':
      return (v, b) => !isDateInLast(v, b * 60 * 60 * 1000);
    case '!lastMinutes':
      return (v, b) => !isDateInLast(v, b * 60 * 1000);
    default:
      return (a, b) => stringComparison(a, b, true);
  }
};

export const getRuleFunc = (
  type: string,
  dataType: string
): ((value) => boolean) | ((value, compareValue?: any) => boolean) => {
  const validator = validationFunctions(type);
  if (type.includes('.')) {
    const parser = getValueParser(dataType, type.split('.')[0]);
    return (a, b) => {
      const val = a?.[type.split('.')[1]];
      console.log(
        { subType: type.split('.')[0], value: parser(val) },
        'Running rule validation on subtype'
      );
      return getRuleFunc(type.split('.')[0], dataType)(parser(val), b);
    };
  }
  return (a, b) => {
    //put global overrides/checks here
    if (a === undefined)
      //if no value provided/no answer given, return true only if negative type supplied
      return type === 'empty' || type.startsWith('<') || type.startsWith('!');
    //run type-specific validation
    return validator(a, b);
  };
};
export type CustomOrRule = {
  type: string;
  value: string;
  inputType?: string;
  question: string;
  result?: true | string;
};
export type CustomAndRule = CustomOrRule[];
export type CustomRule = CustomAndRule[];

export const getValidationMessage = (
  dataType: string,
  comparitor: string,
  value: any
) => {
  let val = value;
  if (comparitor.includes('.')) {
    return (
      _.startCase(comparitor.split('.')[1]) +
      ' ' +
      getValidationMessage(dataType, comparitor.split('.')[0], val)
    );
  }
  switch (dataType) {
    case 'date':
    case 'datetime':
      if (Array.isArray(value)) {
        val = value.map(v => new Date(v).toLocaleDateString('en-GB'));
      } else {
        val = new Date(value).toLocaleDateString('en-GB');
      }
      return;
    default:
      null;
  }
  const getBaseComparitorMessage = () =>
    getValidationMessage('number', comparitor.slice(0, 1), val);
  if (comparitor.includes('shiftDays')) {
    return 'Number of days ' + _.lowerCase(getBaseComparitorMessage());
  }
  if (comparitor.includes('shiftMinHours')) {
    return 'Minimum possible hours ' + _.lowerCase(getBaseComparitorMessage());
  }
  if (comparitor.includes('shiftMaxHours')) {
    return 'Maximum possible hours ' + _.lowerCase(getBaseComparitorMessage());
  }
  const getDateWithinMessage = (span: string) =>
    (comparitor.startsWith('!') ? 'Cannot' : 'Must') +
    ' be within the last ' +
    val +
    ' ' +
    span +
    (val > 1 ? 's' : '') +
    '.';
  if (comparitor.includes('lastYears')) {
    return getDateWithinMessage('year');
  }
  if (comparitor.includes('lastDays')) {
    return getDateWithinMessage('day');
  }
  if (comparitor.includes('lastHours')) {
    return getDateWithinMessage('hour');
  }
  if (comparitor.includes('lastMinutes')) {
    return getDateWithinMessage('minute');
  }
  switch (comparitor) {
    case 'empty':
      return 'Must be empty';
    case '!empty':
      return 'Cannot be empty';
    case '<':
      return (
        'Must be ' +
        (dataType === 'date' || dataType === 'datetime'
          ? 'before '
          : 'less than ') +
        val
      );
    case '>':
      return (
        'Must be ' +
        (dataType === 'date' || dataType === 'datetime'
          ? 'after '
          : 'more than ') +
        val
      );
    case 'between':
      return 'Must be between ' + val[0] + ' and ' + val[1];
    case '!between':
      return 'Cannot be between ' + val[0] + ' and ' + val[1];
    case '==':
      return 'Must equal ' + val;
    case '!=':
      return 'Cannot equal ' + val;
    case '!contains':
      return "Cannot contain '" + value + "'";
    case 'contains':
      return "Must contain '" + value + "'";
    case 'in':
      return 'Must be one of ' + value.join(', ');
    case '!in':
      return 'Cannot be one of ' + value.join(', ');
    case 'allin':
      return 'Must have all of ' + value.join(', ');
    case '!allin':
      return 'Cannot have all of ' + value.join(', ');
    default:
      return (
        "Value does not satisfy the rule '" + comparitor + ' ' + value + "'"
      );
  }
};

/**
 * By default, will return true if the rule passes, and an error message if the rule has failed. @param errorOnPass If true, will return an error message if the rule passes
 */
const validateRule = (
  orRule: CustomOrRule,
  /**must be unparsed */
  inputType: string,
  /**must be unparsed */
  inputValue: any,
  errorOnPass?: boolean
): true | string => {
  const evaluate = value => (errorOnPass ? !value : !!value);
  const inputParser = getValueParser(
    orRule.inputType || inputType,
    orRule.type
  );
  const ruleParser = getValueParser(
    orRule.inputType || inputType,
    //the rule value will always just be the value, not an object type like the input value, so needs to be parsed directly in all case
    orRule.type.split('.')[0]
  );
  const ruleFunc = getRuleFunc(orRule.type, inputType);
  const result = ruleFunc(inputParser(inputValue), ruleParser(orRule.value));
  log(
    {
      orRule,
      result,
      inputValue: inputParser(inputValue),
      ruleValue: ruleParser(orRule.value)
    },
    'Validating rule value'
  );
  return evaluate(result)
    ? true
    : getValidationMessage(
        orRule.inputType || inputType,
        orRule.type,
        ruleParser(orRule.value)
      );
};
export type ValidationResult = {
  rule: CustomOrRule;
  value: any;
  inputType: string;
  result: string | true;
  description: string;
};
export type ValidationRuleResults = ValidationResult[][];
type PropertyGetter<TReturn> = ((rule: CustomOrRule) => TReturn) | TReturn;
const getProperty = <TReturn = any>(
  getter: PropertyGetter<TReturn>,
  rule: CustomOrRule
) => (_.isFunction(getter) ? getter(rule) : getter);
const mapValidationResults = (
  rules: CustomRule,
  inputType: PropertyGetter<string>,
  inputValue: PropertyGetter<any>,
  errorOnPass?: boolean,
  enabled: PropertyGetter<boolean> = () => true
): ValidationRuleResults => {
  return rules.map(andRule => {
    return (Array.isArray(andRule) ? andRule : !andRule ? [] : [andRule]).map(
      rule => {
        const _inputType = getProperty(inputType, rule);
        const _inputValue = getProperty(inputValue, rule);
        const valueParser = getValueParser(
          _inputType,
          //the rule value will always just be the value, not an object type like the input value, so needs to be parsed directly in all cases
          rule.type.split('.')[0]
        );
        const description = getValidationMessage(
          _inputType,
          rule.type,
          valueParser(rule.value)
        );
        return {
          rule,
          value: _inputValue,
          inputType: _inputType,
          description,
          result: !getProperty(enabled, rule)
            ? errorOnPass
              ? 'Masking not enabled'
              : true
            : validateRule(
                rule,
                _inputType,
                getProperty(inputValue, rule),
                errorOnPass
              )
        };
      }
    );
  });
};

const areAllResultsTrue = (results: ValidationRuleResults) =>
  results.every(orRules => orRules.some(rule => rule.result === true));
const areAnyResultsTrue = (results: ValidationRuleResults) =>
  results.some(orRules => orRules.some(rule => rule.result === true));
const combineErrors = (results: ValidationRuleResults) =>
  results
    ?.map(orRules =>
      orRules
        .map(rule => (typeof rule.result === 'string' ? rule.result : null))
        .filter(r => r)
        .join(' or ')
    )
    .join(' and ') || '';

/**
 * Returns true if all rules pass. A combined single error message stating why they failed if not
 */
const trueIfAllPass = (
  rules: CustomRule,
  inputType: PropertyGetter<string>,
  inputValue: PropertyGetter<any>,
  enabled: PropertyGetter<boolean> = () => true
) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    false,
    enabled
  );
  const haveAllPassed = areAllResultsTrue(results);
  if (haveAllPassed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};

/**
 * Returns true if any rules pass. A combined single error message stating why they failed if not
 */
const trueIfAnyPass = (
  rules: CustomRule,
  inputType: PropertyGetter<string>,
  inputValue: PropertyGetter<any>,
  enabled: PropertyGetter<boolean> = () => true
) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    false,
    enabled
  );
  const haveAnyPassed = areAnyResultsTrue(results);
  if (haveAnyPassed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};

/**
 * Returns true if all rules fail. A combined single error message stating why they passed if not
 */
const trueIfAllFail = (
  rules: CustomRule,
  inputType: PropertyGetter<string>,
  inputValue: PropertyGetter<any>,
  enabled: PropertyGetter<boolean> = () => true
) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    true,
    enabled
  );
  const haveAllFailed = areAllResultsTrue(results);
  if (haveAllFailed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};

/**
 * Returns true if any rules fail. A combined single error message stating why they passed if not
 */
const trueIfAnyFail = (
  rules: CustomRule,
  inputType: PropertyGetter<string>,
  inputValue: PropertyGetter<any>,
  enabled: PropertyGetter<boolean> = () => true
) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    true,
    enabled
  );
  const haveAnyFailed = areAllResultsTrue(results);
  if (haveAnyFailed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};
export const validate = {
  trueIfAllFail,
  trueIfAllPass,
  trueIfAnyFail,
  trueIfAnyPass,
  validateRule,
  mapResults: mapValidationResults,
  combineErrors
};
