import { EventPrefix } from 'apis/flex/notifications';
import { settings } from 'config';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
export default ({
  domain,
  isLoading,
  src,
  size = 'md'
}: {
  domain: EventPrefix;
  isLoading?: boolean;
  src: string;
  size?: 'sm' | 'md' | 'lg';
}) => {
  const widths = {
    sm: 32,
    md: 56,
    lg: 98
  };
  const avatarWidth = widths[size];
  const color = settings.domains[domain]?.color || 'info';
  const borders = {
    sm: 1,
    md: 3,
    lg: 5
  };
  return (
    <div
      style={{ width: avatarWidth, height: avatarWidth }}
      className={`border border-${borders[size]} border-${color}-subtle overflow-hidden position-relative rounded-circle`}
    >
      {isLoading ? (
        <Skeleton
          height={avatarWidth}
          width={avatarWidth}
          containerClassName="d-flex"
        />
      ) : (
        <img
          height={avatarWidth}
          className="position-absolute rounded-2 start-50 translate-middle-x"
          src={src}
        />
      )}
    </div>
  );
};
