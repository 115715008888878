import { useQuery } from '@tanstack/react-query';
import { ApplicantResponse, applicantsAPI } from 'apis/flex/recruitment';
import { useMemo } from 'react';

export default <TSelected = ApplicantResponse[]>(
  {
    campaignId,
    select = d => d as TSelected,
    staleTime,
    ...rest
  }: {
    campaignId: number;
    staleTime?: number;
    select?: (data: ApplicantResponse[]) => TSelected;
  } = { campaignId: 0 }
) => {
  const enabled = useMemo(() => !!campaignId, [campaignId]);
  const { data, isLoading, error } = useQuery<
    ApplicantResponse[],
    Error,
    TSelected
  >({
    queryKey: ['Applicants', 'CampaignApplicants', campaignId],
    queryFn: () =>
      applicantsAPI.get({
        filters: [{ first: 'campaignId', second: campaignId }]
      }),
    select,
    enabled,
    staleTime: staleTime || 1000 * 60,
    ...rest
  });
  return { data, isLoading, error };
};
