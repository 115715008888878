export const getSqlEditProps = sqlColumn => {
  if (!sqlColumn) {
    return {};
  }
  return {
    inputType: sqlColumn.type || getSqlInputType(sqlColumn),
    registerProps: { required: !sqlColumn.IS_NULLABLE },
    formControlProps: {
      maxLength: sqlColumn.CHARACTER_MAXIMUM_LENGTH,
      step: sqlColumn.NUMERIC_SCALE
        ? `0.${'0'.repeat(sqlColumn.NUMERIC_SCALE - 1)}1`
        : 'any'
    }
  };
};
export const getSqlInputType = sqlColumn => {
  switch (sqlColumn.DATA_TYPE) {
    case 'int':
    case 'smallint':
      return 'number';
    case 'decimal':
    case 'numeric':
      return 'number';
    case 'varchar':
    case 'nvarchar':
    case 'text':
      return sqlColumn.CHARACTER_MAXIMUM_LENGTH > 255 ||
        sqlColumn.CHARACTER_MAXIMUM_LENGTH === -1
        ? 'textarea'
        : 'text';
    case 'date':
      return 'date';
    case 'datetime':
      return 'datetime';
    case 'time':
      return 'time';
    case 'bit':
    case 'boolean':
      return 'checkbox';
    default:
      return 'text';
  }
};
