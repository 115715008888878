import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { sentryInstance } from 'index';
import useSiteRoutes from 'hooks/useSiteRoutes';
import { ToastContainer, toast } from 'react-toastify';
import { Outlet } from 'react-router-dom';
const FalconRoutes = () => {
  const { allRoutes } = useSiteRoutes();
  const sentryCreateBrowserRouter =
    sentryInstance.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter([
    {
      element: (
        <>
          <ToastContainer
            closeButton
            icon={false}
            position={toast.POSITION.BOTTOM_LEFT}
            draggablePercent={50}
          />
          <Outlet />
        </>
      ),
      children: allRoutes
    }
  ]);
  return <RouterProvider router={router} />;
};
export default FalconRoutes;
