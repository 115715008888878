/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

const AdvanceTableSearchBox = ({
  placeholder = 'Search...',
  className,
  formControlProps,
  onChange,
  autoFocus = false
}) => {
  const onChangeDb = debounce(value => {
    onChange(value || undefined);
  }, 200);
  const innerRef = useRef(null);
  useEffect(() => {
    autoFocus && innerRef?.current?.focus();
  }, []);
  return (
    <InputGroup className={classNames(className, 'position-relative')}>
      <FormControl
        onChange={({ target: { value } }) => {
          onChangeDb(value);
        }}
        ref={innerRef}
        autoFocus={autoFocus}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
        {...formControlProps}
      />
      <Button
        size="sm"
        variant="outline-secondary"
        className="border-300 hover-border-secondary"
      >
        <FontAwesomeIcon icon="search" className="fs--1" />
      </Button>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
